import React from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonButton,
  IonCheckbox,
} from '@ionic/react';
import Layout from '../../components/layout';
import { NormalText, SmallText, Spacer, Title } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import { loading, setDeliveryOption } from '../../store/actions';
import { forwardTo, getSingleDeliveryOption } from '../../lib/utils';
import Basket from '../../lib/basket';
import api from '../../lib/api';
import './index.css';
import Loading from '../../components/spinner';
import { getConfig } from '../../appConfig';

class DeliveryOptionsContent extends React.Component {
  constructor() {
    super();
    this.state = {
      delivery: [],
      option: null,
    };
  }
  // setDeliveryOption = (delivery) => {
  //   this.props.dispatch(setDeliveryOption(delivery));
  //   Basket.setDeliveryOption(delivery);
  //   Basket.setOrderType(delivery.id);
  //   forwardTo(
  //     delivery.id === 'delivery'
  //       ? '/delivery'
  //       : delivery.id === 'charter-delivery'
  //       ? '/delivery'
  //       : delivery.id === 'pick-up-point'
  //       ? '/pick-up-point'
  //       : delivery.id === 'table'
  //       ? '/order-to-table'
  //       : '/click-and-collect',
  //     { isLogoClicked: false },
  //   );
  // };
  selectOption(option) {
    this.setState({ option: option });
  }
  setDeliveryOption = () => {
    const { option } = this.state;
    this.props.dispatch(setDeliveryOption(option));
    Basket.setDeliveryOption(option);
    Basket.setOrderType(option.id);
    forwardTo(option.route);
    forwardTo(
      option.id === 'delivery'
        ? '/delivery'
        : option.id === 'charter-delivery'
        ? '/delivery'
        : option.id === 'pick-up-point'
        ? '/pick-up-point'
        : option.id === 'table'
        ? '/order-to-table'
        : '/click-and-collect',
      { isLogoClicked: false },
    );
  };

  checkForSingleDeliveryOption = async () => {
    const singleDeliveryOption = await getSingleDeliveryOption();
    if (singleDeliveryOption) {
      this.setDeliveryOption(singleDeliveryOption);
    }
  };

  getOrderingButtons = async () => {
    this.props.dispatch(loading(true));
    const config = await api.getFrontEndAppConfig();
    this.setState({ delivery: config.front_end_app_config.delivery });
    this.props.dispatch(loading(false));
  };

  componentDidMount() {
    const { defaultMenuId } = this.props;
    Basket.reset();
    this.checkForSingleDeliveryOption();
    this.getOrderingButtons();
    api.getDefaultMenu(defaultMenuId).then((res) => {
      this.props.dispatch({ type: 'SET_RESTAURANT_PROP', key: 'defaultMenu', value: res });
    });
  }

  componentWillUnmount() {
    this.props.dispatch(loading(false));
  }

  componentDidUpdate() {
    this.checkForSingleDeliveryOption();
  }

  render() {
    const { __ } = this.props;
    const { option } = this.state;
    return (
      <Loading>
        <IonCard className="delivery-options-card">
          <Title className="delivery-options-title">{__('Start New Order')}</Title>
          <Spacer />

          <IonCardContent className="delivery-options-content">
            <IonList lines="full">
              {getConfig().delivery.map((d, index) => (
                <div>
                  {!d.isRemoved && (
                    <IonItem
                      key={`option${index}`}
                      disabled={d.isDisabled}
                      onClick={() => this.selectOption(d)}
                    >
                      <IonLabel>
                        <NormalText className="block">{__(d.label)}</NormalText>
                        <SmallText className="order-description">{__(d.description)}</SmallText>
                      </IonLabel>
                      <IonCheckbox
                        checked={option && d.id === option.id}
                        slot="start"
                        color="primary"
                      />
                    </IonItem>
                  )}
                </div>
              ))}
            </IonList>
          </IonCardContent>
          <Spacer />
          <div className="delivery-options-button">
            <IonButton
              disabled={!option}
              expand="block"
              color="primary"
              className="uppercase"
              onClick={() => this.setDeliveryOption()}
            >
              {__('Start New Order')}
            </IonButton>
          </div>
        </IonCard>
      </Loading>
    );
  }
}

const stateToProps = (state) => ({
  deliveryOption: state.orders.deliveryOption,
  defaultMenuId: state.common.defaultMenuId,
});

export const DeliveryOptionsRaw = connect(stateToProps)(withTranslation(DeliveryOptionsContent));

class DeliveryOptions extends React.Component {
  render() {
    return (
      <Layout color="transparent" noPadding={true}>
        <DeliveryOptionsRaw />
      </Layout>
    );
  }
}

export default DeliveryOptions;
