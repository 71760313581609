import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Stripe from '../../lib/stripe';
import { PaymentRequestButtonElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { showToast, loading } from '../../store/actions';

import Basket from '../../lib/basket';

export const Result = ({ children }) => <div className="result">{children}</div>;

export const ErrorResult = ({ children }) => <div className="error">{children}</div>;

const NotAvailableResult = () => (
  <Result>
    {/* <p style={{ textAlign: 'center' }}>
      Please add card to your browser.
		</p> */}
    {window.location.protocol !== 'https:' && (
      <p style={{ textAlign: 'center' }}>
        Try using{' '}
        <a href="https://ngrok.com" target="_blank" rel="noopener noreferrer">
          {' '}
          ngrok{' '}
        </a>{' '}
        to view this demo over https.
      </p>
    )}
  </Result>
);

const ELEMENT_OPTIONS = {
  style: {
    paymentRequestButton: {
      type: 'buy',
      theme: 'dark',
    },
  },
};

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canMakePayment: false,
      hasCheckedAvailability: false,
      errorMessage: null,
      // paymentMethod: null,
    };
  }

  async componentDidUpdate() {
    const { stripe } = this.props;

    if (stripe && !this.paymentRequest) {
      // Create PaymentRequest after Stripe.js loads.
      this.createPaymentRequest(stripe);
    }
  }

  async createPaymentRequest(stripe) {
    const { dispatch, __, handleClick } = this.props;
    this.paymentRequest = stripe.paymentRequest({
      country: Basket.getCountry().toUpperCase(),
      currency: Basket.getSelectedCurrency(),
      total: {
        // label: Basket.getMembershipName(),
        // amount: Basket.getMembershipTotal(),
        label: 'Label',
        amount: 100,
      },
    });

    this.paymentRequest.on('cancel', function () {
      dispatch(loading(false));
      // handle cancel event
    });

    this.paymentRequest.on('token', function (event) {
      const { token } = event;
      if (token.card && token.card.id) {
        handleClick(token, token.card.name);
        event.complete('success');
      } else {
        dispatch(showToast(__('Card id not found')));
        event.complete('fail');
      }
      dispatch(loading(false));
    });

    const canMakePayment = await this.paymentRequest.canMakePayment();
    this.setState({ canMakePayment, hasCheckedAvailability: true });
  }

  render() {
    const { canMakePayment, hasCheckedAvailability, errorMessage, paymentMethod } = this.state;

    return (
      <form>
        {canMakePayment && (
          <PaymentRequestButtonElement
            onClick={() => {
              this.props.dispatch(loading(true));
              this.setState({
                errorMessage:
                  'You can only use the PaymentRequest button once. Refresh the page to start over.',
              });
            }}
            className={
              this.props.location.pathname === '/card-add'
                ? 'paymentRequestButtonCardAdd'
                : 'paymentRequestButton'
            }
            options={{
              ...ELEMENT_OPTIONS,
              paymentRequest: this.paymentRequest,
            }}
          />
        )}
        {!canMakePayment && hasCheckedAvailability && <NotAvailableResult />}
        {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
        {paymentMethod && <Result>Got PaymentMethod: {paymentMethod.id}</Result>}
      </form>
    );
  }
}

const InjectedCheckoutForm = ({ clientSecret, dispatch, __, location, handleClick }) => (
  <ElementsConsumer>
    {({ stripe }) => (
      <CheckoutForm
        stripe={stripe}
        clientSecret={clientSecret}
        dispatch={dispatch}
        __={__}
        location={location}
        handleClick={handleClick}
      />
    )}
  </ElementsConsumer>
);

const CheckoutPay = ({ clientSecret, dispatch, __, location, handleClick }) => {
  const stripePromise = loadStripe(Stripe.getStripeInstance()._apiKey);
  return (
    <Elements stripe={stripePromise}>
      <InjectedCheckoutForm
        clientSecret={clientSecret}
        dispatch={dispatch}
        __={__}
        location={location}
        handleClick={handleClick}
      />
    </Elements>
  );
};

export default CheckoutPay;
