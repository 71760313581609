import React from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonButton,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
} from '@ionic/react';
import Layout from '../../../components/layout';
import { withTranslation } from '../../../lib/translate';
import {
  getIkentooMenu,
  getIkentooMenusForLocation,
  loading,
  setCommonModal,
  setDeliveryOption,
} from '../../../store/actions';
import { forwardTo, isDefined, isPromise } from '../../../lib/utils';
import { getConfig } from '../../../appConfig';
import Basket from '../../../lib/basket';
import api from '../../../lib/api';
import { Spacer, Subtitle, Title } from '../../../components/common';
import './index.css';
import moment from '../../../lib/moment';
import NoData from '../../../components/noData';
import Loading from '../../../components/spinner';

class DeliveryOptionsKiosk extends React.Component {
  state = {
    option: null,
  };

  setDeliveryOption = async (option) => {
    const { kioskData } = this.props;
    const resturantId = kioskData?.kiosk_restaurant_id
      ? kioskData.kiosk_restaurant_id
      : getConfig()?.kiosk
      ? getConfig().kiosk.restaurant_id
      : null;
    const choosenRestaurant = this.props.restaurants.find(
      (restaurant) => restaurant.id === resturantId,
    );
    this.setState({ selectedRestaurant: choosenRestaurant });
    this.props.dispatch(setDeliveryOption(option));
    this.selectRestaurant(parseInt(resturantId), option.id);
    this.props.dispatch(
      getIkentooMenusForLocation(choosenRestaurant.business_location_id, {
        pickTime: moment(Date.now()).format('HH:mm'),
        json_time_selector:
          option.id == 'kiosk-collection'
            ? choosenRestaurant.kiosk_json_time_selector
            : choosenRestaurant.kiosk_table_json_time_selector,
      }),
    );
  };

  selectRestaurant = (resturantId, orderType) => {
    const { restaurants, profile, kioskData } = this.props;
    const tableNumber =
      kioskData && kioskData.kiosk_table_number && kioskData.kiosk_table_number !== '-1'
        ? kioskData.kiosk_table_number
        : null;
    Basket.reset(profile.cardToken);
    Basket.setRestaurant(restaurants.find((restaurant) => restaurant.id === resturantId));
    Basket.setServicePercentage(0);
    Basket.setCollectionTime(null);
    Basket.setOrderType(orderType);
    if (orderType === 'kiosk-table') Basket.setTableNumber(tableNumber);
  };

  checkForSingleDeliveryOption = () => {
    const kioskDeliveryOptions = getConfig().kiosk.delivery.filter((el) => !el.isRemoved);
    const singleDeliveryOption =
      kioskDeliveryOptions.length === 1 ? kioskDeliveryOptions[0] : false;
    if (singleDeliveryOption && !isPromise(singleDeliveryOption))
      this.setDeliveryOption(singleDeliveryOption);
  };

  getKioskDeliveryOptions = () => {
    const { kioskData, restaurants } = this.props;
    const { kiosk } = getConfig();
    const kioskConfig = isDefined(kiosk)
      ? kiosk
      : {
          table: true,
          collection: true,
        };
    const delivery = kioskConfig.delivery;
    let resturantId = kioskData?.kiosk_restaurant_id
      ? kioskData.kiosk_restaurant_id
      : kiosk
      ? kiosk.restaurant_id
      : null;
    if (!isNaN(resturantId)) {
      resturantId = parseInt(resturantId);
    }
    const restaurant = restaurants.find((restaurant) => restaurant.id === resturantId);
    const kioskCollection =
      kioskConfig.collection && restaurant.can_kiosk_collection_order ? 'kiosk-collection' : '';
    const kisokTable = kioskConfig.table && restaurant.can_kiosk_table_order ? 'kiosk-table' : '';
    const kisokOptions =
      delivery &&
      delivery.filter((option) => option.id === kioskCollection || option.id === kisokTable);
    return kisokOptions || [];
  };

  componentDidMount() {
    const { defaultMenuId } = this.props;
    this.props.dispatch(loading(true));
    Basket.reset();
    this.checkForSingleDeliveryOption();

    api.getDefaultMenu(defaultMenuId).then((res) => {
      this.props.dispatch({
        type: 'SET_RESTAURANT_PROP',
        key: 'defaultMenu',
        value: { ...res, menuName: `${res.menuName} Default` },
        merge: true,
      });
    });
    this.props.dispatch(loading(false));
  }
  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });
  continueOnMenu = () => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu, selectedRestaurant } = this.state;
    if (selectedRestaurant && selectedIkentooMenu) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant.id,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };
  render() {
    const { __, isChooseMenuModalOpen, clientProfile } = this.props;
    const { selectedIkentooMenu } = this.state;
    const config = getConfig();
    const kioskConfig = isDefined(config.kiosk)
      ? config.kiosk
      : {
          table: true,
          collection: true,
        };
    const menus = this.props.ikentooMenusForLocation;
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    return (
      <Loading>
        <Layout scrollY={false} blank={true} color="transparent" noPadding={true}>
          <IonCard className="delivery-options-kiosk-card large-padding">
            <img
              className="client-modal-logo"
              src={clientProfile.logo_image}
              alt={clientProfile.buisiness_name}
            />
            <div className="modal-content">
              <Title className="large-title">{__('Start an order')}</Title>
              <Spacer />

              <IonList lines="none">
                {kioskConfig.delivery
                  .filter((el) => !el.isRemoved)
                  .map((d, index) => (
                    <div>
                      <div key={index}>
                        <IonButton
                          class="large-button"
                          expand="block"
                          onClick={() => this.setDeliveryOption(d)}
                        >
                          {__(d.label)}
                        </IonButton>
                      </div>
                      <Spacer size={1} />
                    </div>
                  ))}
              </IonList>
              <IonButton
                expand="block"
                onClick={() => forwardTo('/kiosk-dashboard', { keepKioskData: true })}
                color="secondary"
                fill="clear"
                className="large-button"
              >
                {'Cancel'}
              </IonButton>
            </div>
          </IonCard>
          {!menus.length ? null : (
            <>
              <div
                className="click-collect-pickers-backdrop"
                style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
                onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
              ></div>

              <div className={`click-collect-dialog ${animationMenuClass} kiosk-menu-dialog`}>
                <div className="click-collect-dialog-layout sc-ion-modal-md">
                  <div className="click-collect-dialog-header">
                    <Title>{__('Choose menu')}</Title>
                  </div>
                  <div
                    className="click-collect-dialog-closer"
                    onClick={() =>
                      this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))
                    }
                  >
                    <ion-icon
                      name="close"
                      role="img"
                      class="md hydrated"
                      aria-label="close"
                    ></ion-icon>
                  </div>
                  <div className="click-collect-dialog-content">
                    <IonList lines="none">
                      <IonRadioGroup
                        onIonChange={this.changeIkentooMenus}
                        value={selectedIkentooMenu}
                      >
                        {!menus.length ? (
                          <NoData />
                        ) : (
                          menus.map((menu) => {
                            const { ikentooMenuId, menuName } = menu;
                            return (
                              <IonItem
                                style={{ marginBottom: '10px' }}
                                className="box-wrapper"
                                key={ikentooMenuId}
                                lines="none"
                              >
                                <div tabIndex="-1"></div>
                                <IonLabel className="ion-text-wrap">
                                  <Subtitle>{menuName}</Subtitle>
                                </IonLabel>
                                <IonRadio color="primary" slot="start" value={ikentooMenuId} />
                              </IonItem>
                            );
                          })
                        )}
                      </IonRadioGroup>
                    </IonList>
                  </div>
                  <div className="click-collect-dialog-action">
                    <IonButton
                      disabled={menus.length > 0 ? false : true}
                      expand="block"
                      color="primary"
                      className="customized-button"
                      onClick={() => this.continueOnMenu()}
                    >
                      {__('Continue')}
                    </IonButton>
                  </div>
                </div>
              </div>
            </>
          )}
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { restaurants, ikentooMenu, ikentooMenusForLocation } = state.restaurants;
  const { isChooseMenuModalOpen, clientProfile } = state.common;
  const { kioskData } = state.profile;
  return {
    restaurants: restaurants || [],
    profile: state.profile.profile,
    deliveryOption: state.orders.deliveryOption,
    defaultMenuId: state.common.defaultMenuId,
    kioskData: kioskData,
    ikentooMenu: ikentooMenu || {},
    ikentooMenusForLocation: ikentooMenusForLocation || [],
    isChooseMenuModalOpen,
    clientProfile,
  };
};
export default connect(stateToProps)(withTranslation(DeliveryOptionsKiosk));
