import React from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from '../../lib/translate';
import { connect } from 'react-redux';
import './index.css';
import { NormalText } from '../common';
import { IonButton } from '@ionic/react';
import { forwardTo } from '../../lib/utils';
import { logout } from '../../store/actions';

const UserBar = ({ __, auth, profile, dispatch }) => {
  const isAuth = auth.loggedIn;
  return (
    <IonButton
      onclick={() => (isAuth ? null : forwardTo('/login'))}
      className={`user-bar-wrapper ${
        isAuth ? 'user-bar-wrapper--logged' : 'user-bar-wrapper--unlogged'
      }`}
    >
      <div>
        {isAuth ? (
          <NormalText>{`${__('Hi')} ${profile.first_name}, ${__('you’re logged in')}`}</NormalText>
        ) : (
          <NormalText>{__('Not logged in')}</NormalText>
        )}

        <IonButton
          onclick={() => (isAuth ? forwardTo('/loyalty') : () => {})}
          className="user-bar-rewards-btn"
        >
          {__('My rewards')}
        </IonButton>
        {isAuth ? (
          <IonButton onclick={() => dispatch(logout())} className="user-bar-auth-btn">
            {__('Log out')}
          </IonButton>
        ) : (
          <IonButton className="user-bar-auth-btn">{__('Log in')}</IonButton>
        )}
      </div>
    </IonButton>
  );
};
const stateToProps = (store) => {
  const { profile, auth } = store.profile;
  return {
    auth,
    profile,
  };
};
export default connect(stateToProps)(withRouter(withTranslation(UserBar)));
