import React from 'react';
import { IonCheckbox, IonItem, IonLabel, IonNote } from '@ionic/react';
import Basket from '../../lib/basket';
import { NormalText, StrongText, Subtitle, Title } from '../common';

const { formatPrice } = Basket;

export const SubscriptionLine = (__, clickHandler, key, isChecked, name, description, price) => (
  <IonItem lines="none" key={key}>
    <IonCheckbox color="primary" slot="start" checked={isChecked} onClick={clickHandler} />
    <div className="subscription-information">
      <Subtitle>{name}</Subtitle>
      <NormalText>{description}</NormalText>
    </div>
    <div>
      <Subtitle>{formatPrice(price)}</Subtitle>
      <NormalText>{__('per month')}</NormalText>
    </div>
  </IonItem>
);
