import React from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonItem,
  IonIcon,
  IonLabel,
  IonInput,
  IonHeader,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { withRouter } from 'react-router';
import {
  chevronDown,
  chevronForward,
  chevronUp,
  pencilOutline,
  trashBinOutline,
} from 'ionicons/icons';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import ValidateButton from '../../components/validateButton';
import Modal from '../../components/modal';
import Incrementer from '../../components/incrementer';
import { PillGroup } from '../../components/pill';
import { Title, SmallText, Spacer, NormalText, StrongText } from '../../components/common';
import OrderContent from '../../components/orderContent';
import { ApplyPoints } from '../../screens/applyPoints';
import {
  forwardTo,
  validateProfileData,
  goBack,
  go,
  isEmptyObject,
  isWebConfig,
} from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import moment from '../../lib/moment';
import { setProtectedReferrer, restoreAuth, removeVoucher } from '../../store/actions';
import { ReactComponent as TicketSvg } from '../../assets/images/ticket.svg';

import './index.css';
import { SET_COMMON_PROP } from '../../store/constants';

const {
  getRestauranName,
  getOrderDate,
  getOrderTime,
  changeItemQuantity,
  itemsCount,
  getItems,
  setMobile,
  getMobile,
  getAllergen,
  getTotal,
  isMinimumOrderTotalSatisfied,
  getTableNumber,
  getASAP,
} = Basket;

class OrderSummaryRaw extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    this.state = {
      quantityModal: null,
      applyPointsModalOpen: false,
      mobile: getMobile() || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
  }

  handleInput = (key, val) => {
    this.setState({ [key]: val });
    setMobile(val);
  };

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else {
      goBack();
    }
  };

  handleSubmit = () => {
    const { cards } = this.props;
    const kiosk = getConfig()?.kiosk ? getConfig()?.kiosk.active : false;
    if (!isMinimumOrderTotalSatisfied()) {
      // display toast with flag 'true'
      isMinimumOrderTotalSatisfied(true);
      return;
    }
    if (kiosk) {
      forwardTo('/checkout-kiosk');
    } else if (cards && cards.length >= 1) {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        forwardTo('/checkout', {
          giftVoucher: Basket.getDeliveryOption().id == 'gift-vouchers' ? true : false,
        });
        setMobile(this.props.profile.mobile);
      } else {
        forwardTo('/contact-details');
      }
    } else {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        setMobile(this.props.profile.mobile);
        forwardTo('/checkout', {
          giftVoucher: Basket.getDeliveryOption().id == 'gift-vouchers' ? true : false,
        });
      } else {
        forwardTo('/contact-details');
      }
    }
  };

  handleOrderItemClick = (item, index, quantity, restaurant) => {
    changeItemQuantity(index, quantity, false, restaurant);
  };
  updateItemQuantity = () => {
    const { item, index } = this.state.quantityModal;
    if (this.state.quantityModal) {
      changeItemQuantity(index, item.quantity);
      this.setState({ quantityModal: null });
    }
  };

  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantityModal: {
        ...this.state.quantityModal,
        item: {
          ...this.state.quantityModal,
          quantity: newQuantity,
        },
      },
    });
  };

  formatDayName = (__, name) => {
    if (name.includes('Today')) {
      name = 'Today'.toLowerCase();
    } else if (name.includes('Tomorrow')) {
      name = 'Tomorrow'.toLowerCase();
    } else {
      name = getOrderDate();
    }
    return `${__(name)}`;
  };
  isRedeemPointsDisabled = () => {
    const { redeemPointsMin } = getConfig().general;
    const { available_balance } = this.props.profile;
    return available_balance < redeemPointsMin ? true : false;
  };

  findSelectedSlot = (dayInWeek, time) => {
    const restaurant = Basket.getRestaurant();
    const selectedDay =
      !isEmptyObject(restaurant) &&
      restaurant.delivery_times_json &&
      !isEmptyObject(restaurant.delivery_times_json) &&
      restaurant.delivery_times_json.slots &&
      !isEmptyObject(restaurant.delivery_times_json.slots)
        ? restaurant.delivery_times_json.slots[dayInWeek]
        : null;
    if (selectedDay) {
      const selectedSlot = selectedDay.find(
        (day) =>
          moment(day.start_time, 'HH:mm').format('HH:mm') ===
          moment(time, 'HH:mm a').format('HH:mm'),
      );
      return (
        moment(selectedSlot.start_time, 'HH:mm').format('h:mm a') +
        ' - ' +
        moment(selectedSlot.end_time, 'HH:mm').format('h:mm a')
      );
    }
    return null;
  };

  drawContentHeader = (__, deliveryOption, orderType, deliveryAddress) => {
    let dateName = this.formatDayName(
      __,
      moment(getOrderDate(), 'dddd Do MMMM').calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
      }),
    );
    if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-point') {
      return (
        <>
          <Title>
            <strong>{__(deliveryOption.label)}</strong>
          </Title>
          <SmallText>
            {__('Ordering for')} {__('drop-off')} {__('at')} {getRestauranName()} {__(dateName)}{' '}
            {__('at')} {getOrderTime()}
            <br />
          </SmallText>
        </>
      );
    } else if (deliveryOption.id === 'charter-delivery' && !isEmptyObject(deliveryAddress)) {
      return (
        <>
          <Title>
            <strong>{__(deliveryOption.label)}</strong>
          </Title>
          <SmallText>
            {__('Ordering for')} {__('delivery')} {__('to')} {deliveryAddress.addressLine1},{' '}
            {deliveryAddress.place} {deliveryAddress.postalCode} {dateName} {__('at')}{' '}
            {getASAP() ? 'ASAP' : getOrderTime()}
            <br />
          </SmallText>
        </>
      );
    } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'table') {
      return (
        <>
          <Title>
            <strong>{__(deliveryOption.label)}</strong>
          </Title>
          <SmallText>
            {__('Ordering at')} {getRestauranName()} {__('for table')} {getTableNumber() + '.'}{' '}
            {__('Your order will be brought to you as soon as possible.')}
          </SmallText>
        </>
      );
    } else {
      return (
        <>
          <Title>
            <strong>{__(orderType)}</strong>
          </Title>
          <SmallText className="cnc-ordering-for">
            {__('Ordering for')} {getOrderDate()} {__('at')} {getOrderTime()}
            {/*<br />*/}
            {' ' + __('from')} {getRestauranName()}
          </SmallText>
        </>
      );
    }
  };

  handleApplyModal = (flag) => {
    const { history, auth } = this.props;
    const isAuth = auth.loggedIn;
    if (!isAuth) {
      this.props.dispatch(setProtectedReferrer(history.location.path));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
    } else {
      if (isWebConfig()) {
        this.setState({ applyPointsModalOpen: flag });
      } else {
        forwardTo('/apply-points');
      }
    }
  };
  handleApplyLoyaltyModal = (flag) => {
    const { history, auth } = this.props;
    const isAuth = auth.loggedIn;
    if (!isAuth) {
      this.props.dispatch(setProtectedReferrer(history.location.path));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
    } else {
      forwardTo('/apply-loyalty');
    }
  };

  getLablelTitle = (orderType) => {
    const tableNumber = Basket.getTableNumber();
    if (orderType === 'Click & Collect') {
      return 'Take Away';
    } else if (orderType === 'Table') {
      if (tableNumber) return `Table: ${tableNumber}`;
      return 'Eat In';
    } else return orderType;
  };

  render() {
    const { __, profile, auth, dispatch, onClickHandler, vouchers } = this.props;
    const { quantityModal, applyPointsModalOpen, mobile } = this.state;
    const valid = validateProfileData(profile).isValid;
    const allergens = getAllergen() || [];
    const isAuth = auth.loggedIn;
    const kiosk = getConfig()?.kiosk ? getConfig().kiosk.active : false;
    const appliedVoucher = Basket.getAppliedVocuher();
    const appliedPoints = Basket.getAppliedPoints();
    return (
      <>
        <div className=" flex-row-wrapper click-collect-content">
          <div className="summary-header-wrapper">
            <IonButton
              disabled={allergens.length == 0}
              onClick={() =>
                this.props.dispatch({
                  type: SET_COMMON_PROP,
                  key: 'allergenModalOpen',
                  value: true,
                })
              }
              className="summary-header-allergens"
            >
              {__('Allergens')}
            </IonButton>
            <div
              onClick={() =>
                this.setState({ orderSummaryVisible: !this.state.orderSummaryVisible }, () => {
                  onClickHandler(this.state.orderSummaryVisible);
                })
              }
            >
              <IonIcon icon={this.state.orderSummaryVisible ? chevronDown : chevronUp} />
              <Title>
                {`${Basket.itemsCountAll()} ${
                  Basket.itemsCountAll() == 1 ? __('Item') : __('Items')
                }`}
              </Title>{' '}
            </div>
            <IonButton
              onClick={this.handleSubmit}
              disabled={!isMinimumOrderTotalSatisfied()}
              color="primary"
            >{`${__('Pay')} ${Basket._getTotal(true)}`}</IonButton>
          </div>
          <div className="order-content-kiosk">
            <OrderContent
              showAddItems={true}
              handleOrderItemClick={this.handleOrderItemClick.bind(this)}
            />

            {Basket.process_fee_value > 0 && (
              <IonGrid style={{ marginBottom: '40px' }} className="box-content-wrapper ">
                <IonRow className="box-content basket-item-service-charge-row">
                  <IonCol className="paddLR grow ">{__('Items total')}</IonCol>
                  <IonCol className="righted paddLR self-center ">{Basket.getItemsTotal()}</IonCol>
                </IonRow>
                <IonRow className="box-content basket-item-service-charge-row">
                  <>
                    <IonCol className="paddLR grow ">
                      {Basket.processing_fee_description > 0
                        ? `${Basket.processing_fee_description}% `
                        : null}
                      {__('Order processing fee')}
                    </IonCol>
                    <IonCol className="righted paddLR self-center ">
                      {Basket.getProcessingFee()}
                    </IonCol>
                  </>
                </IonRow>
              </IonGrid>
            )}
            {isAuth &&
              getConfig().kiosk.hasLoyalty &&
              (vouchers.length > 0 || profile.available_balance > 0) && (
                <>
                  <div className="basket-loyalty-wrapper">
                    <Title className="centered">{__('Your loyalty')}</Title>
                    {!valid && getConfig().appType.hasEmailValidationEnabled ? (
                      <>
                        {isAuth && !valid && getConfig().appType.hasEmailValidationEnabled ? (
                          <NormalText className="centered block">
                            {__(
                              'You can earn, but not redeem points until your account is verified',
                            )}
                          </NormalText>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {appliedVoucher.length == 0 && !appliedPoints ? (
                          <>
                            <div className="basket-loyalty-options">
                              {vouchers.length > 0 && (
                                <div
                                  className="box-content box-shadow"
                                  onClick={() => forwardTo('/apply-vouchers')}
                                >
                                  <TicketSvg />
                                  <div>
                                    <StrongText className="block primary-color">
                                      {__('Apply a voucher')}
                                    </StrongText>
                                    <SmallText className="block primary-color">{`${
                                      vouchers.length
                                    } ${__('available')}`}</SmallText>
                                  </div>
                                  <IonIcon icon={chevronForward} />
                                </div>
                              )}

                              {profile.available_balance > 0 && (
                                <div
                                  className="box-content box-shadow"
                                  onClick={() => forwardTo('/apply-points')}
                                >
                                  <TicketSvg />

                                  <div>
                                    <StrongText className="block primary-color">
                                      {__('Redeem points')}
                                    </StrongText>
                                    <SmallText className="block primary-color">
                                      {' '}
                                      {`${profile.available_balance?.toLocaleString()} ${__(
                                        'points available',
                                      )}`}
                                    </SmallText>
                                  </div>
                                  <IonIcon icon={chevronForward} />
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <IonGrid>
                            {appliedPoints ? (
                              <IonRow className="box-content">
                                <IonCol className="paddLR grow ">
                                  {__('Applied points')}
                                  <IonButton
                                    onClick={() => Basket.applyPoints(0, () => {})}
                                    color="danger"
                                    fill="clear"
                                  >
                                    {__('Remove')} <IonIcon icon={trashBinOutline} />{' '}
                                  </IonButton>
                                </IonCol>
                                <IonCol className="righted paddLR righted ">
                                  {Basket._calculatePointsAppliedPrice(null, true)}
                                </IonCol>
                              </IonRow>
                            ) : null}
                            {appliedVoucher.length > 0 ? (
                              <IonRow className="box-content">
                                <IonCol className="paddLR grow ">
                                  {__('Applied voucher')}
                                  <IonButton
                                    onClick={() =>
                                      this.props.dispatch(removeVoucher(appliedVoucher[0].id))
                                    }
                                    color="danger"
                                    fill="clear"
                                  >
                                    {__('Remove')} <IonIcon icon={trashBinOutline} />{' '}
                                  </IonButton>
                                </IonCol>
                                <IonCol className="righted paddLR righted ">
                                  {Basket._calculateAppliedVocuhersPrice(true, null)}
                                </IonCol>
                              </IonRow>
                            ) : null}
                          </IonGrid>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            <IonGrid className="box-content-wrapper ">
              <IonRow
                className={`box-content
              ${
                Basket._getDeliveryPrice() > 0
                  ? 'amount-values total-bordered-top'
                  : kiosk
                  ? 'amount-values-kiosk'
                  : 'total-amount-values total-bordered-top'
              }`}
              >
                <IonCol className="paddLR">
                  <IonCol className="paddLR grow okx-font-secondary">{__('Total')}</IonCol>
                </IonCol>
                <IonCol className="righted paddLR okx-font-secondary">
                  {Basket._getTotal(true)}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>

        <Modal
          cssClass={`${kiosk ? 'quantity-modal-kiosk' : 'quantity-modal'} customIncrementModal`}
          title={__('Change quantity')}
          action={this.updateItemQuantity}
          actionLabel={__('Change')}
          isOpen={!!quantityModal}
          onDidDismiss={() => this.setState({ quantityModal: null })}
        >
          {quantityModal && quantityModal.item ? (
            <Incrementer
              allowNegative={false}
              quantity={quantityModal.item.quantity}
              onUpdate={this.onIncrementerUpdate}
            />
          ) : null}
        </Modal>

        <Modal
          cssClass="apply-points-modal"
          isOpen={applyPointsModalOpen}
          onDidDismiss={() => this.handleApplyModal(false)}
        >
          <ApplyPoints
            handleApplyModal={this.handleApplyModal}
            applyPointsModalOpen={applyPointsModalOpen}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { basketUpdated, deliveryOption, giftVoucherData } = store.orders;
  const { auth, vouchers, profile } = store.profile;
  return {
    basketUpdated,
    profile: store.profile.profile,
    cards: store.orders.cards || [],
    deliveryOption,
    itemAllergens: store.restaurants.itemAllergens,
    auth,
    giftVoucherData,
    vouchers: vouchers || [],
    profile,
  };
};

export const OrderSummary = connect(mapStateToProps)(withRouter(withTranslation(OrderSummaryRaw)));

class OrderSummaryWrapped extends React.Component {
  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipBackToThePreviousPage
    ) {
      forwardTo('/order');
    } else {
      goBack();
    }
  };
  render() {
    const { __ } = this.props;
    return (
      <Loading transparent>
        <Layout
          color="transparent"
          headerTitle={__('Order Summary')}
          backHandler={this.backHandler}
          scrollY={false}
        >
          <OrderSummary />
        </Layout>
      </Loading>
    );
  }
}

export default withTranslation(OrderSummaryWrapped);
