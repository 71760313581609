import React from 'react';
import { withRouter } from 'react-router';
import { IonButton, IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { forwardTo } from '../../lib/utils';
import { NormalText } from '../common';

const BackButton = (props) => {
  const { history, path, style, className, backHandler, iconColor, text, __ } = props;
  return (
    <IonButton
      className={className ? className : null}
      onClick={() => (backHandler ? backHandler() : path ? forwardTo(path) : history.goBack())}
      style={style ? { ...style } : {}}
    >
      <IonIcon icon={arrowBack} />
      {__('Back')}
    </IonButton>
  );
};

export default withRouter(BackButton);
