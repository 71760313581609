import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonCheckbox,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonItem,
  IonIcon,
  IonInput,
  IonAlert,
} from '@ionic/react';
import Layout from '../../../components/layout';
import Loading from '../../../components/spinner';
import Modal from '../../../components/modal';
import { loading, setDeliveryOption } from '../../../store/actions';
import { SmallText, Sectiontitle, NormalText } from '../../../components/common';
import { isDefined, isEmptyObject, makeKey, deepCopy, forwardTo } from '../../../lib/utils';
import { withTranslation } from '../../../lib/translate';
import Basket from '../../../lib/basket';
import { setScrollTop } from '../../../store/actions';
import { OrderSummary } from '../../orderSummary';
import ItemDetailsKiosk from '../itemDetailsKiosk';
import OrderList from './orderListForKiosk';
import { close, search } from 'ionicons/icons';
import './index.css';
import { getConfig } from '../../../appConfig';
import TileOrderList from '../../order/tileOrderList';
import { version as packageJsonVersion } from '../../../../package.json';

const {
  validateItem,
  getProductName,
  getProductDescription,
  _calculateItemPrice,
  formatPrice,
  addToBasket,
  isChoicesGroupValid,
} = Basket;

const defaultModalItemOptions = {
  quantity: 1,
  price: 0,
  selectedChoices: [],
  instructions: '',
  selectedMenu: [],
};

// category menu calc
const rowHeight = 35; // <-- within error of 1 or 2 pixels
const topOffset = 50;

class OrderPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategory: 0,
      ikentooMenu: null,
      originalMenu: null,
      searchItemsValue: '',
      menuRefs: null,
      categoriesPositionTop: [],
      modalOpen: false,
      modalItem: null,
      modalItemOptions: defaultModalItemOptions,
      validationErrors: [],
      showValidationAlert: false,
      previousRowNum: 1,
      menuHero: '',
      title: '',
      description: '',
      mainLogo: '',
      nameVisible: true,
      showCancelAlert: false,
      categoryRef: React.createRef(),
      modalRef: React.createRef(),
    };
    this.selectCategoryOnScroll = this.selectCategoryOnScroll.bind(this);
  }
  onContentScrollHandler() {
    const nameVisible = this.isScrolledIntoView(document.querySelector('.item-details-modal-name'));
    if (this.state.nameVisible != nameVisible) {
      if (!nameVisible) {
        this.setState({ nameVisible: false });
      } else {
        this.setState({ nameVisible: true });
      }
    }
  }
  handleSubmit = () => {
    const { cards } = this.props;
    const kiosk = getConfig()?.kiosk ? getConfig()?.kiosk.active : false;
    if (!Basket.isMinimumOrderTotalSatisfied()) {
      // display toast with flag 'true'
      Basket.isMinimumOrderTotalSatisfied(true);
      return;
    }
    if (kiosk) {
      forwardTo('/checkout-kiosk');
    } else if (cards && cards.length >= 1) {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        forwardTo('/checkout', {
          giftVoucher: Basket.getDeliveryOption().id == 'gift-vouchers' ? true : false,
        });
        Basket.setMobile(this.props.profile.mobile);
      } else {
        forwardTo('/contact-details');
      }
    } else {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        Basket.setMobile(this.props.profile.mobile);
        forwardTo('/checkout', {
          giftVoucher: Basket.getDeliveryOption().id == 'gift-vouchers' ? true : false,
        });
      } else {
        forwardTo('/contact-details');
      }
    }
  };
  isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top - 90;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    return isVisible;
  }

  setIkentooMenu = (menu) => {
    this.setState(
      {
        ikentooMenu:
          menu ||
          (isEmptyObject(this.props.ikentooMenu) ? this.props.defaultMenu : this.props.ikentooMenu),
        originalMenu:
          menu ||
          (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
            ? this.props.defaultMenu
            : this.props.ikentooMenu),
      },
      () => {
        let menuRefs = this.menuRefs(this.state.ikentooMenu);
        this.setState({ menuRefs });
        if (!isEmptyObject(this.state.ikentooMenu)) this.props.dispatch(loading(false));
      },
    );
  };

  onWindowResize = () => {
    setTimeout(() => {
      const catMenu = document.querySelector('.order-categories-inner');
      if (catMenu) {
        const rowNum = Math.round(catMenu.clientHeight / rowHeight);
        if (rowNum !== this.state.previousRowNum) {
          this.setState({ previousRowNum: rowNum });
          const subHeaders = document.querySelectorAll('.order-sublist-header');
          subHeaders.forEach((item) => (item.style.top = topOffset + rowNum * rowHeight + 'px'));
        }
      }
    }, 0);
  };
  observerModal() {
    this.props.dispatch(loading(true));
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.addedNodes.length > 0) {
          if (this.state.modalRef.current) {
            this.props.dispatch(loading(false));

            observer.disconnect();
          }
        }
      }
    });

    const config = { childList: true };
    observer.observe(document.querySelector('ion-app'), config);
  }
  componentDidMount() {
    this.observerModal();

    this.setState({
      selectedMenuType: getConfig().kiosk.menuDisplayType,
    });
    if (this.props.location.state) {
      this.setState({ selectedMenu: this.props.location.state.selectedMenu });
    }
    if (
      !isEmptyObject(this.props.defaultMenu) ||
      !isEmptyObject(this.props.ikentooMenu || !isEmptyObject(this.state.ikentooMenu))
    ) {
      this.props.dispatch(loading(false));
    } else {
      this.props.dispatch(loading(true));
    }
    this.setIkentooMenu();
    this.setState({
      menuHero: '',
      title: '',
      description: '',
      mainLogo: '',
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.clientProfileUpdated !== nextProps.clientProfileUpdated) {
      this.setState({
        menuHero: nextProps.clientProfile.hero_image,
        title: nextProps.clientProfile.buisiness_name,
        description: nextProps.clientProfile.buisiness_description,
      });
    }
    const prevMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
      ? nextProps.defaultMenu
      : nextProps.ikentooMenu || {}
    ).menuName;
    const currentMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
      ? this.props.defaultMenu
      : this.props.ikentooMenu || {}
    ).menuName;
    if (
      prevMenuName !== currentMenuName ||
      nextProps.restaurantsUpdated !== this.props.restaurantsUpdated
    ) {
      this.setIkentooMenu(
        Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
          ? nextProps.defaultMenu
          : nextProps.ikentooMenu,
      );
    }
    return true;
  }
  filterMenu(menu) {
    if (menu && menu.menuEntryGroups) {
      menu.menuEntryGroups = this.filterIkentooMenuItems(menu.menuEntryGroups);
      return menu;
    }
    return menu;
  }
  filterIkentooMenuItems(items) {
    let filteredItems = [];
    items.forEach((item) => {
      if (!item.menuEntry) {
        // break recursion when arrive to the product
        if (item.productName.toLowerCase().includes(`${this.state.searchItemsValue}`)) {
          filteredItems.push(item);
          return [item];
        } else {
          return [];
        }
      } else {
        const len = (item.menuEntry || []).length;
        if (len > 0) {
          const newFilteredItems = this.filterIkentooMenuItems(item.menuEntry);
          if (newFilteredItems.length > 0) {
            item.menuEntry = newFilteredItems;
            filteredItems.push(item);
          }
        }
      }
    });

    return filteredItems;
  }
  filterMenuItems = (value) => {
    this.setState({ searchItemsValue: value }, () => {
      if (getConfig().kiosk.menuDisplayType !== 1) {
        if (this.state.selectedMenuType !== 1 && this.state.searchItemsValue !== '') {
          this.setState({ selectedMenuType: 1 }, () => {
            const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
            const filtered = this.filterMenu(menu);
            this.setState({ ikentooMenu: filtered }, () => {
              let menuRefs = this.menuRefs(this.state.ikentooMenu);
              this.setState({ menuRefs });
            });
          });
        } else if (this.state.selectedMenuType === 1 && this.state.searchItemsValue === '') {
          this.setState({ selectedMenuType: getConfig().kiosk.menuDisplayType }, () => {
            const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
            const filtered = this.filterMenu(menu);
            this.setState({ ikentooMenu: filtered }, () => {
              let menuRefs = this.menuRefs(this.state.ikentooMenu);
              this.setState({ menuRefs });
            });
          });
        } else {
          const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
          const filtered = this.filterMenu(menu);
          this.setState({ ikentooMenu: filtered }, () => {
            let menuRefs = this.menuRefs(this.state.ikentooMenu);
            this.setState({ menuRefs });
          });
        }
      } else {
        const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
        const filtered = this.filterMenu(menu);
        this.setState({ ikentooMenu: filtered }, () => {
          let menuRefs = this.menuRefs(this.state.ikentooMenu);
          this.setState({ menuRefs });
        });
      }
    });
  };
  menuRefs = (menuRefs) => {
    return menuRefs && menuRefs.menuEntryGroups
      ? menuRefs.menuEntryGroups.reduce((acc, value, index) => {
          let name = value.name + '_' + index;
          acc[name] = React.createRef();
          return acc;
        }, {})
      : {};
  };

  scrollTo = (name) => {
    let orderListHeading = document.querySelector('.order-list-heading');
    let orderListHeadingHeight = 0;
    if (orderListHeading) {
      orderListHeadingHeight = orderListHeading.clientHeight;
    }
    const additionalHeight = document.querySelector('.order-list-restaurant-image') ? 50 : -5;
    document.querySelector('.no-padding').scroll({
      top: this.state.menuRefs[name].current.offsetTop + orderListHeadingHeight + additionalHeight,
      behavior: 'smooth',
    });
  };
  filterMenu(menu) {
    if (menu && menu.menuEntryGroups) {
      menu.menuEntryGroups = this.filterIkentooMenuItems(menu.menuEntryGroups);
      return menu;
    }
    return menu;
  }
  filterIkentooMenuItems(items) {
    let filteredItems = [];
    items.forEach((item) => {
      if (!item.menuEntry) {
        // break recursion when arrive to the product
        if (
          item.productName.toLowerCase().includes(`${this.state.searchItemsValue.toLowerCase()}`)
        ) {
          filteredItems.push(item);
          return [item];
        } else {
          return [];
        }
      } else {
        const len = (item.menuEntry || []).length;
        if (len > 0) {
          const newFilteredItems = this.filterIkentooMenuItems(item.menuEntry);
          if (newFilteredItems.length > 0) {
            item.menuEntry = newFilteredItems;
            filteredItems.push(item);
          }
        }
      }
    });

    return filteredItems;
  }

  selectCategoryOnScroll = (e) => {
    e.preventDefault();
    const headingInfo = document.querySelector('.order-list-restaurant-image');
    const positionTop =
      headingInfo && e.target.scrollTop - headingInfo.clientHeight > 0
        ? e.target.scrollTop - headingInfo.clientHeight
        : e.target.scrollTop;
    const { menuRefs } = this.state;
    setTimeout(() => {
      const positions = Object.keys(menuRefs).map((key) => {
        if (!menuRefs[key].current) {
          return null;
        }
        return menuRefs[key].current.offsetTop;
      });
      let selectCategory = 0;
      positions.forEach((item, i) => {
        if (item <= positionTop) {
          selectCategory = i;
        }
      });
      this.props.dispatch(setScrollTop(positionTop));
      this.setState({ selectedCategory: selectCategory });
    }, 150);
  };

  showModal = (modalItem) => {
    this.setState(
      {
        modalItem,
        modalItemOptions: {
          ...this.state.modalItemOptions,
          price: _calculateItemPrice({ item: modalItem, quantity: 1 }),
          selectedChoices:
            modalItem.menuDealGroups && modalItem.menuDealGroups.length
              ? Array(modalItem.menuDealGroups.length).fill([])
              : [],
          validationErrors:
            modalItem.menuDealGroups && modalItem.menuDealGroups.length
              ? Array(modalItem.menuDealGroups.length).fill(null)
              : [],
        },
        modalOpen: true,
      },
      () => {
        setTimeout(() => {
          const splitPane = document.querySelector('ion-split-pane');
          if (splitPane) {
            splitPane.classList.add('blur');
          }
        }, 300);
      },
    );
  };

  handleInputChange = (groupIndex, choiceIndex, multiSelectionPermitted, event) => {
    const item = this.state.modalItem;
    const { selectedChoices, quantity } = this.state.modalItemOptions;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[groupIndex];
    if (selectedGroup) {
      const selectedChoice = selectedGroup.items[choiceIndex];
      let updatedSelectedChoices = deepCopy(selectedChoices);
      if (multiSelectionPermitted) {
        //checkbox
        if (event.target.checked) {
          updatedSelectedChoices[groupIndex].push(selectedChoice);
        } else {
          updatedSelectedChoices[groupIndex] = updatedSelectedChoices[groupIndex].filter(
            (i) => i.sku !== selectedChoice.sku,
          );
        }
      } else {
        //radio
        if (!selectedChoice) {
          updatedSelectedChoices[groupIndex] = [];
        } else {
          updatedSelectedChoices[groupIndex] = [selectedChoice];
        }
      }

      this.setState(
        {
          modalItemOptions: {
            ...this.state.modalItemOptions,
            selectedChoices: updatedSelectedChoices,
          },
        },
        () => {
          const { selectedChoices } = this.state.modalItemOptions;
          //recalculate item price on every menu deal choice change
          this.setState({ price: _calculateItemPrice({ item, quantity, selectedChoices }) }, () => {
            const validationErrors = validateItem(this.constructBasketItem());
            this.setState({ validationErrors: validationErrors.errors });
          });
        },
      );
    }
  };

  drawGroupChoices = (choices = [], multiSelectionPermitted, groupIndex) => {
    const { selectedChoices } = this.state.modalItemOptions;
    const { profile, __ } = this.props;
    const allChoices = choices.map((item, choiceIndex) => {
      const { sku, productPrice } = item;
      const isChecked = !!(selectedChoices[groupIndex] || []).find((i) => i.sku === sku);
      return (
        <IonItem lines="none" key={makeKey(choiceIndex, sku, groupIndex)}>
          <div tabIndex="-1"></div>
          {multiSelectionPermitted ? (
            <IonCheckbox
              color="primary"
              slot="start"
              checked={isChecked}
              onIonChange={(event) => {
                this.handleInputChange(groupIndex, choiceIndex, multiSelectionPermitted, event);
              }}
            />
          ) : (
            <IonRadio
              slot="start"
              className="details-radio"
              color="primary"
              value={choiceIndex}
              checked={isChecked}
            />
          )}
          <IonLabel className="ion-text-wrap">
            <Sectiontitle className="single-item">{__(getProductName(item, profile))}</Sectiontitle>
            {getProductDescription(item, profile) ? (
              <SmallText className="no-margin">{getProductDescription(item, profile)}</SmallText>
            ) : null}
          </IonLabel>
          <p>{formatPrice(productPrice)}</p>
        </IonItem>
      );
    });
    if (multiSelectionPermitted) {
      return allChoices;
    } else {
      //radio
      return (
        <IonRadioGroup
          onIonChange={(event) => {
            this.handleInputChange(groupIndex, event.target.value, multiSelectionPermitted, event);
          }}
        >
          {allChoices}
        </IonRadioGroup>
      );
    }
  };

  drawGroupLabel = (label, groupIndex) => {
    const { validationErrors } = this.state;
    const { __ } = this.props;

    return (
      <>
        <IonItem>
          <div className="sectiontitle">{label}</div>
        </IonItem>
        {validationErrors[groupIndex] ? (
          <div className="field-error">{__(validationErrors[groupIndex])}</div>
        ) : null}
      </>
    );
  };

  drawMenuDealGroups = (menuGroupItem, index) => {
    // multiSelectionPermitted = true  --> only one item must be selected
    const multiSelectionPermitted = menuGroupItem.multiSelectionPermitted;
    if (isChoicesGroupValid(menuGroupItem)) {
      if (isDefined(multiSelectionPermitted)) {
        return (
          <div key={index}>
            {this.drawGroupLabel(menuGroupItem.description, index)}
            {this.drawGroupChoices(menuGroupItem.items, multiSelectionPermitted, index)}
          </div>
        );
      } else {
        return this.drawGroupLabel(menuGroupItem.description);
      }
    }
  };

  constructBasketItem = () => {
    const { modalItem, modalItemOptions } = this.state;
    const { quantity, selectedChoices, instructions } = modalItemOptions;
    let constructModel = {};
    if (modalItem) {
      constructModel = {
        item: modalItem,
        quantity,
        selectedChoices,
        instructions,
      };
    }
    return constructModel;
  };

  onIncrementerUpdate = (newQuantity) => {
    const price = _calculateItemPrice({
      item: this.state.modalItem,
      quantity: newQuantity,
      selectedChoices: this.state.modalItemOptions.selectedChoices,
    });
    this.setState({
      modalItemOptions: { ...this.state.modalItemOptions, quantity: newQuantity, price },
    });
  };

  addToOrder = () => {
    const newBasketItem = this.constructBasketItem();
    const validationErrors = validateItem(newBasketItem);
    if (validationErrors.errorCount > 0) {
      this.setState({ validationErrors: validationErrors.errors });
      this.setState({ validationErrors: validationErrors.errors }, () => {
        this.setShowValidationAlert(true);
      });
    } else {
      addToBasket(newBasketItem);
      this.setState({
        modalItem: null,
        modalItemOptions: defaultModalItemOptions,
        modalOpen: false,
      });
      /*forwardTo('/order-summary')
      forwardTo('/order-summary', { fromItemDetails: true })*/
    }
  };
  isStoreOpened = () => {
    return 'OPEN';
    // const { restaurants } = this.props;
    // const filteredRestaurants = restaurants.filter((restaurant) => restaurant.name !== 'Vouchers');
    // const selectedRestaurant = filteredRestaurants[0];
    // let currentDay = new Date().getDay();
    // if (currentDay == 0) {
    //   currentDay = 7;
    // }
    // let label = '';

    // const openingTimes = selectedRestaurant.json_opening_time_info[currentDay - 1]?.time;
    // if (openingTimes && openingTimes.toLowerCase() !== 'closed') {
    //   const openingTimesForToday = openingTimes.split('-').map((time) => {
    //     return {
    //       hour: time.split(':')[0].trim(),
    //       minutes: time.split(':')[1].trim(),
    //     };
    //   });
    //   const startTime = openingTimesForToday[0];
    //   const closeTime = openingTimesForToday[1];
    //   const current = moment().format('HH-mm').split('-');
    //   const currentTime = { hour: current[0], minutes: current[1] };
    //   if (currentTime.hour > closeTime.hour || currentTime.hour < startTime.hour) {
    //     label = 'CLOSED';
    //   } else if (currentTime.hour < closeTime.hour && currentTime.hour > startTime.hour) {
    //     label = 'OPEN';
    //   } else if (currentTime.hour === closeTime.hour) {
    //     if (currentTime.minutes == closeTime.minutes) {
    //       label = 'OPEN';
    //     } else if (currentTime.minutes > closeTime.minutes) {
    //       label = 'OPEN';
    //     } else {
    //       label = 'CLOSED';
    //     }
    //   } else if (currentTime.hour === startTime.hour) {
    //     if (currentTime.minutes == startTime.minutes) {
    //       label = 'OPEN';
    //     } else if (currentTime.minutes > startTime.minutes) {
    //       label = 'OPEN';
    //     } else {
    //       label = 'CLOSED';
    //     }
    //   }
    // } else {
    //   label = 'CLOSED';
    // }
    // return label;
  };
  getClass = (id) => {
    let backgroundImage;
    switch (id) {
      case 'delivery':
        backgroundImage = 'delivery-Icon';
        break;
      case 'charter-delivery':
        backgroundImage = 'delivery-icon';
        break;
      case 'table':
        backgroundImage = 'table-icon';
        break;
      default:
        backgroundImage = 'collection-icon';
    }
    return backgroundImage;
  };
  instructionsChange = (event) => this.setState({ instructions: event.target.value });

  closeModal = () =>
    this.setState(
      {
        modalOpen: false,
        modalItemOptions: defaultModalItemOptions,
        validationErrors: [],
        nameVisible: true,
      },
      () => {
        const splitPane = document.querySelector('ion-split-pane');
        if (splitPane) {
          splitPane.classList.remove('blur');
        }
      },
    );
  setDeliveryOption = (delivery) => {
    this.props.dispatch(setDeliveryOption(delivery));
    Basket.setDeliveryOption(delivery);
    Basket.setOrderType(delivery.id);
    forwardTo(
      delivery.id === 'delivery'
        ? '/delivery'
        : delivery.id === 'charter-delivery'
        ? '/delivery'
        : delivery.id === 'pick-up-point'
        ? '/pick-up-point'
        : delivery.id === 'table'
        ? '/order-to-table'
        : '/click-and-collect',
      { isLogoClicked: false },
    );
  };

  selectMenu = (index) => {
    const selectedItem = index;
    const currentCategories = this.state.selectedMenu ? [...this.state.selectedMenu] : [];
    currentCategories.push(selectedItem);
    this.setState({ selectedMenu: currentCategories }, () => {
      this.state.categoryRef.current.scrollIntoView();
    });
  };
  parseCategories = (data) => {
    const { menuDisplayType, ikentooMenu } = data;
    const selectedMenu = data.selectedMenu || [];

    let categories;
    if (menuDisplayType !== 1) {
      categories = !isEmptyObject(ikentooMenu) ? ikentooMenu : [];
      if (selectedMenu.length > 0) {
        categories = categories.menuEntryGroups[selectedMenu[0]];
        for (let i = 1; i < selectedMenu.length; i++) {
          categories = categories.menuEntry[selectedMenu[i]];
        }
      }
    } else {
      categories = !isEmptyObject(ikentooMenu) ? ikentooMenu.menuEntryGroups : [];
    }
    return categories;
  };
  backHandler = () => {
    let menuDisplayType = this.state.selectedMenuType;
    const selectedMenus = this.state.selectedMenu || [];
    if (menuDisplayType !== 1 && selectedMenus.length > 0) {
      const items = this.state.selectedMenu;
      items.pop();
      this.setState({ selectedMenu: items });
    } else {
      this.setState({ showCancelAlert: true });
    }
  };
  onOrderSummaryOpenHandler = (boolean) => {
    this.setState({ orderSummaryVisible: boolean });
  };
  render() {
    const { __, profile } = this.props;
    const {
      selectedCategory,
      ikentooMenu,
      modalOpen,
      modalItem,
      showCancelAlert,
      selectedMenu,
      orderSummaryVisible,
      editItem,
    } = this.state;
    let menuDisplayType = this.state.selectedMenuType;

    // let categories = !isEmptyObject(ikentooMenu) ? ikentooMenu.menuEntryGroups : [];
    let modalItemImage =
      modalItem && modalItem.itemRichData && modalItem.itemRichData.squareImageUrl
        ? modalItem.itemRichData.squareImageUrl
        : '';

    if (modalItemImage && modalItemImage.indexOf('http://') !== -1) {
      modalItemImage = modalItemImage.replace(/http:\/\//g, 'https://');
    }
    let categories = this.parseCategories({ selectedMenu, ikentooMenu, menuDisplayType });
    return (
      <Loading>
        <Layout
          onPayButtonSubmitHandler={() => this.handleSubmit()}
          showPayButton={Basket.itemsCount() > 0}
          hasUserBar
          noPadding
          scrollY={false}
          color="transparent"
          selectCategoryOnScroll={this.selectCategoryOnScroll}
          hideSecondToolbar={true}
          headerTitle={__(Basket.getOrderType())}
          headerWithTitle={true}
          disableForwardTo={true}
          backButtonText={
            menuDisplayType !== 1 && this.state.selectedMenu && this.state.selectedMenu.length > 0
              ? __('')
              : __('Back')
          }
          backHandler={this.backHandler}
          staticHeaderClassName={`kiosk-header`}
          showHamburger={false}
        >
          <>
            {!isDefined(ikentooMenu) && !isEmptyObject(ikentooMenu) && !ikentooMenu ? null : (
              <div ref={this.state.categoryRef} className={'order-list order-list-full'}>
                <div className="order-categories order-categories-kiosk">
                  <div className="search-box-wrapper">
                    <div className="search-box search-box-kiosk">
                      <IonIcon color="primary" icon={search}></IonIcon>
                      <IonInput
                        placeholder={__('Search products')}
                        value={this.state.searchItemsValue}
                        onIonChange={(e) => {
                          this.filterMenuItems(e.target.value);
                        }}
                      />
                      <IonIcon
                        onClick={() => this.setState({ searchItemsValue: '' })}
                        className="reset-icon"
                        color="primary"
                        icon={close}
                      ></IonIcon>
                    </div>
                  </div>
                  {menuDisplayType == 1 && (
                    <>
                      {categories.length > 0 ? (
                        <div className="order-categories-inner">
                          {categories.map((category, index) => {
                            return (
                              <IonButton
                                key={index}
                                size="small"
                                fill="clear"
                                className={
                                  selectedCategory === index
                                    ? 'category-button active'
                                    : 'category-button'
                                }
                                onClick={() => this.scrollTo(`${category.name}_${index}`, index)}
                              >
                                {__(category.name)}
                              </IonButton>
                            );
                          })}
                        </div>
                      ) : (
                        <NormalText className="no-items-message">{__('No items found')}</NormalText>
                      )}
                    </>
                  )}
                </div>
                {menuDisplayType == 1 ? (
                  <>
                    <div className="order-content">
                      <OrderList
                        scrollTopPosition={this.props.scrollTop}
                        selectCategoryOnScroll={this.selectCategoryOnScroll}
                        showModal={this.showModal}
                        category={!isEmptyObject(ikentooMenu) ? ikentooMenu : null}
                        menuRefs={this.state.menuRefs ? this.state.menuRefs : []}
                        basketHaveItems={Basket.items.length > 0}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="tiles-order-content">
                      <TileOrderList
                        selectMenu={(index) => {
                          this.selectMenu(index);
                        }}
                        showModal={this.showModal}
                        category={categories}
                        selectedMenu={this.state.selectedMenu}
                      ></TileOrderList>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        </Layout>
        <IonAlert
          isOpen={showCancelAlert}
          onDidDismiss={() => this.setState({ showCancelAlert: false })}
          header={__('Cancel order')}
          message={__('Your order would be deleted')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.setState({ showCancelAlert: false }),
            },
            {
              text: __('Confirm'),
              handler: () => forwardTo('/kiosk-dashboard', { keepKioskData: true }),
            },
          ]}
        />
        <Modal
          ref={this.state.modalRef}
          className={`item-details-modal ${modalOpen ? 'visible' : ''}`}
          isOpen={true}
          onDidDismiss={() => this.closeModal()}
          backdropDismiss={false}
        >
          {modalItem && modalOpen ? (
            <ItemDetailsKiosk
              basketItem={this.constructBasketItem()}
              profile={profile}
              modalOpen={modalOpen}
              closeModal={this.closeModal}
              edit={editItem}
            />
          ) : null}
        </Modal>
        {Basket.items.length > 0 ? (
          <div
            className={`order-summary-kiosk ${
              this.state.orderSummaryVisible ? 'order-summary-kiosk-visible' : ''
            }`}
          >
            <OrderSummary onClickHandler={this.onOrderSummaryOpenHandler} />
            <SmallText className="app-version">v{packageJsonVersion}</SmallText>
          </div>
        ) : null}
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, clientProfile, clientProfileUpdated } = state.common;
  const { restaurants, ikentooMenu, defaultMenu, restaurantsUpdated } = state.restaurants;
  const { scrollTop } = state.orders;
  return {
    auth,
    restaurants: restaurants || [],
    ikentooMenu: ikentooMenu || {},
    defaultMenu: defaultMenu || {},
    basketUpdated: state.orders.basketUpdated,
    scrollTop,
    storedItemWeb: state.orders.storedItemWeb,
    restaurantsUpdated,
    clientProfile,
    clientProfileUpdated,
    cards: state.orders.cards || [],
  };
};

export default connect(stateToProps)(withTranslation(OrderPage));
