import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import './index.css';
import {
  isDefined,
  isEmptyObject,
  forwardTo,
  getSingleDeliveryOption,
  checkBackgroundColor,
} from '../../lib/utils';
import { getConfig } from '../../appConfig';
import { NormalText, Subtitle, Title } from '../../components/common';

const { hasOrdering } = getConfig().appType;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      singleDelivery: null,
      image: '',
      dashboardWithTiles: false,
      backgorundColor: false,
    };
  }

  componentDidMount() {
    const styles = this.props.clientStyles.colors?.['--okx-dashboard-background'];
    if (styles) {
      this.setState({ backgorundColor: styles });
    }
    const { clientProfile } = this.props;
    if (getConfig().general.dashboardWithTiles) {
      this.setState({ dashboardWithTiles: true });
    }
    getSingleDeliveryOption().then((res) => {
      this.setState({
        singleDelivery: res,
        image: clientProfile.main_image,
      });
    });
  }

  render() {
    const { __, screenName } = this.props;
    const { singleDelivery, image } = this.state;
    const dashboardContent = getConfig().dashboardConfig;
    return (
      <Layout
        headerIconColors={
          getConfig().general.dashboardWithTiles
            ? this.state.backgorundColor
              ? checkBackgroundColor(this.state.backgorundColor)
              : 'white'
            : 'dark'
        }
        color="transparent"
        noPadding={true}
      >
        {!this.state.dashboardWithTiles ? (
          <>
            <div
              className="absolute-content dash-layout"
              style={{ backgroundImage: `url(${image})` }}
            ></div>
            <IonCard className="dash-card">
              <IonCardHeader className="ion-text-center">
                <IonLabel color="dark">{__('Welcome Back')}</IonLabel>
                <IonCardTitle>{screenName}</IonCardTitle>
              </IonCardHeader>

              <IonCardContent className="dash-menu">
                <IonList>
                  {getConfig().delivery.filter((d) => !d.isRemoved && !d.isDisabled).length > 0 && (
                    <>
                      {hasOrdering ? (
                        singleDelivery ? (
                          <IonItem
                            className="clickable"
                            onClick={() => forwardTo('/click-and-collect')}
                          >
                            <div tabIndex="-1"></div>
                            <IonLabel>{__(singleDelivery?.label)}</IonLabel>
                          </IonItem>
                        ) : (
                          <IonItem
                            className="clickable"
                            onClick={() => forwardTo('/delivery-options')}
                          >
                            <div tabIndex="-1"></div>
                            <IonLabel>{__('Start New Order')}</IonLabel>
                          </IonItem>
                        )
                      ) : null}
                    </>
                  )}
                  <IonItem className="clickable" onClick={() => forwardTo('/loyalty')}>
                    <div tabIndex="-1"></div>
                    <IonLabel>{__('Loyalty')}</IonLabel>
                  </IonItem>
                  <IonItem lines="none" className="clickable" onClick={() => forwardTo('/account')}>
                    <div tabIndex="-1"></div>
                    <IonLabel>{__('My Account')}</IonLabel>
                  </IonItem>
                </IonList>
              </IonCardContent>
            </IonCard>
          </>
        ) : (
          <>
            <div className="dashboard-content">
              <div className="dashboard-header">
                <Subtitle className="uppercase bold letter-spacing-big">
                  {__(dashboardContent?.title)}
                </Subtitle>
                <Title>
                  {__('Welcome')} {screenName}
                </Title>
                <NormalText>{__(dashboardContent?.description)}</NormalText>
              </div>
              <div className="dashboard-cards-wrapper">
                {dashboardContent?.data.map((item) => {
                  return (
                    <div
                      style={{ backgroundImage: `url(${item.image})` }}
                      className="dashboard-card "
                      onClick={() => forwardTo(item.path)}
                    >
                      <Subtitle>{__(item.title)}</Subtitle>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { clientProfile, clientStyles } = state.common;
  let screenName = '';
  if (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) {
    screenName = profile.first_name;
  }
  return {
    screenName,
    clientProfile,
    clientStyles,
  };
};

export default connect(stateToProps)(withTranslation(Dashboard));
