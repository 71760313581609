import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from '../../../lib/translate';
import './index.css';
import { isEmptyObject, forwardTo } from '../../../lib/utils';
import { loading, logout, updateKioskData } from '../../../store/actions';
import { getConfig } from '../../../appConfig';
import { PAUSE_TIMER, SET_COMMON_PROP, START_TIMER, STOP_TIMER } from '../../../store/constants';
import api from '../../../lib/api';
import Loading from '../../../components/spinner';

class KioskDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageCount: 0,
    };
  }

  async componentDidMount() {
    const { dispatch, profile, restaurants } = this.props;
    if (!isEmptyObject(profile)) {
      this.props.dispatch(logout());
    }
    const search = this.props.location.search;
    const kioskDataFromUrl = {
      kiosk_auth_email: new URLSearchParams(search).get('email') || 'guest@5loyalty.com',
      kiosk_password: new URLSearchParams(search).get('auth'),
      kiosk_restaurant_id: new URLSearchParams(search).get('restaurant_id'),
      kiosk_zoom: new URLSearchParams(search).get('zoom') || '100',
      kiosk_is_disabled: new URLSearchParams(search).get('disabled'),
      kiosk_table_number: new URLSearchParams(search).get('table_number'),
      pos_location: new URLSearchParams(search).get('pos_location') || 'tml_EmdZwAFsuok3tO',
      full_screen_basket_mode: new URLSearchParams(search).get('full_screen_basket_mode')
        ? Boolean(parseInt(new URLSearchParams(search).get('full_screen_basket_mode')))
        : false,
    };
    kioskDataFromUrl.kiosk_zoom =
      parseInt(kioskDataFromUrl?.kiosk_zoom) <= 100 ? '100' : kioskDataFromUrl?.kiosk_zoom;
    if (!this.props.location.state?.keepKioskData) {
      dispatch(updateKioskData(kioskDataFromUrl));
    }
    this.props.dispatch({ type: STOP_TIMER });
    this.props.dispatch({ type: SET_COMMON_PROP, key: 'restartOrderAlert', value: false });
    const restaurantId =
      kioskDataFromUrl?.kiosk_restaurant_id ||
      JSON.parse(localStorage.getItem('kioskData')).kiosk_restaurant_id ||
      null;
    let selectedRestaurant = this.props.restaurants.find((r) => r.id == restaurantId);
    if (selectedRestaurant) {
      const menusImages = await api.getImagesFromMenus(selectedRestaurant.business_location_id);
      if (menusImages) {
        this.props.dispatch(loading(true));
        const imagesToPreload = menusImages
          .filter(Boolean)
          .map((el) => (el.indexOf('http://') !== -1 ? el.replace(/http:\/\//g, 'https://') : el));
        this.setState({ imagesToPreloadNum: imagesToPreload.length });
        document.querySelectorAll('.cached-img').forEach((el) => document.body.removeChild(el));
        for (let i = 0; i < imagesToPreload.length; i++) {
          const image = new Image();
          image.src = imagesToPreload[i];
          image.classList.add('cached-img');
          image.onload = () => {
            this.setState({ imageCount: this.state.imageCount + 1 });
          };
          document.body.appendChild(image);
        }
        this.props.dispatch(loading(false));
      }
    }
  }

  startOrder = () => {
    const { kiosk } = getConfig();
    const restartOrderTimer = kiosk ? kiosk?.restartOrderTimer : false;
    const restartTime = kiosk ? kiosk.restartTime : 120;
    if (restartOrderTimer)
      this.props.dispatch({ type: START_TIMER, data: restartTime, cb: this.timerHasEndedHandler });
    forwardTo('/delivery-options-kiosk');
  };

  timerHasEndedHandler = () => {
    this.props.dispatch({ type: SET_COMMON_PROP, key: 'restartOrderAlert', value: true });
    this.props.dispatch({ type: PAUSE_TIMER });
  };

  render() {
    const { kiosk } = getConfig();
    const { kioskData } = this.props;
    document.documentElement.style.setProperty('--zoom', `${kioskData.kiosk_zoom}%`);
    document.documentElement.style.setProperty('--zoom-value', `${kioskData.kiosk_zoom / 100}`);
    if (kioskData.kiosk_is_disabled === '1') {
      document.documentElement.style.setProperty(
        '--image-url',
        `url(${kiosk.disabledSplashImage ? kiosk.disabledSplashImage : ''})`,
      );
    } else {
      document.documentElement.style.setProperty(
        '--image-url',
        `url(${kiosk.splashImage ? kiosk.splashImage : ''})`,
      );
    }
    return (
      <Loading>
        <div
          className="kiosk-bg"
          onClick={() => {
            if (kioskData.kiosk_is_disabled === '0' || !kioskData.kiosk_is_disabled) {
              this.startOrder();
            }
          }}
        ></div>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile, vouchers, kioskData } = state.profile;
  return {
    profile: profile || {},
    vouchers: vouchers || [],
    kioskData: kioskData || {},
    restaurants: state.restaurants.restaurants || [],
  };
};

export default connect(stateToProps)(withTranslation(KioskDashboard));
