import React, { Component } from 'react';
import { IonGrid, IonRow, IonCol, IonButton, IonIcon } from '@ionic/react';
import { NormalText, SmallText, Spacer, StrongText } from '../common';
import { makeKey, isDefined, forwardTo, isWebConfig } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import './index.css';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ServiceCharge } from '../../screens/service-charge';
import Modal from '../modal';
import { getConfig } from '../../appConfig';
import { removeVoucher, setModal } from '../../store/actions';
import Incrementer from '../incrementer';
import { pencilOutline, trashBinOutline } from 'ionicons/icons';
import ItemDetailsKiosk from '../../screens/kiosk/itemDetailsKiosk';

class OrderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceChargeModalOpen: false,
      showCharge: false,
    };
  }

  handleServiceChargeModal = (flag) => {
    this.setState({ serviceChargeModalOpen: flag, showCharge: !flag });
  };
  closeModal = () =>
    this.setState({
      modalOpen: false,

      nameVisible: true,
    });
  drawSubItems = (basketItem = {} /*, basketItemIndex*/) => {
    const { selectedChoices } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;

    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <IonRow
              key={makeKey(
                choiceGroupIndex,
                choiceIndex,
                sku,
              )} /*onClick={ () => this.handleOrderItemClick(basketItem, basketItemIndex) }*/
            >
              <IonCol size="50px"></IonCol>
              <IonCol className="paddLR grow">
                {choice.quantity} x {Basket.getProductName(choice, profile)}
              </IonCol>
              <IonCol className="righted paddLR self-aligned">
                {basketInstance.calculateSubItemPrice(choice, choice.quantity) !== 0
                  ? basketInstance._calculateSubItemPrice(choice, choice.quantity)
                  : null}
              </IonCol>
            </IonRow>
          );
        });
      });
    }
    return null;
  };
  drawSubItemsText = (basketItem = {}) => {
    const { selectedChoices } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;
    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <IonRow
              className="sub-item"
              key={makeKey(
                choiceGroupIndex,
                choiceIndex,
                sku,
              )} /*onClick={ () => this.handleOrderItemClick(basketItem, basketItemIndex) }*/
            >
              <IonCol className="paddLR grow">
                <SmallText>
                  {choice.quantity} x {Basket.getProductName(choice, profile)}
                </SmallText>
              </IonCol>
              <IonCol className="righted paddLR self-aligned">
                <SmallText>
                  {basketInstance.calculateSubItemPrice(choice, choice.quantity) !== 0
                    ? basketInstance._calculateSubItemPrice(choice, choice.quantity)
                    : null}
                </SmallText>
              </IonCol>
            </IonRow>
          );
        });
      });
    }
    return null;
  };
  showModal = (basketItem, basketItemIndex) => {
    this.setState({ modalOpen: !this.state.modalOpen, basketItem, basketItemIndex });
  };
  render() {
    const {
      handleOrderItemClick,
      __,
      history,
      profile,
      showAddItems,
      totalClass,
      isRemoveVoucherModalOpen,
      dispatch,
      className,
    } = this.props;
    const basketInstance = this.props.basketInstance || Basket;
    const type = this.props.type || 'order';
    const orderType = basketInstance.getOrderType();
    const appliedPoints = basketInstance.getAppliedPoints();
    const deliveryPrice = basketInstance.getDeliveryPrice();
    const deliveryPriceUnformated = basketInstance._getDeliveryPrice();
    const { serviceChargeModalOpen } = this.state;
    const appliedVoucher = basketInstance.getAppliedVocuher();
    let location =
      history && history.location && history.location.pathname === '/history-details'
        ? true
        : false;
    const kiosk = getConfig().kiosk ? getConfig().kiosk.active : false;
    const { modalOpen } = this.state;
    return (
      <>
        <IonGrid className="order-content-wrapper paddL">
          <div className="basket-items-wrapper">
            <div className="add-items-button-wrapper">
              {showAddItems ? (
                <IonButton
                  fill="clear"
                  size="small"
                  color="secondary"
                  className="add-items-btn link underlined"
                  onClick={() => forwardTo('/order')}
                >
                  {__('Add Items')}
                </IonButton>
              ) : null}
            </div>

            {basketInstance.getItems().map((basketItem, basketItemIndex) => {
              const { quantity, item, instructions } = basketItem;
              let image =
                item.itemRichData && item.itemRichData.squareImageUrl
                  ? item.itemRichData.squareImageUrl
                  : '';
              if (image && image.indexOf('http://') !== -1) {
                image = image.replace(/http:\/\//g, 'https://');
              }
              return (
                <div className="box-content">
                  <div
                    className="order-item-image"
                    style={
                      image && image !== ''
                        ? { backgroundImage: `url(${image})` }
                        : {
                            backgroundSize: 'contain',
                            backgroundImage: `url(${this.props.clientProfile.logo_image})`,
                          }
                    }
                  />
                  <div className="item-content-wrapper flex-justify-between flex-row-wrapper">
                    <div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <NormalText className="block ">
                          {Basket.getProductName(item, profile)}
                        </NormalText>
                        <NormalText>
                          {basketInstance.calculateItemPriceByIndex(basketItemIndex, true) > 0
                            ? basketInstance._calculateItemPriceByIndex(basketItemIndex, true)
                            : null}
                        </NormalText>
                      </div>

                      {this.drawSubItemsText(basketItem, basketItemIndex)}
                      {basketItem.instructions && basketItem.instructions !== '' && (
                        <>
                          <Spacer size={1} />
                          <SmallText className="block">
                            {`${__('Instructions')}: ${basketItem.instructions}`}{' '}
                          </SmallText>
                        </>
                      )}
                    </div>
                    <div className="item-content-actions flex-justify-between flex-col-wrapper flex-align-end">
                      <Incrementer
                        onUpdate={(val) => {
                          if (handleOrderItemClick) {
                            handleOrderItemClick(basketItem, basketItemIndex, val);
                          }
                        }}
                        quantity={quantity}
                        step={1}
                        allowNegative={false}
                        enableLastItemAlert={true}
                      >
                        <StrongText className="bold primary-color">{quantity}</StrongText>
                      </Incrementer>
                      <IonButton
                        onClick={() => this.showModal(basketItem, basketItemIndex)}
                        fill="outline"
                        color="primary"
                      >
                        {__('Edit')}
                        <IonIcon
                          style={{ marginLeft: '10px', height: '30px', width: '30px' }}
                          icon={pencilOutline}
                        ></IonIcon>
                      </IonButton>
                      <IonButton
                        onClick={() => basketInstance.changeItemQuantity(basketItemIndex, 0)}
                        fill="outline"
                        color="danger"
                      >
                        {__('Remove')}
                        <IonIcon
                          style={{ marginLeft: '10px', height: '30px', width: '30px' }}
                          icon={trashBinOutline}
                        ></IonIcon>
                      </IonButton>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {showAddItems ? (
            <IonRow className="righted add-items-row">
              <IonButton
                size="small"
                color="black"
                className="rounded add-items-btn"
                onClick={() => forwardTo('/order')}
              >
                + {__('Add Items')}
              </IonButton>
            </IonRow>
          ) : null}
        </IonGrid>

        <Modal
          cssClass="service-charge-modal"
          isOpen={serviceChargeModalOpen}
          onDidDismiss={() => this.handleServiceChargeModal(false)}
        >
          <ServiceCharge
            handleServiceChargeModal={this.handleServiceChargeModal}
            serviceChargeModalOpen={serviceChargeModalOpen}
          />
        </Modal>
        <Modal
          className={`item-details-modal  ${modalOpen ? 'visible' : ''}`}
          isOpen={true}
          onDidDismiss={() => this.closeModal()}
          backdropDismiss={false}
        >
          {modalOpen && this.state.basketItem ? (
            <ItemDetailsKiosk
              basketItem={this.state.basketItem}
              profile={profile}
              modalOpen={modalOpen}
              closeModal={this.closeModal}
              edit={true}
              index={this.state.basketItemIndex}
            />
          ) : null}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    isRemoveVoucherModalOpen: store.profile.isRemoveVoucherModalOpen,
    clientProfile: store.common.clientProfile,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderContent)));
