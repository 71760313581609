import React, { Component } from 'react';
import { IonItem, IonInput } from '@ionic/react';
import './index.css';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { Spacer, Title } from '../common';
import { withTranslation } from '../../lib/translate';

class KioskCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
    };
  }

  onChange = (val) => {
    this.setState({ code: val }, () => {
      this.props.onCodeChange(val);
    });
  };
  getCode = () => {
    return this.state.code;
  };
  render() {
    const { code } = this.state;
    const { __ } = this.props;
    return (
      <>
        <div className="kiosk-code-wrapper">
          {code.split('').map((el) => (
            <Title>{el}</Title>
          ))}
          <div>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
        </div>
        <Spacer size={1} />
        <Keyboard
          layout={{
            default: ['1 2 3', '4 5 6', '7 8 9', ' 0 ', '{bksp}'],
          }}
          display={{
            '{bksp}': __('Delete'),
          }}
          maxLength={6}
          onChange={this.onChange}
        />
      </>
    );
  }
}

export default withTranslation(KioskCode);
