import React, { Component } from 'react';
import { IonButton } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import { CONTINUE_TIMER, SET_COMMON_PROP } from '../../store/constants';
import { forwardTo } from '../../lib/utils';

class TimerButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLeft: 10,
      interval: null,
    };
  }
  componentDidMount() {
    const { startValue } = this.props;
    let time = startValue ? startValue : 10;
    this.setState({
      interval: setInterval(() => {
        time = time - 1;
        if (time <= 0) {
          this.handleClick(true);
        }
        this.setState({ timeLeft: time });
      }, 1000),
    });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
    this.props.dispatch({ type: SET_COMMON_PROP, key: 'restartOrderAlert', value: false });
    this.restartOrderHandler(false);
  }

  handleClick = (restart) => {
    const { interval } = this.state;
    clearInterval(interval);
    this.restartOrderHandler(restart);
  };

  restartOrderHandler = (restart) => {
    if (restart) {
      this.props.dispatch({ type: SET_COMMON_PROP, key: 'restartOrderAlert', value: false });
      forwardTo(this.props.forwardTo);
    } else {
      this.props.dispatch({ type: CONTINUE_TIMER });
    }
  };

  render() {
    const { __, style, onClickNo, onClickYes, text, color } = this.props;
    const { timeLeft } = this.state;
    return (
      <div className="continue-order-buttons">
        <IonButton
          color="danger"
          onClick={() => (onClickNo ? onClickNo() : this.handleClick(true))}
          style={style ? { ...style } : {}}
        >
          {`${__(text ? text : 'No')} [ ${timeLeft} ]`}
        </IonButton>
        <IonButton onClick={() => (onClickYes ? onClickYes() : this.componentWillUnmount())}>
          {__('Yes')}
        </IonButton>
      </div>
    );
  }
}

export default withTranslation(TimerButtons);
