import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { connect } from 'react-redux';
import { arrowForward } from 'ionicons/icons';
import Layout from '../../components/layout';
import { forwardTo, getDefaultRoute } from '../../lib/utils';
import { loginRequest } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import Loading from '../../components/spinner';
import { Spacer, Title, Subtitle } from '../../components/common';
import './index.css';
import KioskCode from '../../components/kioskCode';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleInput(key, val) {
    this.setState({ [key]: val });
  }

  handleLogin() {
    const { code } = this.state;
    if (code.length === 6) {
      this.props.dispatch(
        loginRequest({
          code,
          referrer: '/loyalty',
          useCode: true,
        }),
      );
    }
  }

  onCodeChange = (code) => {
    this.setState({ code });
  };
  render() {
    const { __, clientProfile } = this.props;
    const { code } = this.state;
    return (
      <Loading>
        <Layout scrollY={false} headerTitle={__('Login')} color="transparent">
          <div className="absolute-content large-padding">
            <img
              className="client-modal-logo"
              src={clientProfile.logo_image}
              alt={clientProfile.buisiness_name}
            />

            <Spacer />
            <Title className="centered  large-title">{__('Log in')}</Title>
            <Spacer size={1} />
            <Subtitle className="block centered ">
              {__(
                'To login and earn loyalty, enter your one time code below. You’ll find it on the loyalty page of your app account',
              )}
            </Subtitle>
            <Spacer />
            <KioskCode code={code} onCodeChange={this.onCodeChange} numDigits={6}></KioskCode>

            <Spacer />

            <IonButton
              disabled={code.length < 6}
              onClick={() => this.handleLogin()}
              className="large-button"
            >
              {__('Login')}
              <IonIcon icon={arrowForward} />
            </IonButton>
            <Spacer size={1} />

            <IonButton
              onClick={() => forwardTo('/order')}
              expand="block"
              color="secondary"
              fill="clear"
              className="large-button"
            >
              {'Cancel'}
            </IonButton>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, protectedReferrer } = state.profile;
  const { storedDeliveryAddress, storedPickUpPoint } = state.orders;
  const { navConfig, clientProfile } = state.common;
  return {
    auth,
    protectedReferrer,
    storedDeliveryAddress,
    storedPickUpPoint,
    navConfig,
    clientProfile,
  };
};

export default connect(stateToProps)(withTranslation(Login));
