import React from 'react';
import { withRouter } from 'react-router';
import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { getConfig } from '../../appConfig';
import Header from '../../components/header';
import { getRouteClassName, isWebConfig } from '../../lib/utils';
import { connect } from 'react-redux';
import './index.css';
import StaticHeader from '../staticHeader';
import UserBar from '../UserBar';

const defaultHeaderTitle = getConfig().general.clientName;

const Layout = ({
  history,
  children,
  headerTitle,
  showSecondToolbar,
  hideBack,
  color,
  poster,
  blank,
  noPadding,
  contentClassName,
  scrollY,
  backHandler,
  selectCategoryOnScroll,
  navConfig,
  showHamburger,
  headerIconColors,
  withRefresher,
  onRefreshHandler,
  headerWithTitle,
  staticHeaderClassName,
  backButtonText,
  disableForwardTo,
  hasUserBar,
  showAllergensButton,
  showPayButton,
  onPayButtonSubmitHandler,
}) => {
  useIonViewWillEnter(() => {
    // eslint-disable-next-line no-console
    console.log('1. WillEnter event fired');
  });
  useIonViewDidEnter(() => {
    // eslint-disable-next-line no-console
    console.log('2. DidEnter event fired');
  });
  useIonViewWillLeave((a, b, c) => {
    // eslint-disable-next-line no-console
    console.log('3. WillLeave event fired', a, b, c);
  });
  useIonViewDidLeave((a, b, c) => {
    // eslint-disable-next-line no-console
    console.log('4. DidLeave event fired', a, b, c);
  });
  const routeClassName = getRouteClassName(history.location.pathname, navConfig);
  return (
    <>
      {blank ? null : (
        <>
          {!showSecondToolbar ? (
            <StaticHeader
              title={headerTitle || defaultHeaderTitle}
              hideBack={hideBack}
              backHandler={backHandler}
              showHamburger={showHamburger}
              headerIconColors={headerIconColors}
              headerWithTitle={headerWithTitle}
              className={staticHeaderClassName}
              backButtonText={backButtonText}
              disableForwardTo={disableForwardTo}
              hasUserBar={hasUserBar}
              showAllergensButton={showAllergensButton}
              showPayButton={showPayButton}
              onPayButtonSubmitHandler={onPayButtonSubmitHandler}
            />
          ) : (
            <>
              <StaticHeader
                title={headerTitle || defaultHeaderTitle}
                hideBack={hideBack}
                backHandler={backHandler}
                headerIconColors={headerIconColors}
                headerWithTitle={headerWithTitle}
                className={staticHeaderClassName}
                backButtonText={backButtonText}
                disableForwardTo={disableForwardTo}
                hasUserBar={hasUserBar}
                showAllergensButton={showAllergensButton}
                showPayButton={showPayButton}
                onPayButtonSubmitHandler={onPayButtonSubmitHandler}
              />
              <Header
                title={headerTitle || defaultHeaderTitle}
                hideBack={hideBack}
                backHandler={backHandler}
              />
            </>
          )}
        </>
      )}
      <IonContent
        scrollY={scrollY !== undefined ? scrollY : true}
        color={color || 'white'}
        className={routeClassName + (contentClassName ? ' ' + contentClassName : '')}
      >
        {withRefresher && !isWebConfig() && (
          <IonRefresher pullFactor="0.8" onIonRefresh={(e) => onRefreshHandler(e)} slot="fixed">
            <IonRefresherContent pullingIcon="chevron-down-circle-outline"></IonRefresherContent>
          </IonRefresher>
        )}
        {poster ? <div className={'poster ' + poster} /> : null}
        <div
          className={noPadding ? 'no-padding' : 'ion-padding'}
          onScroll={routeClassName === 'route-order' ? selectCategoryOnScroll : null}
        >
          {withRefresher && isWebConfig() && (
            <IonRefresher pullFactor="0.8" onIonRefresh={(e) => onRefreshHandler(e)} slot="fixed">
              <IonRefresherContent
                {...(!isWebConfig() ? { pullingIcon: 'chevron-down-circle-outline' } : {})}
              ></IonRefresherContent>
            </IonRefresher>
          )}
          {children}
        </div>
      </IonContent>
    </>
  );
};

function mapStateToProps(state) {
  return {
    navConfig: state.common.navConfig,
  };
}
export default connect(mapStateToProps)(withRouter(Layout));
