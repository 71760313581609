import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Stripe from '../../lib/stripe';
import { getConfig } from '../../appConfig';
import { PaymentRequestButtonElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { showToast, loading } from '../../store/actions';

import Basket from '../../lib/basket';
import { SEND_GIFT_VOUCHER } from '../../store/constants';
import api from '../../lib/api';

const { setSelectedCard } = Basket;

export const Result = ({ children }) => <div className="result">{children}</div>;

export const ErrorResult = ({ children }) => <div className="error">{children}</div>;

const NotAvailableResult = () => (
  <Result>
    <p style={{ textAlign: 'center' }}>{'Please add card to your browser.'}</p>
    {window.location.protocol !== 'https:' && (
      <p style={{ textAlign: 'center' }}>
        {'Try using'}{' '}
        <a href="https://ngrok.com" target="_blank" rel="noopener noreferrer">
          {' '}
          ngrok{' '}
        </a>{' '}
        {'to view this demo over https.'}
      </p>
    )}
  </Result>
);

const ELEMENT_OPTIONS = {
  style: {
    paymentRequestButton: {
      type: 'buy',
      theme: 'dark',
    },
  },
};

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canMakePayment: false,
      hasCheckedAvailability: false,
      errorMessage: null,
      // paymentMethod: null
    };
  }

  async componentDidUpdate() {
    const { stripe } = this.props;

    if (stripe && !this.paymentRequest) {
      // Create PaymentRequest after Stripe.js loads.
      this.createPaymentRequest(stripe);
    }
  }

  async createPaymentRequest(stripe) {
    const config = await api.getFrontEndAppConfig();
    const clientName = config?.front_end_app_config?.general?.clientName;

    const { dispatch, __, profile } = this.props;
    this.paymentRequest = stripe.paymentRequest({
      country: Basket.getCountry().toUpperCase(),
      currency: Basket.getSelectedCurrency(),
      total: Basket.getItemsForWebPay(clientName),
    });
    this.setState({ paymentWebType: this.paymentRequest._queryStrategy[0] });
    const that = this;
    this.paymentRequest.on('paymentmethod', function (ev) {
      if (that.clientSecret) {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        stripe
          .confirmCardPayment(
            that.clientSecret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: true },
          )
          .then(function (confirmResult) {
            dispatch(loading(false));
            if (confirmResult.error) {
              // Report to the browser that the payment failed, prompting it to
              // re-show the payment interface, or show an error message and close
              // the payment interface.
              ev.complete('fail');
              dispatch(showToast(__(Stripe.failed_message), 'danger'));
            } else {
              // Report to the browser that the confirmation was successful, prompting
              // it to close the browser payment method collection interface.
              ev.complete('success');
              dispatch(showToast(__(Stripe.success_message), 'success'));
              if (that.giftVoucherData) {
                dispatch({ type: SEND_GIFT_VOUCHER, data: that.giftVoucherData });
              }
              if (that.resetBasket) {
                that.resetBasket(that.giftVoucherData);
              }
              // Check if the PaymentIntent requires any actions and if so let Stripe.js
              // handle the flow. If using an API version older than "2019-02-11" instead
              // instead check for: `paymentIntent.status === "requires_source_action"`.
              if (confirmResult.paymentIntent.status === 'requires_action') {
                // Let Stripe.js handle the rest of the payment flow.
                stripe.confirmCardPayment(that.clientSecret).then(function (result) {
                  console.log(result);
                  if (result.error) {
                    // The payment failed -- ask your customer for a new payment method.
                  } else {
                    // The payment has succeeded.
                  }
                });
              } else {
                // The payment has succeeded.
              }
            }
          });
      } else {
        setSelectedCard(profile.cardToken);
        dispatch(loading(false));
      }
    });

    this.paymentRequest.on('cancel', function () {
      dispatch(loading(false));
      // handle cancel event
    });

    const canMakePaymentRes = await this.paymentRequest.canMakePayment();
    if (canMakePaymentRes) {
      this.setState({ canMakePayment: true, hasCheckedAvailability: true });
    } else {
      this.setState({ canMakePayment: false, hasCheckedAvailability: true });
    }
  }

  render() {
    const {
      canMakePayment,
      hasCheckedAvailability,
      errorMessage,
      paymentMethod,
      paymentWebType,
    } = this.state;
    const { __ } = this.props;
    return (
      <form>
        {canMakePayment && (
          <PaymentRequestButtonElement
            onClick={(event) => {
              this.props.dispatch(loading(true));
              Basket.createOrder(
                'webPayment',
                paymentWebType,
                (clientSecret, resetBasket, giftVoucherData) => {
                  this.setState({ clientSecret: clientSecret });
                  this.clientSecret = clientSecret;
                  this.resetBasket = resetBasket;
                  this.giftVoucherData = giftVoucherData;

                  // this.paymentRequest.show()
                },
              );
              this.setState({
                errorMessage:
                  'You can only use the PaymentRequest button once. Refresh the page to start over.',
              });
            }}
            options={{
              ...ELEMENT_OPTIONS,
              paymentRequest: this.paymentRequest,
            }}
          />
        )}
        {!canMakePayment && hasCheckedAvailability && <NotAvailableResult />}
        {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
        {paymentMethod && (
          <Result>
            {__('Got PaymentMethod')}: {paymentMethod.id}
          </Result>
        )}
      </form>
    );
  }
}

const InjectedCheckoutForm = ({ clientSecret, dispatch, __, location, profile }) => (
  <ElementsConsumer>
    {({ stripe }) => (
      <CheckoutForm
        stripe={stripe}
        clientSecret={clientSecret}
        dispatch={dispatch}
        __={__}
        location={location}
        profile={profile}
      />
    )}
  </ElementsConsumer>
);

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// console.log('Stripe.getStripeInstance()1', Stripe.getStripeInstance())
// const stripePromise = loadStripe('pk_test_SfLlfP41LhHVIG2vrA95ZrFt')

const CheckoutPay = ({ clientSecret, dispatch, __, location, profile }) => {
  const stripePromise = loadStripe(Stripe.getStripeInstance()._apiKey);
  return (
    <Elements stripe={stripePromise}>
      <InjectedCheckoutForm
        clientSecret={clientSecret}
        dispatch={dispatch}
        __={__}
        location={location}
        profile={profile}
      />
    </Elements>
  );
};

export default CheckoutPay;
