import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { ReactComponent as CompletedIcon } from '../../assets/images/completed.svg';

import {
  getProfile,
  getRewards,
  getTransactionHistory,
  getVouchers,
  sendVoucherCode,
} from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { SmallText, Spacer, StrongText, Subtitle, Title } from '../../components/common';
import { forwardTo, isDefined, isEmptyObject, validateForm } from '../../lib/utils';
import { IonButton, IonIcon } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import moment from 'moment';
import { getConfig } from '../../appConfig';
import api from '../../lib/api';
import { getFaq } from '../../store/actions';

const VoucherItem = ({ reward, id, className, __, action }) => {
  return (
    <div
      style={{ backgroundImage: `url(${reward.image})` }}
      className={'voucher' + (className ? ' ' + className : '')}
      onClick={action ? () => action(id) : null}
    ></div>
  );
};
export const getLabel = (item) => {
  let label = '';
  const defaultDescriptors = [
    {
      bl_id: '-1',
      label: 'Referral Bonus',
    },
    {
      bl_id: '-2',
      label: 'Sign up Bonus',
    },
    {
      bl_id: '-3',
      label: 'Refunded points',
    },
  ];
  const historyDescriptors = getConfig().history_descriptors || defaultDescriptors;

  const descriptors = historyDescriptors.filter((el) => el.bl_id == item.business_location_id);
  if (descriptors.length > 0) {
    label = descriptors[0].label;
  } else {
    let isPoints = item.is_points;
    let isStamps = item.is_stamps;
    let subLabel = '';
    if (item.stamp_power == 1) {
      subLabel = `${isPoints ? 'Point' : isStamps ? 'Stamp' : ''}`;
    } else if (item.stamp_power !== 1) {
      subLabel = `${isPoints ? 'Points' : isStamps ? 'Stamps' : ''}`;
    }
    if (item.stamp_power < 0) {
      label = `Redeemed ${subLabel}`;
    } else if (item.stamp_power > 0) {
      label = `Earned ${subLabel}`;
    }
  }
  return label;
};
class Loyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      loyaltyFaqModalOpened: false,
      voucherCode: '',
      selectedVoucher: null,
      formErrors: {
        voucherCode: '',
      },
      stampCards: [],
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  async componentDidMount() {
    this.props.dispatch(getRewards());
    this.props.dispatch(getTransactionHistory());
    const config = await api.getFrontEndAppConfig();

    const stampsPosition =
      config?.stamp_cards_json?.map((el, i) => Array(el.stamps_required).fill({})) || [];
    for (let i = 0; i < stampsPosition.length; i++) {
      for (let j = 0; j < stampsPosition[i].length; j++) {
        stampsPosition[i][j] = {
          top: this.getRandomNumber(-3, 3),
          left: this.getRandomNumber(-3, 3),
          rotate: this.getRandomNumber(-90, 90),
        };
      }
    }
    this.props.dispatch(getFaq());
    this.setState({ stampCards: config?.stamp_cards_json || [], stampsPosition });
  }

  viewVoucher = (id) => {
    const { vouchers } = this.props;
    this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) });
  };

  redeemVoucher = () => {
    const { voucherCode } = this.state;
    if (voucherCode !== '') {
      //console.log('Redeem voucher, code: "' + this.state.voucherCode + '"')
    }
  };
  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      //dispatch(redeemGiftVoucher(data));
      dispatch(sendVoucherCode(data));
    }
  };

  handlerOfVoucherCode = (e) => {
    const val = e.target.value.toUpperCase();
    this.setState({ voucherCode: val });
  };
  clearFields = () => {
    this.setState({ voucherCode: '' });
  };
  getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }
  getNumberOfRowsAndColumns(stampNumber) {
    let data = {};
    if (stampNumber % 5 !== 0) {
      data.columns = 'repeat(4,65px)';
      data.rows = '65px';
    } else {
      data.columns = 'repeat(5,50px)';
      data.rows = '50px';
    }
    return data;
  }
  onRefreshHandler = (e) => {
    setTimeout(() => {
      this.props.dispatch(getProfile());
      this.props.dispatch(getTransactionHistory());
      this.props.dispatch(getRewards());
      this.props.dispatch(getVouchers());

      e.target.complete();
    }, 2000);
  };
  render() {
    const {
      __,
      /*rewards,*/ profile,
      screenName,
      transactionHistory,
      faq,
      vouchers,
      qr_code,
    } = this.props;
    const { loyaltyFaqModalOpened, voucherCode, selectedVoucher } = this.state;
    //  let voucherRes = (vouchers || []).filter((data) => {
    //   if (data.type === 1) {
    //     return data;
    //   }
    // });
    let voucherRes = vouchers || [];
    return (
      <Loading transparent>
        <Layout
          withRefresher={true}
          onRefreshHandler={this.onRefreshHandler}
          color="transparent"
          headerWithTitle={true}
          headerTitle={__('Loyalty')}
        >
          <div className="absolute-content large-padding flex-row-wrapper">
            <div className="content-wrapper ">
              <div>
                <Title className=" large-title">
                  <CompletedIcon className="completed-icon" />
                  {`${__('Hi')} ${profile.first_name}!`}
                </Title>
                <Spacer size={1} />
                <Subtitle className="block centered ">
                  {__(
                    'You will now earn loyalty for this order, and can redeem your loyalty rewards at the checkout.',
                  )}
                </Subtitle>
                <Spacer size={2} />
                <Title>{__('Loyalty balance')}</Title>
                <div className="loyalty-balance-wrapper">
                  <Title className="large-title">
                    {' '}
                    {profile.available_balance.toLocaleString()} {__('pts')}{' '}
                  </Title>
                </div>
                {voucherRes.length > 0 && (
                  <>
                    <Spacer size={2} />
                    <Title>{__('Your vouchers')}</Title>
                  </>
                )}
              </div>
              {voucherRes.length > 0 && (
                <div className="loyalty-vouchers-wrapper ">
                  {voucherRes &&
                    voucherRes.map((voucher, index) => {
                      return (
                        <div className="voucher-wrapper box-content box-shadow">
                          <StrongText className="block primary-color">
                            {voucher.reward.name}
                          </StrongText>
                          <SmallText className="block primary-color">
                            {`${__('Expires:')} ${moment(
                              voucher.reward.expiry_date,
                              'YYYY/MM/DD',
                            ).format('DD/MM/YY')}`}
                          </SmallText>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
            <div className="flex-min">
              <Spacer size={1} />
              <IonButton
                className="large-button"
                color="primary"
                onClick={() => forwardTo('/order')}
              >
                {__('Continue')}
                <IonIcon icon={arrowForward} />
              </IonButton>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { rewards } = state.restaurants;
  const { history } = state.orders;

  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name} ${profile.last_name}`;
  }
  return {
    screenName,
    profile,
    rewards: rewards || [],
    transactionHistory: history || [],
    faq: state.common.faq || null,
    vouchers: state.profile.vouchers || [],
    qr_code: state.profile.profile.qr_code,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Loyalty)));
