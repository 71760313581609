import {
  LOGIN_REQUEST,
  LOGOUT,
  REGISTER_REQUEST,
  GET_PROFILE,
  SET_PROFILE_PROP,
  UPDATE_PROFILE,
  RESTORE_AUTH,
  SET_MODAL,
  RESET_PASSWORD,
  SET_PROTECTED_REFERRER,
  VALIDATE_EMAIL,
  CLEAR_REGISTER_FORM,
  SEND_REFER,
  SEND_VOUCHER_CODE,
  GET_VOUCHERS,
  CHECKOUT_DISCOUNT_VOUCHERS,
  REMOVE_VOUCHER,
  CLEAR_GUEST_FORM,
  SET_KIOSK_DATA,
  UPDATE_KIOSK_DATA,
} from './constants';
import {
  SET_ORDERS_PROP,
  GET_ORDER_HISTORY,
  SET_COMMON_MODAL,
  /*INIT_FIREBASE_DATABASE,*/ ADD_DELIVERY_ADDRESS,
  ADD_PICKUP_POINT,
  SHOW_TOAST,
  SET_COMMON_PROP,
} from '../constants';
import { loading } from '../common/sagas';
import { take, call, put, select, cancel, spawn } from 'redux-saga/effects';
import api from '../../lib/api';
import {
  forwardTo,
  getDefaultRoute,
  isDefined,
  validateProfileData,
  isEmptyObject,
  removeNullFromObject,
} from '../../lib/utils';
import asyncStorage from '../../lib/asyncStorage';
import { saveFcmToken, translateSaga, firebaseDatabase } from '../common/sagas';
import { getPaymentCards, showToast, storeDeliveryAddress, storePickUpPoint } from '../actions';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import delay from '@redux-saga/delay-p';
import { errorHandlerSaga } from '../sagas';
import { applayLocale } from '../../lib/moment';
import moment from '../../lib/moment';
import { setKioskData } from './actions';

// const authRoutes = {
//   protectedLanding: getDefaultRoute(true)?.path,
//   unprotectedLanding: getDefaultRoute()?.path,
// };

/**
 * Effect to handle authorization
 * @param { string } username The username of the user
 * @param { string } password The password of the user
 * @param { object } options Options
 * @param { boolean } options.isRegistering Is this a register request?
 */
export const authorize = function* (data) {
  const authStore = yield select((state) => state.profile.auth);
  if (authStore.loggedIn) {
    return authStore;
  }
  let returnValue = null;
  if (data.isRegistering) {
    data.isRegistering = undefined;
    const registerData = yield call(api.register, data);
    returnValue = registerData;
  } else {
    data.isRegistering = undefined;
    const { username, password, code, useCode } = data;
    let loginData = null;
    if (useCode) {
      loginData = yield call(api.loginWithCode, { authType: 'kiosk', code });
    } else {
      loginData = yield call(api.login, username, password);
    }
    returnValue = loginData;
  }

  return returnValue;
};

/* Login saga */
export const loginFlow = function* () {
  while (true) {
    const { username, password, code, useCode, referrer, cb } = yield take(LOGIN_REQUEST);
    const store = yield select();
    const type = Basket.getOrderType();

    yield call(loading, function* () {
      const response = yield call(authorize, {
        username,
        password,
        isRegistering: false,
        code,
        useCode,
      });
      if (!response.loggedIn) {
        const { token, profile } = response.data;

        yield call(asyncStorage.setItem, 'token', token);
        yield call(asyncStorage.setItem, 'profile', JSON.stringify(profile));

        yield put({ type: SET_PROFILE_PROP, key: 'auth', value: { loggedIn: true, token } });
        yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile });
        yield put({ type: GET_VOUCHERS });

        yield call(saveFcmToken);
        Basket.setLang(profile);
        if (store.orders.storedDeliveryAddress) {
          let found = !!profile.address_list.find(
            (al) =>
              al.addressLine1 + al.place + al.postalCode ===
              store.orders.storedDeliveryAddress.addressLine1 +
                store.orders.storedDeliveryAddress.place +
                store.orders.storedDeliveryAddress.postalCode,
          );
          if (type === 'Delivery' && store.orders.storedDeliveryAddress && !found) {
            yield put({
              type: ADD_DELIVERY_ADDRESS,
              deliveryAddress: store.orders.storedDeliveryAddress,
              flag: true,
            });
            yield put(storeDeliveryAddress(null));
          }
        }
        if (store.orders.storedPickUpPoint) {
          let found = !!profile.pickup_points_list.find(
            (pp) => pp.code === store.orders.storedPickUpPoint.pickUpPoint,
          );
          if (type === 'Outpost Drop-Off' && store.orders.deliveryAddress && !found) {
            yield put({
              type: ADD_PICKUP_POINT,
              pickUpPoint: store.orders.storedPickUpPoint,
              flag: true,
            });
            yield put(storePickUpPoint(null));
          }
        }
        const { navConfig } = yield select((state) => state.common);
        yield put({ type: CLEAR_GUEST_FORM });
        forwardTo(referrer || getDefaultRoute(navConfig).path);
      }
    });
    if (cb) yield call(cb);
    //yield call(postLogingFlow);
  }
};

export const restoreAuthSaga = function* () {
  const token = yield call(asyncStorage.getItem, 'token');
  const profile = yield call(asyncStorage.getItem, 'profile');

  if (isDefined(token) && isDefined(profile)) {
    yield put({ type: SET_PROFILE_PROP, key: 'auth', value: { loggedIn: true, token } });
    yield put({ type: SET_PROFILE_PROP, key: 'profile', value: JSON.parse(profile) });
    yield call(api.createAxiosInstance, token);
    yield call(saveFcmToken);
    yield call(postLogingFlow);
    Basket.setLang(JSON.parse(profile));
  } else {
    yield call(api.createAxiosInstance);
  }
  return;
};

export const restoreAuthFlow = function* () {
  while (true) {
    yield take(RESTORE_AUTH);
    yield call(restoreAuthSaga);
  }
};

const periodicalSaga = function* () {
  while (true) {
    try {
      if (isDefined(Basket.collection_time)) {
        const collection_time = moment.unix(Basket.collection_time);
        const store = yield select();
        const hasBaksetResetModalOpen = store.common.hasBaksetResetModalOpen;
        const current_time = moment();
        if (isDefined(getConfig().general.basketTime)) {
          const c_t = collection_time.add(getConfig().general.basketTime, 'minutes');
          const cutoff_time = moment.unix(Basket.cutoffTime);
          if (
            (Basket.order_type === 'pick-up-point' ||
              Basket.order_type === 'charter-delivery' ||
              Basket.order_type === 'delivery') &&
            cutoff_time.isBefore(current_time)
          ) {
            yield put({ type: SET_COMMON_MODAL, modal: 'removeBasketItemsModalOpen', value: true });
          } else if (c_t.isBefore(current_time)) {
            if (!hasBaksetResetModalOpen) {
              const nextAvailableTime = Basket.next_collection_time.filter((availableSlotTime) =>
                moment
                  .unix(availableSlotTime)
                  .add(getConfig().general.basketTime, 'minutes')
                  .isAfter(current_time),
              );
              if (nextAvailableTime.length > 0) {
                Basket.setCollectionTime(nextAvailableTime[0]);
              } else {
                yield put({ type: SET_COMMON_MODAL, modal: 'isBasketResetModalOpen', value: true });
              }
            }
          }
        }
        if (moment(collection_time).isAfter(current_time)) {
          Basket._isCollectionTimeStillValid(true);
        }
      }
      yield delay(getConfig().general.periodForSaga * 1000);
    } catch (e) {
      yield call(errorHandlerSaga, e);
    }
  }
};

export const startPeriodicalSaga = function* () {
  // periodicalSaga is now at the same level as root saga
  const timer = yield spawn(periodicalSaga);
  const store = yield select();
  if (isDefined(store.profile.timer)) {
    yield cancel(store.profile.timer);
  }
  yield put({ type: SET_PROFILE_PROP, key: 'timer', value: timer });
};

const stopPeriodicalSaga = function* () {
  const store = yield select();
  if (isDefined(store.profile.timer)) {
    yield cancel(store.profile.timer);
  }
  yield put({ type: SET_PROFILE_PROP, key: 'timer', value: null });
};

const postLogingFlow = function* () {
  if (getConfig().appType.hasOrdering) {
    yield put(getPaymentCards());
    yield put({ type: GET_ORDER_HISTORY, loading: false });
  }
  yield put({ type: GET_VOUCHERS });

  yield call(startPeriodicalSaga);
  yield call(showValidationPopUp);
  yield call(applayLocale);

  try {
    yield spawn(firebaseDatabase);
  } catch (e) {
    yield call(errorHandlerSaga, e);
  }
};

/* Register saga */
export const registerFlow = function* () {
  //const store = yield select()
  while (true) {
    const action = yield take(REGISTER_REQUEST);
    yield call(loading, function* () {
      const profile = yield select((state) => state.profile);
      const { registerFormData, sysLocale, protectedReferrer } = profile;
      const myLocation = yield select((state) => state.common.myLocation);
      const locale = getConfig().localization.defaultLocale;
      const data = {
        ...registerFormData,
        sysLocale,
        myLocation:
          myLocation.latitude !== null && myLocation.longitude !== null ? myLocation : undefined,
        locale,
      };
      yield call(authorize, { ...data, isRegistering: true });
      // yield put({ type: CLEAR_REGISTER_FORM });
      //auto login after register
      let referrer = yield select((state) => state.profile.referrer);
      if (!referrer) referrer = action.referrer;
      const { email, password } = data;
      yield put({
        type: LOGIN_REQUEST,
        username: email,
        password: password,
        referrer,
        cb: action.cb,
        useCode: false,
      });
    });
  }
};

/* Log out saga */
export const logoutFlow = function* () {
  while (true) {
    const action = yield take(LOGOUT);
    yield call(asyncStorage.removeItem, 'token');
    yield call(asyncStorage.removeItem, 'profile');
    yield put({ type: SET_PROFILE_PROP, key: 'auth', value: {} });
    yield put({ type: SET_PROFILE_PROP, key: 'profile', value: {} });
    yield put({ type: SET_ORDERS_PROP, key: 'cards', value: [] });
    yield put({ type: SET_PROTECTED_REFERRER, path: null });
    Basket.reset();
    //stop periodical profile fetching
    yield call(stopPeriodicalSaga);
    const { navConfig } = yield select((state) => state.common);
    forwardTo(getDefaultRoute(navConfig).path);

    if (isDefined(action.cb) && action.cb) {
      yield call(action.cb);
    }
  }
};

/* get Profile saga */
export const getProfileFlow = function* () {
  while (true) {
    const action = yield take(GET_PROFILE);
    const saga = function* () {
      const response = yield call(api.getProfile);
      const profile = response.data.profile;
      yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile });
      yield call(asyncStorage.setItem, 'profile', JSON.stringify(profile));
      yield call(applayLocale);
    };
    if (action.skipLoading) {
      yield call(saga);
    } else {
      yield call(saga);
    }
  }
};

/* update Profile saga */
export const updateProfileFlow = function* () {
  while (true) {
    const action = yield take(UPDATE_PROFILE);
    yield call(loading, function* () {
      let response = yield call(api.updateProfile, action.data);
      if (response.data.error) {
        yield put({ type: SHOW_TOAST, message: Object.values(response.data.error.errors)[0] });
      } else {
        const profile = response.data.profile;
        yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile, merge: true });
        yield call(asyncStorage.setItem, 'profile', JSON.stringify(profile));
        if (!isDefined(action.skipAlert) || !action.skipAlert) {
          yield put({ type: SET_MODAL, modal: 'isProfileModalOpen', value: true });
        }
      }
    });
  }
};

/* reset Password saga */
export const resetPasswordFlow = function* () {
  while (true) {
    const action = yield take(RESET_PASSWORD);
    yield call(api.resetPassword, action.email);
    yield put({ type: SET_MODAL, modal: 'isResetPasswordModalOpen', value: true });
  }
};

/* validte Email saga */
export const validateEmailFlow = function* () {
  while (true) {
    const action = yield take(VALIDATE_EMAIL);
    const validate_profile = action.validate_profile;
    yield put({ type: UPDATE_PROFILE, validate_profile, skipAlert: true });
    const result = yield call(api.validateEmail);
    yield put(showToast(yield call(translateSaga, result.data.data.success), 'success'));
  }
};

/* show validtion PopUp Flow saga */
export const showValidationPopUp = function* () {
  const profile = yield select((state) => state.profile.profile);
  const valid = validateProfileData(profile).isValid;
  if (
    getConfig().appType.hasEmailValidationEnabled &&
    valid &&
    isDefined(profile.is_verification_pop_up_shown) &&
    !profile.is_verification_pop_up_shown
  ) {
    yield put({ type: SET_COMMON_MODAL, modal: 'isValidationModalOpen', value: true });
  }
};

export const sendReferFlow = function* () {
  while (true) {
    const action = yield take(SEND_REFER);
    yield call(loading, function* () {
      const result = yield call(api.sendRefer, action.data);
      if (!result.error) {
        yield put(showToast(yield call(translateSaga, result.message), 'success'));
      }
    });
  }
};

export const sendVoucherCodeFlow = function* () {
  while (true) {
    const action = yield take(SEND_VOUCHER_CODE);
    yield call(loading, function* () {
      const result = yield call(api.sendCode, action.data);
      if (!result.is_gift) {
        yield put({ type: GET_VOUCHERS });
        yield put({ type: CHECKOUT_DISCOUNT_VOUCHERS, data: Basket.parseBasketForCheckVouchers() });

        if (!result.error && !result.Error) {
          yield put(showToast(yield call(translateSaga, result.message), 'success'));
        }
      } else {
        yield put({
          type: SET_COMMON_PROP,
          key: 'redeemGiftVoucherMessage',
          value: result.message,
        });
        yield put({
          type: SET_COMMON_MODAL,
          modal: 'isRedeemGiftVoucherModalOpen',
          value: true,
        });
      }
    });
  }
};

export const getVouchersFlow = function* () {
  while (true) {
    yield take(GET_VOUCHERS);
    if (getConfig().flags.hasCampaignManager) {
      const response = yield call(api.getVouchers);
      if (!response.error) {
        yield put({ type: SET_PROFILE_PROP, key: 'vouchers', value: response.data });
      }
    }
  }
};
export const checkDiscountFlow = function* () {
  while (true) {
    const action = yield take(CHECKOUT_DISCOUNT_VOUCHERS);
    if (getConfig().flags.hasCampaignManager) {
      yield call(loading, function* () {
        const response = yield call(api.checkDiscount, action.data);
        if (!response.error) {
          yield put({
            type: SET_PROFILE_PROP,
            key: 'applicableVouchers',
            value: response.data.data,
          });
        }
      });
    }
  }
};
export const removeVoucherFlow = function* () {
  while (true) {
    yield take(REMOVE_VOUCHER);
    if (getConfig().flags.hasCampaignManager) {
      // eslint-disable-next-line require-yield
      yield call(loading, function* () {
        Basket.clearAllDiscounts(false, true);
      });
    }
  }
};
export const updateKioskDataFlow = function* () {
  while (true) {
    const action = yield take(UPDATE_KIOSK_DATA);
    let kioskData = removeNullFromObject(action.value) || {};
    if (kioskData?.kiosk_restaurant_id) {
      kioskData.kiosk_restaurant_id = parseInt(kioskData.kiosk_restaurant_id);
    }
    let savedKioskData = yield call(asyncStorage.getItem, 'kioskData');
    savedKioskData = JSON.parse(savedKioskData);
    if (!isEmptyObject(kioskData) && savedKioskData && savedKioskData?.pos_location) {
      kioskData.pos_location = savedKioskData?.pos_location;
    }
    if (!isEmptyObject(kioskData)) {
      const newKioskData = { ...savedKioskData, ...kioskData };
      yield call(asyncStorage.setItem, 'kioskData', JSON.stringify(newKioskData));
      yield put({ type: SET_KIOSK_DATA, value: kioskData });
    } else {
      yield put({ type: SET_KIOSK_DATA, value: savedKioskData });
      document.documentElement.style.setProperty('--zoom', `${savedKioskData?.kiosk_zoom || 100}%`);
      document.documentElement.style.setProperty(
        '--zoom-value',
        `${savedKioskData?.kiosk_zoom / 100 || 1}`,
      );
    }
  }
};
