import {
  SET_PROFILE_PROP,
  SET_MODAL,
  SET_PROTECTED_REFERRER,
  SET_SYS_LOCALE,
  SET_REGISTER_FORM,
  CLEAR_REGISTER_FORM,
  REGISTER_REQUEST,
  SET_GUEST_USER,
  CLEAR_GUEST_FORM,
  CLEAR_KIOSK_FORM,
  SET_KIOSK_USER,
  SET_KIOSK_DATA,
} from './constants';
import { isDefined } from '../../lib/utils';

let initialState = {
  auth: {},
  profile: {},
  protectedReferrer: null,
  isProfileModalOpen: false,
  isRegisterModalOpen: false,
  isResetPasswordModalOpen: false,
  isVerfiedModalOpen: false,
  sysLocale: '',
  registerFormData: {
    first_name: '',
    last_name: '',
    mobile: '',
    email: '',
    password: '',
    accepted_terms_and_conditions: false,
    is_subscribed: false,
    guest: false,
  },
  guestUserData: {
    guest_first_name: '',
    guest_email: '',
    guest_accepted_terms_and_conditions: false,
    guest_mobile: '',
  },
  kioskData: {
    kiosk_auth_email: '',
    kiosk_password: '',
    kiosk_restaurant_id: '',
  },
  isValidetePopUpDisplayed: false,
  isRemoveVoucherModalOpen: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE_PROP:
      return {
        ...state,
        [action.key]:
          isDefined(action.merge) && action.merge
            ? { ...state[action.key], ...action.value }
            : action.value,
      };
    case SET_MODAL:
      return { ...state, [action.modal]: action.value };
    case SET_PROTECTED_REFERRER:
      return { ...state, protectedReferrer: action.path };
    case SET_SYS_LOCALE:
      return { ...state, sysLocale: action.sysLocale };
    case SET_REGISTER_FORM:
      return {
        ...state,
        registerFormData: { ...state.registerFormData, [action.key]: action.value },
      };
    case CLEAR_REGISTER_FORM:
      return {
        ...state,
        registerFormData: {
          first_name: '',
          last_name: '',
          mobile: '',
          email: '',
          password: '',
          accepted_terms_and_conditions: false,
          is_subscribed: false,
        },
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        referrer: action.data ? action.data.referrer : null,
      };
    case CLEAR_GUEST_FORM:
      return {
        ...state,
        guestUserData: {
          guest_first_name: '',
          guest_email: '',
          guest_accepted_terms_and_conditions: false,
          guest_mobile: '',
        },
      };
    case SET_GUEST_USER:
      return {
        ...state,
        guestUserData: { ...state.guestUserData, ...action.value },
      };
    case CLEAR_KIOSK_FORM:
      return {
        ...state,
        kioskData: {
          kiosk_auth_email: '',
          kiosk_password: '',
        },
      };
    case SET_KIOSK_DATA:
      return {
        ...state,
        kioskData: { ...state.kioskData, ...action.value },
      };
    default:
      return state;
  }
}

export default reducer;
