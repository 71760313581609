import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IonApp, IonSplitPane, IonPage, IonAlert, IonContent, IonButton } from '@ionic/react';
import { Router } from 'react-router-dom';
import { Plugins, Capacitor } from '@capacitor/core';
import { getConfig } from './appConfig';
// import navConfig from './navConfig';
import ProtectedRoute from './components/protectedRoute';
import history from './history';
import { forwardTo, getDefaultRoute, isWebConfig } from './lib/utils';
import { withTranslation } from '../src/lib/translate';
import {
  init,
  changeConnectionStatus,
  setMyLocation,
  setCommonModal,
  updateProfile,
} from './store/actions';
import ValidateModal from './components/validateModal';
import Drawer from './components/drawer';
import Toast from './components/toast';
import Loading from './components/spinner';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import Basket from './lib/basket';
import Alert from './components/alert';
import { RESTART_TIMER, SET_COMMON_PROP } from './store/constants';
import Modal from './components/modal';
import { NormalText, Spacer, Title } from './components/common';
import TimerButtons from './components/timerButtons';
import { PillGroup } from './components/pill';
/* Theme */
// require('./theme/' + (isPlatform('mobile') ? 'index' : 'web') + '.css')
require('./theme/' + (isWebConfig() ? 'web' : 'index') + '.css');
const { Network, Geolocation } = Plugins;

Plugins.App.addListener('backButton', () => {
  if (
    history.location &&
    history.location.pathname &&
    getConfig().general.appExitRoutes.indexOf(history.location.pathname) !== -1
  ) {
    Plugins.App.exitApp();
  } else {
    history.goBack();
  }
});

const mapRoutes = (routes, extraProps = {}) => {
  return routes
    .filter((route) => !!route.path && !!route.component)
    .map((item) => {
      const { path } = item;
      const ActualRoute = item.protected ? ProtectedRoute : Route;
      return (
        <ActualRoute
          exact={item.exact}
          path={path}
          key={'nav-key-' + path}
          route={item}
          cmp={item.component}
          render={(props) =>
            item.render ? (
              item.render({ ...props, ...extraProps, route: item })
            ) : (
              <item.component {...props} {...extraProps} route={item} />
            )
          }
        />
      );
    });
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.defaultRoute = [];
    this.routeComponents = [];
    this.authRouteComponents = [];
    this.additionalRouteComponents = [];
    this.notInMenuRouteComponents = [];

    this.content = null;
  }

  componentDidMount() {
    this.props.dispatch(init());

    Network.addListener('networkStatusChange', (conStatus) => {
      const status = conStatus.connected;
      this.props.dispatch(changeConnectionStatus(status));
    });
  }

  async getCurrentPosition() {
    const { myLocation, dispatch } = this.props;
    if (!myLocation.latitude && !myLocation.longitude) {
      // this.showModal(true)
      try {
        const coordinates = await Geolocation.getCurrentPosition({
          enableHighAccuracy: false,
        });
        const myLocation = {
          latitude: coordinates.coords.latitude,
          longitude: coordinates.coords.longitude,
        };
        dispatch(setMyLocation(myLocation));
      } catch (error) {
        if (
          JSON.stringify(error).indexOf('kCLErrorDomain') !== -1 &&
          JSON.stringify(error).indexOf('error 0') !== -1
        ) {
          this.getCurrentPosition();
        }
      }
    }
  }
  onRedeemGiftVoucherModalClose = () => {
    const { dispatch } = this.props;
    dispatch(setCommonModal('isRedeemGiftVoucherModalOpen', false));
  };
  onRemoveValidateModal = () => {
    const { dispatch } = this.props;
    dispatch(setCommonModal('isValidationModalOpen', false));
    dispatch(updateProfile({ is_verification_pop_up_shown: true }, true));
  };

  onRemoveBasketResetModal = () => {
    const { dispatch } = this.props;
    dispatch(setCommonModal('isBasketResetModalOpen', false));
    dispatch(setCommonModal('hasBaksetResetModalOpen', true));
  };

  generateRoutes = () => {
    this.defaultRoute = getDefaultRoute(this.props.navConfig);
    this.routeComponents = mapRoutes(this.props.navConfig.routes);
    this.authRouteComponents = mapRoutes(this.props.navConfig.authRoutes);
    this.additionalRouteComponents = mapRoutes(this.props.navConfig.additionalRoutes);
    this.notInMenuRouteComponents = mapRoutes(this.props.navConfig.notInMenuRoutes);
  };

  clearBasket = () => {
    const { dispatch } = this.props;
    Basket.reset();
    dispatch(setCommonModal('isBasketResetWarningModalOpen', false));
  };

  clearPickUpBasket = () => {
    const { dispatch } = this.props;
    Basket.reset();
    dispatch(setCommonModal('removeBasketItemsModalOpen', false));
    forwardTo('/delivery-options');
  };
  closeAlert = () => {
    const { dispatch } = this.props;
    forwardTo('/order');
    dispatch(setCommonModal('isBasketResetWarningModalOpen', false));
  };
  clickHandler = () => {
    this.props.dispatch({ type: RESTART_TIMER });
  };
  render() {
    const {
      __,
      isValidationModalOpen,
      initLoading,
      isBasketResetModalOpen,
      isBasketResetWarningModalOpen,
      removeBasketItemsModalOpen,
      redeemGiftVoucherMessage,
      isRedeemGiftVoucherModalOpen,
      restartOrderAlert,
      allergenModalOpen,
    } = this.props;
    const { kiosk } = getConfig();
    const restartOrderTimer = kiosk ? kiosk?.restartOrderTimer : false;
    const kioskIsActive = kiosk ? kiosk.active : false;
    if (Capacitor.platform === 'web') {
      // wait for init saga to finish (reason: we dont have splash screen in web)
      if (!initLoading) {
        this.generateRoutes();
        this.content = null;
      } else {
        this.content = (
          <IonPage id="main">
            <IonContent>
              <Loading additionalLoadingCondition />
            </IonContent>
          </IonPage>
        );
      }
    } else {
      // mobile
      this.generateRoutes();
    }

    return (
      <IonApp
        className={`${isWebConfig() ? 'web' : ''} ${kioskIsActive ? 'kiosk' : ''}`}
        onclick={restartOrderTimer && this.clickHandler}
      >
        <Router history={history}>
          {this.content ? (
            this.content
          ) : (
            <IonSplitPane
              {...(isWebConfig() ? { when: '(min-width:768px)' } : { when: '(min-width:2000px)' })}
              contentId="main"
            >
              {
                //<Drawer />
              }
              <IonPage id="main">
                <Switch>
                  {this.routeComponents}
                  {this.authRouteComponents}
                  {this.additionalRouteComponents}
                  {this.notInMenuRouteComponents}
                  {this.defaultRoute ? (
                    <Route exact path="/" render={() => <Redirect to={this.defaultRoute.path} />} />
                  ) : null}
                </Switch>
                <Toast />
                <ValidateModal />
                <IonAlert
                  isOpen={isRedeemGiftVoucherModalOpen}
                  onDidDismiss={this.onRedeemGiftVoucherModalClose}
                  header={__('Success')}
                  message={__(redeemGiftVoucherMessage)}
                  buttons={[
                    {
                      text: __('Close'),
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: this.onRedeemGiftVoucherModalClose,
                    },
                  ]}
                />
                <IonAlert
                  isOpen={isValidationModalOpen}
                  onDidDismiss={this.onRemoveValidateModal}
                  header={__('Success')}
                  message={__('The app is now unlocked to redeem your loyalty')}
                  buttons={[
                    {
                      text: __('Close'),
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: this.onRemoveValidateModal,
                    },
                  ]}
                />
                <Modal
                  className="continue-order-modal"
                  isOpen={restartOrderAlert}
                  onDidDismiss={() =>
                    this.props.dispatch({
                      type: SET_COMMON_PROP,
                      key: 'restartOrderAlert',
                      value: false,
                    })
                  }
                >
                  <Title>{__('Would you like to continue your order') + '?'}</Title>
                  <TimerButtons forwardTo={this.defaultRoute.path} />
                </Modal>
                <IonAlert
                  isOpen={isBasketResetModalOpen}
                  onDidDismiss={this.onRemoveBasketResetModal}
                  header={__('Basket')}
                  message={__('You must checkout in the next 5 minutes to place your order.')}
                  buttons={[
                    {
                      text: __('OK'),
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: () => this.onRemoveBasketResetModal,
                    },
                  ]}
                />
                <Modal
                  cssClass="allergen-modal small-modal"
                  isOpen={allergenModalOpen}
                  onDidDismiss={() =>
                    this.props.dispatch({
                      type: SET_COMMON_PROP,
                      key: 'allergenModalOpen',
                      value: false,
                    })
                  }
                >
                  <div className="absolute-content flex-row-wrapper">
                    <div className="scrollable-y rhino">
                      <Title className="centered primary-color large-title">
                        {__('Allergens')}
                      </Title>
                      <Spacer />
                      {Basket.getItems().map((item, index) => {
                        let data = (Basket.getAllergen() || []).find(
                          (allergen) => allergen[1].sku === item.item.sku,
                        );
                        if (data && data.length > 0) {
                          return (
                            <div key={'allergen-' + index}>
                              <NormalText
                                className="block
                      "
                              >
                                {item.quantity}x {item.item.productName}
                              </NormalText>
                              <PillGroup items={data[0].allergens} borderColor="primary" />
                              <Spacer size={1} />
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                    <div className="flex-min centered">
                      <NormalText>
                        {__(
                          'If you have any allergies, please advise our staff to confirm the suitability of your order.',
                        )}
                      </NormalText>
                      <Spacer />
                      <IonButton
                        style={{ width: '30%' }}
                        className="large-button uppercase"
                        color="primary"
                        onClick={() =>
                          this.props.dispatch({
                            type: SET_COMMON_PROP,
                            key: 'allergenModalOpen',
                            value: false,
                          })
                        }
                      >
                        {__('OK')}
                      </IonButton>
                    </div>
                  </div>
                </Modal>
                <IonAlert
                  isOpen={removeBasketItemsModalOpen}
                  onDidDismiss={this.clearPickUpBasket}
                  header={__('Basket')}
                  message={__(
                    'Your collection time has now passed, please select diferent collection time and place your order.',
                  )}
                  buttons={[
                    {
                      text: __('OK'),
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: () => this.clearPickUpBasket,
                    },
                  ]}
                />
                <Alert
                  showAlert={isBasketResetWarningModalOpen}
                  closeAlert={this.closeAlert}
                  type="select"
                  clearBasket={this.clearBasket}
                />
              </IonPage>
            </IonSplitPane>
          )}
        </Router>
      </IonApp>
    );
  }
}

const stateToProps = (store) => {
  const { auth, profile } = store.profile;
  const {
    myLocation,
    initLoading,
    navConfig,
    isValidationModalOpen,
    isBasketResetModalOpen,
    hasBaksetResetModalOpen,
    removeBasketItemsModalOpen,
    isBasketResetWarningModalOpen,
    redeemGiftVoucherMessage,
    isRedeemGiftVoucherModalOpen,
    restartOrderAlert,
    allergenModalOpen,
  } = store.common;
  return {
    auth,
    myLocation,
    profile,
    initLoading,
    navConfig,
    isValidationModalOpen,
    isBasketResetModalOpen,
    removeBasketItemsModalOpen,
    hasBaksetResetModalOpen,
    isBasketResetWarningModalOpen,
    redeemGiftVoucherMessage,
    isRedeemGiftVoucherModalOpen,
    restartOrderAlert,
    allergenModalOpen,
  };
};

export default connect(stateToProps)(withTranslation(App));
