import React from 'react';
import { withRouter } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonButtons,
  IonButton,
  IonBadge,
  IonIcon,
} from '@ionic/react';
import { arrowForward, basket, scan } from 'ionicons/icons';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import Icon from '../icon';
import { Title } from '../common';
import BackButton from '../backButton';
import {
  checkBackgroundColor,
  forwardTo,
  getRouteClassName,
  isWebConfig,
  getDefaultRoute,
} from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import './index.css';
import UserBar from '../UserBar';
import { SET_COMMON_PROP } from '../../store/constants';

const StaticHeader = ({
  __,
  history,
  backHandler,
  clientProfile,
  navConfig,
  showHamburger,
  headerIconColors,
  clientStyles,
  className,
  disableForwardTo,
  backButtonText,
  title,
  headerWithTitle,
  hasUserBar,
  showAllergensButton,
  showPayButton,
  onPayButtonSubmitHandler,
  dispatch,
}) => {
  // const logo_image = clientProfile.logo_image
  //style={{ '--background': isWebConfig() ? '' : `#FFF url(${logo_image}) no-repeat 50% 50%/auto 90%` }
  const currentPath = history.location.pathname;
  const kiosk = getConfig()?.kiosk ? getConfig()?.kiosk.active : false;
  const authPages = getConfig().general.authRoutes.indexOf(currentPath) !== -1;
  const hideStaticHeader = getConfig().theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1;
  const pagesWithoutBackButton =
    getConfig().general.routesWithoutBackButton.indexOf(currentPath) !== -1;

  const routeClassName = getRouteClassName(currentPath, navConfig);
  const logoMain = clientProfile.logo_image || '';
  const defaultIconsColor = clientStyles?.colors?.['--okx-background-color']
    ? checkBackgroundColor(clientStyles?.colors['--okx-background-color'])
    : 'dark';
  const defaultRoute = getDefaultRoute(navConfig).path;
  return (
    <>
      {hideStaticHeader ? null : getConfig().theme.showHeaderOnAuthRoutes || !authPages ? (
        <IonHeader
          className={`static ${routeClassName} 
            ${getConfig().general.dashboardWithTiles ? 'colored-header' : ''} 
            ${headerWithTitle ? 'no-logo' : ''}
            ${className} ${getConfig().kiosk.active ? 'kiosk-mode' : ''}`}
        >
          {hasUserBar && getConfig().kiosk.hasLoyalty && <UserBar />}

          <IonToolbar
            className="primary-toolbar"
            style={{
              backgroundImage: !isWebConfig() && !headerWithTitle ? `url(${logoMain})` : '',
            }}
          >
            <IonButtons slot="start">
              {pagesWithoutBackButton || showHamburger ? (
                <IonMenuButton color={headerIconColors ? headerIconColors : defaultIconsColor} />
              ) : (
                <>
                  <BackButton
                    __={__}
                    backHandler={backHandler}
                    iconColor={headerIconColors ? headerIconColors : defaultIconsColor}
                    text={backButtonText}
                    className={'header-back-button'}
                  />
                </>
              )}
            </IonButtons>
            <IonButtons color="primary" className="header-center">
              {!headerWithTitle ? (
                <IonButton
                  className="image-button"
                  onClick={() => {
                    if (!disableForwardTo) forwardTo(defaultRoute);
                  }}
                />
              ) : (
                <IonButton
                  color="transparent"
                  className="header-title"
                  onClick={() => {
                    if (!disableForwardTo) forwardTo(defaultRoute);
                  }}
                >
                  <Title>{__(title)}</Title>
                </IonButton>
              )}
            </IonButtons>

            <IonButtons slot="end">
              {showAllergensButton ? (
                <IonButton
                  disabled={Basket.getAllergen().length == 0}
                  onClick={() =>
                    dispatch({ type: SET_COMMON_PROP, key: 'allergenModalOpen', value: true })
                  }
                  class="header-allergens-button"
                >
                  {__('Allergens')}
                </IonButton>
              ) : null}
              {showPayButton ? (
                <IonButton
                  disabled={!Basket.isMinimumOrderTotalSatisfied()}
                  onClick={() => onPayButtonSubmitHandler()}
                  class="header-pay-button"
                >
                  {`${__('Pay')} ${Basket._getTotal(true)}`}
                  <IonIcon icon={arrowForward} />
                </IonButton>
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { orders } = store;
  const { clientProfile, navConfig, clientStyles } = store.common;
  return {
    basketUpdated: orders.basketUpdated,
    clientProfile,
    navConfig,
    clientStyles,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)));
