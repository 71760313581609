import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonCheckbox,
  IonTextarea,
  IonItem,
  IonList,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonButton,
  IonIcon,
  IonAlert,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import Layout from '../../../components/layout';
import Incrementer from '../../../components/incrementer';
import { PillGroup } from '../../../components/pill';
import {
  SmallText,
  Spacer,
  NormalText,
  StrongText,
  Title,
  Subtitle,
} from '../../../components/common';
import { withTranslation } from '../../../lib/translate';
import Basket from '../../../lib/basket';
import {
  makeKey,
  isDefined,
  deepCopy,
  goBack,
  isWebConfig,
  isEmptyObject,
  forwardTo,
  parseAllergenData,
} from '../../../lib/utils';
import { getConfig } from '../../../appConfig';
import { storeItemWeb } from '../../../store/actions';
import Modal from '../../../components/modal';
import './index.css';

const {
  formatPrice,
  _calculateItemPrice,
  validateItem,
  addToBasket,
  getProductName,
  getProductDescription,
  getModifierGroupName,
  isProductEnabled,
  isProductJustEnabled,
  isChoicesGroupValid,
  setAllergen,
  removeFromBasket,
} = Basket;

const noImage = () => <div className="item-details-background" id="item-details-image"></div>;

class itemDetailsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      price: 0,
      selectedChoices: [],
      validationErrors: [],
      instructions: '',
      showValidationAlert: false,
      allergensCodes: [],
      instructions: '',
      modalInstructions: '',
      specialInstructionsModalOpen: false,
    };
  }

  componentDidMount() {
    const { profile, allergens, basketItem } = this.props;
    let quantity = this.state.quantity;
    let item = this.props.item;
    let selectedChoices = this.state.selectedChoices;
    let validationErrors = [];

    if (basketItem) {
      quantity = basketItem.quantity;
      item = basketItem.item;
      selectedChoices = basketItem.selectedChoices;
    }
    if (item.menuDealGroups && item.menuDealGroups.length > 0 && !basketItem) {
      selectedChoices = Array(item.menuDealGroups.length).fill([]);
      validationErrors = Array(item.menuDealGroups.length).fill(null);
    }
    let price = _calculateItemPrice({ item, quantity, selectedChoices });
    let newArr = parseAllergenData(profile, item, allergens);
    this.setState(
      { price, quantity, selectedChoices, validationErrors, allergensCodes: newArr },
      () => {
        // run initial validation (this should be commented if initial validation isnt necessary)
        const initialValidationErrors = validateItem(this.constructBasketItem());
        this.setState({ validationErrors: initialValidationErrors.errors });
      },
    );
  }
  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantity: newQuantity,
      price: _calculateItemPrice({
        item: this.props.item,
        quantity: newQuantity,
        selectedChoices: this.state.selectedChoices,
      }),
    });
  };

  handleInputChange = (groupIndex, choiceIndex, multiSelectionPermitted, event) => {
    const item = this.props.item;
    const { quantity, selectedChoices } = this.state;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[groupIndex];

    if (selectedGroup) {
      const selectedChoice = selectedGroup.items[choiceIndex];
      let updatedSelectedChoices = deepCopy(selectedChoices);
      if (multiSelectionPermitted) {
        //checkbox
        if (event.target.checked) {
          updatedSelectedChoices[groupIndex].push(selectedChoice);
        } else {
          updatedSelectedChoices[groupIndex] = updatedSelectedChoices[groupIndex].filter(
            (i) => i.sku !== selectedChoice.sku,
          );
        }
      } else {
        //radio
        if (!selectedChoice) {
          updatedSelectedChoices[groupIndex] = [];
        } else {
          updatedSelectedChoices[groupIndex] = [selectedChoice];
        }
      }

      this.setState({ selectedChoices: updatedSelectedChoices }, () => {
        const { selectedChoices } = this.state;
        //recalculate item price on every menu deal choice change
        this.setState({ price: _calculateItemPrice({ item, quantity, selectedChoices }) }, () => {
          const validationErrors = validateItem(this.constructBasketItem());
          this.setState({ validationErrors: validationErrors.errors });
        });
      });
    }
  };

  drawGroupChoices = (menuGroupItem, multiSelectionPermitted, groupIndex) => {
    const { items, max, multiMax } = menuGroupItem;
    const choices = items || [];
    const { selectedChoices } = this.state;
    const { profile, __ } = this.props;
    const allChoices = choices.map((item, choiceIndex) => {
      const { sku, productPrice } = item;
      const isChecked = !!(selectedChoices[groupIndex] || []).find((i) => {
        return i.sku === sku;
      });
      if (
        item.sku === undefined ||
        (getConfig().flags.showSnoozedProducts
          ? !isProductJustEnabled(item)
          : !isProductEnabled(item))
      ) {
        return <span key={item.sku + '_disabled'} />;
      }
      const groupQuantity =
        selectedChoices[groupIndex] && selectedChoices[groupIndex].length > 0
          ? selectedChoices[groupIndex].reduce((sum, i) => sum + i.quantity || 0, 0)
          : 0;
      let quantity = 0;
      if (selectedChoices[groupIndex]) {
        const _choice = selectedChoices[groupIndex].find((i) => i.sku === sku);
        quantity = _choice ? _choice.quantity : 0;
      }
      let groupQunatity = 0;
      (selectedChoices[groupIndex] || []).forEach((i) => {
        groupQunatity += isDefined(i.quantity) ? i.quantity : 1;
      });
      const shouldBeDisabled =
        isDefined(max) &&
        max > 0 &&
        isDefined(selectedChoices[groupIndex]) &&
        groupQunatity >= max &&
        !isChecked;
      if (menuGroupItem.hasOwnProperty('multiMax')) {
        return (
          <div
            className={`sub-item ${!Basket.isProductUnsnoozed(item) ? 'disabled-element' : ''} `}
            key={makeKey(choiceIndex, sku, groupIndex)}
          >
            <IonItem tabIndex="-1" lines="none">
              {Basket.isProductUnsnoozed(item) ? (
                <>
                  {multiSelectionPermitted ? (
                    <IonCheckbox
                      disabled={shouldBeDisabled}
                      className="details"
                      color="primary"
                      slot="start"
                      checked={isChecked}
                      onIonChange={(event) => {
                        this.handleInputChange(
                          groupIndex,
                          choiceIndex,
                          multiSelectionPermitted,
                          event,
                        );
                      }}
                    />
                  ) : (
                    <IonRadio
                      slot="start"
                      className={'details' + (isChecked ? ' primary-radio' : '')}
                      color="primary"
                      value={choiceIndex}
                      checked={isChecked}
                    />
                  )}
                </>
              ) : (
                <div className="checkbox-placeholder"></div>
              )}
              <div className="ion-text-wrap up">
                <NormalText className="single-item item-details-product-label">
                  {__(getProductName(item, profile))}
                </NormalText>
                {isDefined(item.itemRichData) &&
                isDefined(item.itemRichData.allergenCodes) &&
                item.itemRichData.allergenCodes.length > 0 ? (
                  <>
                    <PillGroup items={item.itemRichData.allergenCodes} borderColor="primary" />
                  </>
                ) : null}
              </div>
            </IonItem>
            <div className="price-increment-content">
              {formatPrice(productPrice) ? <p>{formatPrice(productPrice)}</p> : null}
              {quantity >= 1 && max > 1 && multiMax > 1 ? (
                <div
                  className={formatPrice(productPrice) ? 'incrementer-box' : 'incrementer-box-top'}
                >
                  <Incrementer
                    allowNegative={false}
                    data={{ groupIndex, choiceIndex, multiSelectionPermitted }}
                    disabled={shouldBeDisabled}
                    maxLimit={Math.min(multiMax, (quantity || 0) + max - groupQuantity)}
                    onUpdate={this.onIncrementerChoiceUpdate}
                    quantity={quantity || 0}
                  />
                </div>
              ) : null}
            </div>
          </div>
        );
      } else {
        return (
          <div
            className={`sub-item ${!Basket.isProductUnsnoozed(item) ? 'disabled-element' : ''} `}
          >
            <IonItem lines="none" key={makeKey(choiceIndex, sku, groupIndex)}>
              <div tabIndex="-1"></div>
              {Basket.isProductUnsnoozed(item) ? (
                <>
                  {multiSelectionPermitted ? (
                    <IonCheckbox
                      color="primary"
                      slot="start"
                      checked={isChecked}
                      onIonChange={(event) => {
                        this.handleInputChange(
                          groupIndex,
                          choiceIndex,
                          multiSelectionPermitted,
                          event,
                        );
                      }}
                    />
                  ) : (
                    <IonRadio
                      slot="start"
                      className="details-radio"
                      color={isWebConfig() ? 'primary' : 'white'}
                      value={choiceIndex}
                      checked={isChecked}
                    />
                  )}
                </>
              ) : (
                <div className="checkbox-placeholder"></div>
              )}
              <div className="ion-text-wrap up">
                <NormalText className="single-item item-details-product-label primary-color">
                  {__(getProductName(item, profile))}
                </NormalText>
                {isDefined(item.itemRichData) &&
                isDefined(item.itemRichData.allergenCodes) &&
                item.itemRichData.allergenCodes.length > 0 ? (
                  <>
                    <PillGroup items={item.itemRichData.allergenCodes} borderColor="primary" />
                  </>
                ) : null}
              </div>
            </IonItem>
            <div className="price-increment-content">
              <NormalText>
                {formatPrice(item.productPrice) ? formatPrice(item.productPrice) : null}
              </NormalText>
            </div>
          </div>
        );
      }
    });
    if (multiSelectionPermitted) {
      return <div className="item-details-group-wrapper">{allChoices}</div>;
    } else {
      //radio
      return (
        <div className="item-details-group-wrapper">
          <IonRadioGroup
            allowEmptySelection={true}
            value={
              selectedChoices &&
              selectedChoices[groupIndex] &&
              selectedChoices[groupIndex].length > 0
                ? choices.findIndex((i) => i.sku === selectedChoices[groupIndex][0].sku)
                : -1
            }
            onIonChange={(event) => {
              this.handleInputChange(
                groupIndex,
                event.target.value,
                multiSelectionPermitted,
                event,
              );
            }}
          >
            {allChoices}
          </IonRadioGroup>
        </div>
      );
    }
  };

  drawGroupLabel = (menuGroupItem, groupIndex) => {
    const { validationErrors } = this.state;
    const { __ } = this.props;
    const { description, min, max } = menuGroupItem;
    const itemDontHaveMinMax = (!isDefined(min) && !isDefined(max)) || (min === 0 && max === 0);
    // let minMaxlabel = (isDefined(min) ? __('min') + ':' + min : '') + (isDefined(max) ? ', ' + __('max') + ':' + max : '')
    let minMaxlabel = null;

    if (isDefined(min) && isDefined(max)) {
      if ((min === 0 && max === 0) || (min === 0 && max === -1)) {
        minMaxlabel = null;
      }
      if (min === 0 && max > 0) {
        minMaxlabel = __('Select up to') + ' ' + max + ' ' + __('items (optional)');
      }
      if (min > 0 && min === max) {
        minMaxlabel = __('Select') + ' ' + min + ' ' + (min === 1 ? __('option') : __('options'));
      }
      if ((min > 0 && max === 0) || max === -1) {
        minMaxlabel =
          __('Select at least') + ' ' + min + ' ' + (min === 1 ? __('option') : __('options'));
      }
      if (min > 0 && max > 0 && min !== max) {
        minMaxlabel =
          __('Select between') + ' ' + min + ' ' + __('and') + ' ' + max + ' ' + __('options');
      }
    } else {
      minMaxlabel = null;
    }
    return (
      <>
        <IonItem lines="none">
          <div tabIndex="-1" className="sectiontitle">
            <Subtitle className="primary-color bold">{description}</Subtitle>
            {itemDontHaveMinMax ? null : isDefined(minMaxlabel) && minMaxlabel !== '' ? (
              <>
                <br />
                <NormalText className={validationErrors[groupIndex] ? 'field-error' : ''}>
                  {minMaxlabel}
                </NormalText>
              </>
            ) : null}
          </div>
        </IonItem>
        {itemDontHaveMinMax && validationErrors[groupIndex] ? (
          <div className="field-error">{__(validationErrors[groupIndex])}</div>
        ) : null}
      </>
    );
  };

  drawMenuDealGroups = (menuGroupItem, index) => {
    // multiSelectionPermitted = true  --> only one item must be selected
    const multiSelectionPermitted = menuGroupItem.multiSelectionPermitted;
    if (isChoicesGroupValid(menuGroupItem)) {
      if (isDefined(multiSelectionPermitted)) {
        return (
          <div className={this.state.validationErrors[index] ? 'group-required' : ''} key={index}>
            {this.drawGroupLabel(menuGroupItem, index)}
            {this.drawGroupChoices(menuGroupItem, multiSelectionPermitted, index)}
          </div>
        );
      } else {
        return this.drawGroupLabel(menuGroupItem);
      }
    }
  };

  constructBasketItem = () => {
    const { item } = this.props;
    const { quantity, selectedChoices, instructions } = this.state;

    return {
      item,
      quantity,
      selectedChoices,
      instructions,
    };
  };

  addToOrder = () => {
    const newBasketItem = this.constructBasketItem();
    const validationErrors = validateItem(newBasketItem);
    const { item, edit, index, defaultMenuId, ikentooMenu } = this.props;
    const kiosk = getConfig()?.kiosk ? getConfig()?.kiosk.active : false;
    const menu = isEmptyObject(ikentooMenu) ? defaultMenuId : ikentooMenu;
    if (validationErrors.errorCount > 0) {
      this.setState({ validationErrors: validationErrors.errors }, () => {
        this.setShowValidationAlert(true);
      });
    } else {
      if (
        Basket.items.length === 0 &&
        isEmptyObject(menu) &&
        isEmptyObject(Basket.getDeliveryOption())
      ) {
        this.props.dispatch(storeItemWeb(newBasketItem));
        kiosk ? forwardTo('/delivery-options-kiosk') : forwardTo('/delivery-options');
      } else {
        if (edit) {
          removeFromBasket(index);
        }
        addToBasket(newBasketItem);
        if (isWebConfig() || kiosk) {
          this.props.closeModal();
        } else {
          goBack();
        }
      }
    }

    let allergensCodes =
      item &&
      item.itemRichData &&
      item.itemRichData.allergenCodes &&
      item.itemRichData.allergenCodes.length > 0
        ? item.itemRichData.allergenCodes
        : [];
    if (allergensCodes.length > 0) {
      let allergensData = [{ allergens: this.state.allergensCodes }, { sku: item.sku }];
      if (isDefined(newBasketItem.selectedChoices)) {
        newBasketItem.selectedChoices.forEach((group) => {
          group.forEach((item) => {
            if (
              isDefined(item.itemRichData) &&
              isDefined(item.itemRichData.allergenCodes) &&
              item.itemRichData.allergenCodes.length > 0
            ) {
              setAllergen([{ allergens: item.itemRichData.allergenCodes }, { sku: item.sku }]);
            }
          });
        });
      }
      setAllergen(allergensData);
    }
  };

  instructionsChange = (event) => this.setState({ modalInstructions: event.target.value });

  setShowValidationAlert = (flag) => this.setState({ showValidationAlert: flag });

  calcPriceOfItem = (itemPrice, quantity) => {
    let price = itemPrice;
    if (+itemPrice > 0) {
      price = itemPrice * quantity;
    } else {
      price = 0;
    }

    return price.toFixed(2);
  };
  scrollToRequiredField = () => {
    document.querySelectorAll('.field-error').forEach((el) => el.classList.add('visible'));
    const requiredGroups = document.querySelectorAll('.group-required');
    document.querySelector('.item-details-main-wrapper').scrollTo({
      top: requiredGroups[0].offsetTop + 400,
      left: 0,
      behavior: 'smooth',
    });
  };
  render() {
    const { __, profile, item, edit } = this.props;
    const { quantity, showValidationAlert, allergensCodes } = this.state;
    const menuDealGroups = item?.menuDealGroups ? item.menuDealGroups : [];
    const isAddToOrderBtnValid = validateItem(this.constructBasketItem()).errorCount === 0;
    const price = this.calcPriceOfItem(item.productPrice, quantity);
    const specialInstructionsConf = getConfig().kiosk.specialInstructions;
    return (
      <>
        <div className="item-details-card-content">
          <IonList className="item-details-card-list">
            {getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, '') && (
              <IonItem lines="none">
                <div tabIndex="-1"></div>
                <NormalText
                  color="primary"
                  className="item-details-card-description"
                  dangerouslySetInnerHTML={{
                    __html: getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, ''),
                  }}
                ></NormalText>
              </IonItem>
            )}
            <div className="item-details-card-price okx-font-secondary">
              {formatPrice(item.productPrice)}
            </div>
            {allergensCodes.length > 0 ? (
              <>
                <Spacer size="1" />
                <Subtitle className="block">{__('Allergens')}</Subtitle>
                <IonItem lines="none">
                  <div tabIndex="-1"></div>
                  <PillGroup items={allergensCodes} borderColor="primary" paddingTop={4} />
                </IonItem>
              </>
            ) : null}

            {menuDealGroups.map(this.drawMenuDealGroups)}

            {specialInstructionsConf && specialInstructionsConf.isDisplayed ? (
              <>
                {this.state.instructions == '' ? (
                  <IonButton
                    onClick={() => {
                      this.setState({ specialInstructionsModalOpen: true });
                    }}
                    fill="clear"
                    expand="block"
                    color="primary"
                    className="no-borders add-instructions-button capitalized underlined  transparent"
                  >
                    {__('Add special instructions')}
                  </IonButton>
                ) : (
                  <>
                    <div className="special-instructions-title">
                      <IonItem lines="none">
                        <div tabIndex="-1"></div>
                        <StrongText>{__('Special Instructions')}</StrongText>
                      </IonItem>
                      <IonButton
                        color="primary"
                        fill="clear"
                        className="capitalized underlined transparent no-borders"
                        onClick={() => {
                          this.setState({ specialInstructionsModalOpen: true });
                        }}
                      >
                        {__('Edit')}
                      </IonButton>
                    </div>
                    <div
                      className="special-instructions-wrapper box-wrapper"
                      onClick={() => {
                        this.setState({ specialInstructionsModalOpen: true });
                      }}
                    >
                      <NormalText>{this.state.instructions}</NormalText>
                    </div>
                  </>
                )}
                <Modal
                  onDidDismiss={() => this.setState({ specialInstructionsModalOpen: false })}
                  className="special-instructions-modal"
                  isOpen={this.state.specialInstructionsModalOpen}
                >
                  <Title className="centered">{__('Special Instructions')}</Title>
                  <Spacer size={1} />
                  <IonItem lines="none" className="input-field-wrapper">
                    <IonTextarea
                      onIonChange={this.instructionsChange}
                      rows={5}
                      placeholder={__(
                        specialInstructionsConf && specialInstructionsConf.placeholder,
                      )}
                      value={this.state.modalInstructions}
                    ></IonTextarea>
                  </IonItem>
                  <Spacer size={1} />
                  <IonButton
                    color="primary"
                    expand="block"
                    className="uppercase"
                    onClick={() => {
                      this.setState({
                        instructions: this.state.modalInstructions,
                        specialInstructionsModalOpen: false,
                      });
                    }}
                  >
                    {' '}
                    {__('Continue')}
                  </IonButton>
                </Modal>
              </>
            ) : null}
          </IonList>
        </div>
        <div className="item-details-actions">
          <Incrementer
            color="primary"
            allowNegative={false}
            quantity={quantity}
            onUpdate={this.onIncrementerUpdate}
          />
          <IonButton
            disabled={quantity === 0}
            className={'item-details-add-to-order ' + (isAddToOrderBtnValid ? '' : 'disabled')}
            size="full"
            shape="round"
            color="primary"
            onClick={!isAddToOrderBtnValid ? this.scrollToRequiredField : this.addToOrder}
          >
            {edit ? __('Edit') : __('Add to Basket')}
            {+price === 0 ? '' : ` - ${Basket.getCurrency().label}${price}`}
          </IonButton>
        </div>
        <IonAlert
          isOpen={showValidationAlert}
          onDidDismiss={() => this.setShowValidationAlert(false)}
          header={__('Validation')}
          message={__('Please check any required options')}
          buttons={[{ text: __('OK'), role: 'cancel', cssClass: 'secondary' }]}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    restaurantsUpdated: store.restaurants.restaurantsUpdated,
    basketUpdated: store.orders.basketUpdated,
    allergens: store.restaurants.allergens,
    storedItemWeb: store.orders.storedItemWeb,
    ikentooMenu: store.restaurants.ikentooMenu || {},
    defaultMenuId: store.common.defaultMenuId,
  };
};

export const ItemDetailsKioskRaw = connect(mapStateToProps)(withTranslation(itemDetailsContent));

export class ItemDetailsKiosk extends Component {
  constructor(props) {
    super(props);
    this.x = React.createRef();
    this.state = {
      nameVisible: true,
    };
    this.timeout = null;
  }
  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  onContentScrollHandler() {
    if (!this.isScrolledIntoView(this.x.current)) {
      this.setState({ nameVisible: false });
    } else {
      this.setState({ nameVisible: true });
    }
  }
  isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    return isVisible;
  }

  render() {
    const { __, location, basketItem, profile, modalOpen, closeModal, edit, index } = this.props;
    const item = basketItem?.item ? basketItem.item : location.state;
    const { selectedMenu } = item;
    const kiosk = getConfig()?.kiosk ? getConfig().kiosk.active : false;
    let image =
      item && item.itemRichData && item.itemRichData.squareImageUrl
        ? item.itemRichData.squareImageUrl
        : '';
    if (image && image.indexOf('http://') !== -1) {
      image = image.replace(/http:\/\//g, 'https://');
    }
    return kiosk ? (
      <>
        {this.state.showItemDetailsSkeleton ? (
          <div className="item-details-skeleton">
            <div className="item-details-skeleton-image"></div>
            <p></p>
            <div className="item-details-skeleton-content">
              <p></p>
              <div></div>
              <p></p>
              <div></div>
            </div>
          </div>
        ) : (
          <div
            className="scrollable-y  item-details-main-wrapper"
            onScroll={() => {
              this.onContentScrollHandler();
            }}
          >
            <div
              className={
                this.state.nameVisible ? ' item-details-header' : 'active item-details-header'
              }
            >
              <div
                className={`header-product-name ${
                  this.state.nameVisible ? 'product-name-is-visible' : ''
                }`}
              >
                <Title className="product-name-title">
                  <strong>{__(getProductName(item, profile))}</strong>
                </Title>
              </div>
            </div>
            <div className="item-detals-background">
              <div className="item-details-info">
                {image && image !== '' ? (
                  <div className="item-details-img">
                    <img src={image} />
                  </div>
                ) : (
                  <div className="image-placeholder"></div>
                )}
                <div className="item-details-name" ref={this.x}>
                  <Title className="primary-color large-title">
                    {__(getProductName(item, profile))}
                  </Title>
                </div>
              </div>

              <ItemDetailsKioskRaw
                item={item}
                profile={profile}
                modalOpen={modalOpen}
                closeModal={closeModal}
                basketItem={basketItem}
                edit={edit}
                index={index}
              />
            </div>
          </div>
        )}
      </>
    ) : (
      <Layout
        headerTitle={__('Item Details')}
        // scrollY={false}
        noPadding
        contentClassName="item-details-wrapper"
        hideSecondToolbar={true}
      >
        <div className="item-details-main-wrapper">
          <div
            className={
              this.state.nameVisible ? ' item-details-header' : 'active item-details-header'
            }
          >
            <div className="item-details-back">
              <IonButton
                className="solo-button"
                color="white"
                onClick={() => forwardTo('/order', { selectedMenu })}
              >
                <IonIcon slot="icon-only" icon={arrowBack} />
              </IonButton>
            </div>
            <div
              className={`header-product-name ${
                this.state.nameVisible ? 'product-name-is-visible' : ''
              }`}
            >
              <NormalText className="product-name-title primary-color">
                {__(getProductName(item, profile))}
              </NormalText>
            </div>
          </div>
          <div
            className=" item-detals-background"
            onScroll={() => {
              this.onContentScrollHandler();
            }}
          >
            <div className="item-details-info">
              {image && image !== '' ? (
                <div className="item-details-img">
                  <img src={image} />
                </div>
              ) : (
                <div className="image-placeholder"></div>
              )}
              <div className="item-details-name" ref={this.x}>
                <Title className="primary-color">{__(getProductName(item, profile))}</Title>
              </div>
            </div>
            <ItemDetailsKioskRaw item={item} profile={profile} />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (store) => {
  return {
    profile: store.profile.profile,
  };
};

export default connect(stateToProps)(withTranslation(ItemDetailsKiosk));
