import React from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonButton,
} from '@ionic/react';
import { withTranslation } from '../../lib/translate';
// import moment from '../../lib/moment'
import { withRouter } from 'react-router';
import { SmallText, StrongText } from '../../components/common';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import orderIcon from '../../assets/images/order.svg';
import loyaltyIcon from '../../assets/images/loyalty.svg';
import { forwardTo, isDefined } from '../../lib/utils';

const doRefresh = (event, getTransactionHistory) => {
  setTimeout(() => {
    event.detail.complete();
    getTransactionHistory();
  }, 1000);
};

const HistoryTab = withRouter(
  ({ __, transactionHistory, getTransactionHistory, history, ...rest }) => {
    const type = rest.type || 'order';

    const handleRowClick = (item) => {
      if (type === 'order') {
        history.push('./history-details', { order: item });
      } else {
        // history.push('./history-details')
      }
    };
    const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];
    const orderAgain = (past_orders, option) => {
      Basket.reset();
      Basket.setDeliveryOption(option);
      forwardTo(option.route, { selectedRestaurant: past_orders });
    };
    return (
      <>
        <IonRefresher slot="fixed" onIonRefresh={(e) => doRefresh(e, getTransactionHistory)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className={`history-content ${type !== '' ? `${type}-tab` : ''}`}>
          {(transactionHistory || []).length === 0 ? (
            type === 'order' ? (
              <div className="no-data-history">
                <img alt=" " src={orderIcon} />
                <span>{__('Your order history will appear here')}</span>
              </div>
            ) : (
              <div className="no-data-history">
                <img alt=" " src={loyaltyIcon} />
                <span>{__('Your loyalty history will appear here')}</span>
              </div>
            )
          ) : (
            <IonGrid>
              {transactionHistory.map((i, index) => {
                const { stamp_power, transaction_date, label, item, location_name } = i;
                const withoutZ = transaction_date.replace('Z', '');
                const transDate = Basket.getDate(withoutZ);
                let orderLabel = Basket.getOrderType(i.item);
                let option = (delivery || []).find((d) => d.id === orderLabel);
                let orderId = i && i.item ? ' #' + i.item.id : '';
                let status = item && item.status ? item.status : '';
                let locationName =
                  location_name && location_name !== ''
                    ? location_name
                    : i && i.item
                    ? i.item.restaurant_name
                    : '';
                return status === 'CREATED' ||
                  status === 'created' ||
                  status === 'new' ||
                  status === 'NEW' ? null : (
                  <IonRow key={index} className="history-item">
                    <IonCol size="8" onClick={() => handleRowClick(item)}>
                      {type === 'order' && (
                        <div className="order-button-wrapper">
                          <div className="order-button bordered uppercase">
                            {__(item && item.status ? item.status : '')}
                          </div>
                        </div>
                      )}
                      {!item?.is_gift ? (
                        <>
                          <SmallText className=" break-spaces">
                            {item?.is_asap
                              ? 'ASAP'
                              : __(transDate.format('ddd')) +
                                transDate.format(' DD ') +
                                __(transDate.format('MMM')) +
                                transDate.format(' YYYY ') +
                                __('at') +
                                transDate.format(' h:mm a')}
                          </SmallText>
                          <StrongText className="break-spaces uppercase block">
                            {type === 'order'
                              ? option
                                ? __(option.label) + orderId
                                : ''
                              : __(label)}
                          </StrongText>
                          <SmallText>{locationName}</SmallText>
                        </>
                      ) : (
                        <StrongText className="break-spaces uppercase block">
                          {__('eGift Voucher')} {orderId}
                        </StrongText>
                      )}
                    </IonCol>
                    {type === 'order' ? (
                      !item?.is_gift && (
                        <IonCol
                          size="4"
                          style={{ alignSelf: 'center', textAlign: 'end' }}
                          className="order-button-col-width"
                        >
                          <IonButton onClick={() => orderAgain(item, option)}>
                            {__('Order Again')}
                          </IonButton>
                        </IonCol>
                      )
                    ) : (
                      <IonCol size="4" style={{ alignSelf: 'center' }}>
                        <p className={stamp_power < 0 ? 'redeem' : ''}>
                          <StrongText>
                            {stamp_power < 0 ? '' : '+'}
                            {stamp_power} {__('points')}
                          </StrongText>
                        </p>
                      </IonCol>
                    )}
                  </IonRow>
                );
              })}
            </IonGrid>
          )}
        </div>
      </>
    );
  },
);

export default withTranslation(HistoryTab);
