/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { IonButton, IonItem, IonInput, IonIcon } from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
// import { close } from 'ionicons/icons'
import { withTranslation } from '../../lib/translate';
import { deepIsDefined, forwardTo, goBack, validateForm } from '../../lib/utils';
import { sendVoucherCode, checkDiscount, showToast } from '../../store/actions';
import Layout from '../../components/layout';
import {
  Title,
  Spacer,
  FieldError /*, NormalText, SmallText*/,
  NormalText,
  Subtitle,
  StrongText,
  SmallText,
} from '../../components/common';
// import plateImage from '../../assets/images/icons/eat.png'

import Basket from '../../lib/basket';
import Modal from '../../components/modal';
import { getConfig } from '../../appConfig';
import './index.css';
import { arrowForward } from 'ionicons/icons';
import moment from 'moment';

class ApplyVouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discounts: null,
      selectedVoucher: null,
      showModal: null,
      voucherCode: '',
      disabledAppliedButton: false,
      formErrors: {
        voucherCode: '',
      },
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(checkDiscount(Basket.parseBasketForCheckVouchers()));
  }

  setVoucher = (voucher) => {
    this.setState({ selectedVoucher: voucher }, () => {
      const { vouchers, applicableVouchers } = this.props;
      const { selectedVoucher } = this.state;
      const appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
      const applicableVoucher = applicableVouchers.find(
        (appliableVoucher) => appliableVoucher.id === selectedVoucher.id,
      );
      if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
        this.setState({ disabledAppliedButton: true });
        Basket.applyPoints(0);
      } else {
        this.setState({ disabledAppliedButton: false });
      }
    });
  };

  addVoucher = () => {
    const { selectedVoucher } = this.state;
    const { vouchers, applicableVouchers, dispatch, __ } = this.props;
    const appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
    const applicableVoucher = applicableVouchers.find(
      (appliableVoucher) => appliableVoucher.id === selectedVoucher.id,
    );
    if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
      this.setState({ selectedVoucher: null }, () => {
        Basket.addVoucher(appliedVoucher, applicableVoucher);
        goBack();
      });
    } else {
      dispatch(
        showToast(
          __('Your basket is higher then voucher cost. Please add more items in basket'),
          'warning',
        ),
      );
    }
  };

  showModal = (show) => {
    this.setState({ showModal: show });
    this.clearFields();
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data));
      this.showModal(false);
    }
  };

  filterVouchers = (vouchers, appliedVouchers) => {
    const { applicableVouchers, availableBalance } = this.props;
    let filteredVouchers = [];
    if (applicableVouchers && applicableVouchers.length > 0) {
      applicableVouchers.forEach((discount) => {
        let findedVoucher = vouchers.find((voucher) => voucher.id === discount.id);
        if (getConfig().vouchersType === 2) {
          if (findedVoucher && availableBalance > findedVoucher.cost) {
            filteredVouchers.push(findedVoucher);
          }
        } else {
          if (findedVoucher) {
            filteredVouchers.push(findedVoucher);
          }
        }
      });
    }
    if (appliedVouchers && appliedVouchers.length > 0) {
      const ids = appliedVouchers.map((i) => i.id);
      return filteredVouchers.filter((filteredVoucher) => ids.indexOf(filteredVoucher.id) === -1);
    }
    return filteredVouchers;
  };
  render() {
    const { __, vouchers } = this.props;
    const { selectedVoucher, voucherCode, showModal, disabledAppliedButton } = this.state;
    const appliedVouchers = Basket ? Basket.applied_vouchers : [];
    const filteredVouchers = this.filterVouchers(vouchers, appliedVouchers);
    return (
      <Layout scrollY={false} headerTitle={__('Vouchers')} color="transparent">
        <div className="absolute-content large-padding">
          <Title className="centered  large-title">{__('Select a voucher')}</Title>
          <div className="vouchers-wrapper">
            {filteredVouchers.map((voucher) => (
              <div
                className={`${
                  selectedVoucher && selectedVoucher.id === voucher.id ? 'voucher-selected' : ''
                } box-content box-shadow`}
                onClick={() => this.setVoucher(voucher)}
              >
                <StrongText className="block ">{voucher.reward.name}</StrongText>
                <SmallText className="block ">
                  {`${__('Expires:')} ${moment(voucher.reward.expiry_date, 'YYYY/MM/DD').format(
                    'DD/MM/YY',
                  )}`}
                </SmallText>
              </div>
            ))}
          </div>
          <IonButton
            disabled={!selectedVoucher}
            onClick={() => this.addVoucher()}
            className="large-button"
            color="primary"
          >
            {__('Continue')}
            <IonIcon icon={arrowForward} />
          </IonButton>
          <IonButton
            expand="block"
            onClick={() => goBack()}
            color="secondary"
            fill="clear"
            className="large-button"
          >
            {'Cancel'}
          </IonButton>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    availableBalance: deepIsDefined(store, 'profile.profile.available_balance')
      ? store.profile.profile.available_balance
      : 0,
    basketUpadated: store.orders.basketUpdated,
    vouchers: store.profile.vouchers || null,
    applicableVouchers: store.profile.applicableVouchers || null,
  };
};

export default connect(mapStateToProps)(withTranslation(ApplyVouchers));
