import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { IonButton, IonIcon, IonList } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { getSubscriptions } from '../../store/subscription/actions';
import { setSelectedSubscription } from '../../store/orders/actions';
import { forwardTo, go } from '../../lib/utils';
import { SubscriptionLine } from '../../components/subscription-item';
import { addCircleOutline } from 'ionicons/icons';
import { NormalText, SmallText, Spacer, StrongText, Title } from '../../components/common';
import { loading } from '../../store/actions';
const handleMembership = () => forwardTo('/membership-terms');

const ChooseSubscription = ({
  __,
  subscriptions,
  selectedSubscription,
  activeSubscription,
  getSubscriptions,
  setSelectedSubscription,
  clientProfile,
  location,
  history,
}) => {
  useEffect(() => {
    getSubscriptions();
  }, []);

  if (
    activeSubscription &&
    subscriptions.find((subscription) => subscription.id === activeSubscription.id)
  ) {
    forwardTo('/my-membership');
  }

  let imageUrl = null;
  const items = subscriptions.map((e, idx) => {
    const isChecked = idx === selectedSubscription?.idx;
    if (imageUrl === null) {
      imageUrl = e.image;
    }
    const price = e.monthly_cost_inc_vat / 100;
    return SubscriptionLine(
      __,
      () => setSelectedSubscription({ id: e.id, idx }),
      idx,
      isChecked,
      e.name,
      e.short_description,
      price,
    );
  });

  const fromChooseSubscriptionMenu = location?.state?.fromChooseSubscriptionMenu;
  const backHandler = () => {
    fromChooseSubscriptionMenu ? go(-2) : history.goBack();
  };

  return (
    <Loading transparent>
      <Layout
        color="transparent"
        scrollY={false}
        headerTitle={__('MembershipChooser')}
        hideSecondToolbar={true}
        backHandler={backHandler}
      >
        <div className="absolute-content scrollable-y">
          <div
            className="subscription-image"
            style={{ backgroundImage: `url(${subscriptions[0]?.image})` }}
          ></div>
          <div className="centered subscription-content">
            <Spacer size={1} />
            <StrongText className="block">
              {clientProfile.buisiness_name} {__('Membership')}
            </StrongText>
            <NormalText>
              {__('Join the exclusive')} {clientProfile.buisiness_name} {__('Club')}
            </NormalText>
            {/* <Spacer size={2} />
            <div className="subscription-desc-wrapper">
              <StrongText>{__('Enjoy 5 hot drinks every day')}</StrongText>
              <SmallText>{__('Coffee vouchers issued daily')}</SmallText>
            </div> */}
            <Spacer size={1} />
            <div className="subscription-list">
              <IonList>{items}</IonList>
              <Spacer size={1} />
              <IonButton
                disabled={selectedSubscription === null}
                onClick={handleMembership}
                expand="block"
                color="primary"
                className={'checkout-btn '}
              >
                {__('JOIN NOW')}
              </IonButton>
            </div>
          </div>
        </div>
      </Layout>
    </Loading>
  );
};

const mapStateToProps = (store) => ({
  subscriptions: store.subscription.subscriptions,
  selectedSubscription: store.orders.selectedSubscription,
  activeSubscription: store.profile.profile.active_subscription,
  clientProfile: store.common.clientProfile,
});

const mapDispatchToProps = {
  setSelectedSubscription,
  getSubscriptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(ChooseSubscription));
