import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import { sprintf, deepIsDefined, isDefined, isWebConfig, forwardTo, goBack } from '../../lib/utils';
import Layout from '../../components/layout';
import { Title, Spacer, StrongText, NormalText, Subtitle } from '../../components/common';
import Incrementer from '../../components/incrementer';
import Basket from '../../lib/basket';
import BigNumber from '../../lib/bignumber';
import { getConfig } from '../../appConfig';
import './index.css';
import { arrowForward } from 'ionicons/icons';

class ApplyPointsRaw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      points: this.getRedeemPointsInit(),
    };
  }

  getRedeemPointsMin = () => getConfig().general.redeemPointsMin || 50;
  getRedeemPointsInit = () => {
    let availableBalance = this.props.availableBalance;
    const step = getConfig().general.redeemPointsStep || 50;
    const basketTotalInCents =
      new BigNumber(Basket.getTotal()).times(100).toNumber() + Basket.getAppliedPoints();
    let limit = 0;
    if (!isDefined(availableBalance) && availableBalance === null) {
      availableBalance = 0;
    } else {
      limit = parseInt(availableBalance / step) * step;
    }
    let pointsLimitBasedOnBasket = basketTotalInCents;
    if (pointsLimitBasedOnBasket > 0) {
      if (basketTotalInCents <= availableBalance) {
        limit = basketTotalInCents;
      } else {
        pointsLimitBasedOnBasket = parseInt(pointsLimitBasedOnBasket / step) * step;
      }
    }
    return limit < pointsLimitBasedOnBasket ? limit : pointsLimitBasedOnBasket;
  };

  componentDidMount() {
    const points = Basket.getAppliedPoints();
    if (points > 0) {
      this.setState({ points });
    }
  }

  onIncrementerUpdate = (points) => {
    // from min to 0
    if (points < this.state.points && points < this.getRedeemPointsMin()) {
      points = 0;
    }
    // from 0 to min
    if (points > this.state.points && points < this.getRedeemPointsMin()) {
      points = this.getRedeemPointsMin();
    }
    this.setState({ points });
  };

  applyPoints = () => {
    Basket.applyPoints(this.state.points, this.props.availableBalance, () => {
      goBack();
    });
  };

  render() {
    const { __ } = this.props;
    let availableBalance = this.props.availableBalance;
    const { points } = this.state;
    const formatedAmount = Basket._calculatePointsAppliedPrice(points, false, true);
    const step = getConfig().general.redeemPointsStep || 50;

    const basketTotalInCents =
      new BigNumber(Basket.getTotal()).times(100).toNumber() + Basket.getAppliedPoints();
    let limit = 0;
    if (!isDefined(availableBalance) && availableBalance === null) {
      availableBalance = 0;
    } else {
      limit = parseInt(availableBalance / step) * step;
    }

    // basket total: 5.2$ => 520 => 520/step(e.g. step=100)=5.2 => int(5.2) = 5 => 5 * step(e.g. step=100)
    // limit                    - represents available balance limit
    // pointsLimitBasedOnBasket - represents limit based on basket value
    // we will use lower value
    let pointsLimitBasedOnBasket = basketTotalInCents;
    if (pointsLimitBasedOnBasket > 0) {
      if (basketTotalInCents <= availableBalance) {
        // if user have enough points to cover entire basket total then we should let him to go over step
        limit = basketTotalInCents;
      } else {
        pointsLimitBasedOnBasket = parseInt(pointsLimitBasedOnBasket / step) * step;
      }
    }
    return (
      <div className="large-padding absolute-content flex-row-wrapper">
        <Title className="centered large-title">{__('Redeem points')}</Title>
        <Spacer size={1} />
        <Title className="light centered">
          {__('Select the number of points you would like to redeem')}
        </Title>
        <Spacer />

        <div className="large-incrementer">
          <Title className="light centered">
            <b>{availableBalance.toLocaleString()}</b> {__('points available')}
          </Title>
          <Spacer size={1} />
          <Incrementer
            maxLimit={limit < pointsLimitBasedOnBasket ? limit : pointsLimitBasedOnBasket}
            onUpdate={this.onIncrementerUpdate}
            quantity={points}
            step={step}
            allowNegative={false}
          />
          <Spacer size={1} />
          <Title className="light centered">{`${__('value to apply')} ${Basket.formatPrice(
            Basket.calculatePointsAppliedPrice(points),
            true,
          )}`}</Title>
          <Spacer size={1} />
          <NormalText className="light centered">{`${__(
            'Minimum required points for redemtion is',
          )} ${this.getRedeemPointsMin()}`}</NormalText>
        </div>

        <Spacer size={1} />
        <IonButton
          disabled={points === 0 ? false : points < this.getRedeemPointsMin()}
          onClick={() => this.applyPoints()}
          className="large-button"
          color="primary"
        >
          {__('Apply')}
          <IonIcon icon={arrowForward} />
        </IonButton>
        <IonButton
          expand="block"
          onClick={() => goBack()}
          color="secondary"
          fill="clear"
          className="large-button"
        >
          {'Cancel'}
        </IonButton>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    availableBalance: deepIsDefined(store, 'profile.profile.available_balance')
      ? store.profile.profile.available_balance
      : 0,
    basketUpadated: store.orders.basketUpdated,
  };
};

export const ApplyPoints = connect(mapStateToProps)(withTranslation(ApplyPointsRaw));

const ApplyPointsWrapped = (props) => (
  <Loading>
    <Layout color="transparent" headerTitle={props.__('Redeem Points')}>
      <ApplyPoints {...props} />
    </Layout>
  </Loading>
);

export default withTranslation(ApplyPointsWrapped);
