import React from 'react';
import moment from '../../../lib/moment';
import { IonItem, IonButton, IonAlert, IonInput, IonIcon } from '@ionic/react';
import Loading from '../../../components/spinner';
import { withTranslation } from '../../../lib/translate';
import Layout from '../../../components/layout';
import { Title, Spacer, NormalText, Subtitle } from '../../../components/common';
import { connect } from 'react-redux';
import { createNewBasketInstance } from '../../../lib/basket';
import { forwardTo } from '../../../lib/utils';
import Basket from '../../../lib/basket';
import { withRouter } from 'react-router';
import { checkCancelOrder, setOrdersProp } from '../../../store/orders/actions';
import './index.css';
import { logout, sendFeedback } from '../../../store/actions';
import { starOutline, star, arrowForward } from 'ionicons/icons';
import { ReactComponent as CompletedIcon } from '../../../assets/images/completed.svg';

class OrderCompletedKiosk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancel: false,
      orderId: null,
      restaurantId: null,
      experience: 0,
      comment: '',
      showFeedbackModal: false,
    };
  }

  componentDidMount() {
    Basket.createOrder();
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastOrder) {
      if (prevProps.lastOrder === null || prevProps.lastOrder.id !== this.props.lastOrder.id) {
        const { lastOrder } = this.props;
        const orderArr = lastOrder;
        const now = moment();
        let cutoffTime = orderArr ? orderArr.cutoff_time : now;
        let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss');
        const utcOffset = cutoffTimeMoment.utcOffset();
        cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset);
        if (now.isBefore(cutoffTimeMoment)) {
          this.setState({
            isCancel: true,
            orderId: orderArr.id,
            restaurantId: orderArr.restaurant_id,
          });
        }
      }
    }
  }

  drawOrder = (order) => {
    const { __ } = this.props;
    const { id, restaurant_id } = order;
    const { comment } = this.state;
    const basketInstance = createNewBasketInstance();
    basketInstance.recreateOrder(order);
    return (
      <div className="order-details-kiosk">
        <CompletedIcon className="completed-icon" />
        <Spacer />
        <div>
          <Subtitle className="large-title">{__('Order Confirmed')}</Subtitle>
          <Title className="confirm-order-id bold primary-color">{`#${id}`}</Title>
        </div>
        <Spacer />
        <NormalText className="block centered">
          {__('Please collect your order at the pick up point')}
        </NormalText>
        <Spacer />
        <Spacer />
        <IonButton
          className="large-button"
          color="primary"
          onClick={() => this.props.dispatch(logout())}
        >
          {__('Next Customer')}
          <IonIcon icon={arrowForward} />
        </IonButton>
      </div>
    );
  };

  noOrder = () => (
    <IonItem lines="none">
      <div tabIndex="-1" className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>
        {this.props.__('No order')}
      </div>
    </IonItem>
  );

  backHandler = () => {
    const { location } = this.props;
    if (location && location.state && location.state.completedOrder) {
      forwardTo('/dashboard');
      forwardTo('/click-and-collect');
      Basket.reset();
    }
  };

  cancelOrder = () => {
    const { orderId, isCancel, restaurantId } = this.state;
    if (isCancel) {
      this.props.dispatch(checkCancelOrder(orderId, restaurantId));
      this.setState({ orderId: null, isCancel: false, restaurantId: null });
    }
  };

  handleCancelOrderModal = (flag) => {
    this.props.dispatch(setOrdersProp('cancelOrderModal', flag));
  };

  createStarSet(size, name) {
    return <>{[...Array(size).keys()].map((i) => this.createStar(name, i + 1))}</>;
  }

  createStar = (name, value) => {
    if (this.state[name] >= value) {
      return (
        <IonIcon
          key={value}
          icon={star}
          color="warning"
          className="star"
          onClick={() => this.setState({ [name]: value })}
        />
      );
    } else {
      return (
        <IonIcon
          key={value}
          icon={starOutline}
          color="gray"
          className="star"
          onClick={() => this.setState({ [name]: value })}
        />
      );
    }
  };

  handleFeedback = (restaurant_id, order) => {
    let formErrors = [];
    if (Object.keys(formErrors).length === 0) {
      const { experience, comment } = this.state;
      const feedback = {
        experience: experience,
        commentExperience: comment,
        selectedRestaurant: restaurant_id,
        isKiosk: true,
        order_type: Basket.getOrderType(order),
      };
      this.props.dispatch(sendFeedback(feedback));
    }
  };
  render() {
    const { __, lastOrder } = this.props;
    const { showFeedbackModal } = this.state;
    const order = this.props.order || lastOrder;
    const orderCompletePage = true;
    const headerLabel = `${__('Order')} ${order?.id && `#${order.id}`}`;
    return (
      <>
        <Layout blank={true} color="transparent">
          <div className="order-details-kiosk-wrapper">
            {order ? this.drawOrder(order, orderCompletePage) : this.noOrder()}
          </div>
        </Layout>
        <IonAlert
          isOpen={this.props.cancelOrderModal}
          onDidDismiss={() => this.handleCancelOrderModal(false)}
          header={__('Confirm')}
          message={__('Do you want to cancel this order?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.cancelOrder(),
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  const { cancelOrderModal, latestOrderEvent } = orders;
  const { restaurants } = store.restaurants;
  let lastOrder = null;
  const orderHistory = orders.orderHistory;
  if (orderHistory && orderHistory.length > 0) {
    lastOrder = orderHistory[0];
  }
  const { profile } = store.profile;

  return {
    lastOrder,
    cards: orders.cards || [],
    restaurants: restaurants || [],
    cancelOrderModal,
    latestOrderEvent,
    profile,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderCompletedKiosk)));
