import React from 'react';
import {
  IonItem,
  IonLabel,
  IonButton,
  IonAlert,
  IonInput,
  IonCheckbox,
  IonRow,
  IonCol,
  IonIcon,
  IonGrid,
} from '@ionic/react';
import Loading from '../../../components/spinner';
import Layout from '../../../components/layout';
import { withTranslation } from '../../../lib/translate';
import {
  Title,
  Spacer,
  NormalText,
  Subtitle,
  SmallText,
  StrongText,
} from '../../../components/common';
import { connect } from 'react-redux';
import Basket from '../../../lib/basket';
import {
  forwardTo,
  goBack,
  isDefined,
  isStringEmptyOrSpaces,
  isEmailValid,
  isEmptyObject,
  validateProfileData,
} from '../../../lib/utils';
import './index.css';
import Modal from '../../../components/modal';
import {
  removePaymentCard,
  removeVoucher,
  setCommonModal,
  updateProfile,
} from '../../../store/actions';
import moment from '../../../lib/moment';
import Mobiscroll from '../../../components/mobiscroll';
import OrderContentItem from '../../../components/orderContentItem';
import { checkSnoozedTimes, formatDataForTime } from '../../clickAndCollect';
import { getConfig } from '../../../appConfig';
import { CANCEL_PAYMENT_INTENT, REGISTER_REQUEST, SET_COMMON_PROP } from '../../../store/constants';
import { ReactComponent as PaymentIcon } from '../../../assets/images/payment.svg';
import { arrowForward, chevronForward, trashBinOutline } from 'ionicons/icons';
import { ReactComponent as TicketSvg } from '../../../assets/images/ticket.svg';

const { SelectOption } = Mobiscroll;
const { getMobile, setMobile } = Basket;

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    const defaultCard =
      this.props.cards.length > 0 &&
      this.props.profile.cardToken &&
      this.props.cards.find((card) => card.id === this.props.profile.cardToken);
    this.state = {
      selectedCard: defaultCard,
      defaultCard: defaultCard,
      selectedJudopayCard: null,
      collectedModal: false,
      collectedPay: this.props.cards.length === 0,
      showAllCards: false,
      pickTime: null,
      removeCardAlert: null,
      email: '',
      name: this.props.profile?.first_name ?? '',
      receiveUpdates: false,
      modalOpen: false,
      enterNameModal: false,
      flash: false,
      newEmail: '',
      update: false,
    };
    this.updateHandler = this.updateHandler.bind(this);
  }

  componentDidMount() {
    const { profile } = this.props;
    if (profile.cardToken) {
      this.changeSelectedPaymentCard(profile.cardToken);
    }
    this.setState({
      newEmail: isEmailValid(this.props.profile.email) ? this.props.profile.email : '',
    });
    this.props.dispatch({ type: SET_COMMON_PROP, key: 'checkoutInstructionsModal', value: false });
    Basket.setKiosk();
  }

  componentDidUpdate(prevProps) {
    const { profile } = this.props;
    if (!this.state.collectedPay) {
      if (prevProps.profile.cardToken !== profile.cardToken) {
        this.changeSelectedPaymentCard(profile.cardToken);
      }
    }
  }

  changeSelectedPaymentCard = (cardId) => {
    if (cardId !== 'collectedPay') {
      const selectedCard = this.props.cards.find((card) => card.id === cardId);
      this.setState({ selectedCard, defaultCard: selectedCard, collectedPay: false }, () => {
        Basket.changeSelectedCard(cardId);
      });
    } else {
      this.setState({ collectedPay: true, selectedCard: null }, () => {});
    }
  };

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipContactDetails
    ) {
      forwardTo('/order', { skipBackToThePreviousPage: true, selectedMenu: [] });
    } else if (this.props.location.pathname === '/checkout') {
      forwardTo('/order', { skipBackToThePreviousPage: true, selectedMenu: [] });
    } else {
      goBack();
    }
  };

  updateHandler = () => {
    this.setState({
      update: true,
    });
  };

  showCollectedModal(modalOpen) {
    this.setState({ collectedModal: modalOpen });
  }
  changeTime = (selectedTime, minDT) => {
    let h = parseInt(selectedTime.split(':')[0]);
    let m = parseInt(selectedTime.split(':')[1]);
    const formattedDT = moment(minDT).hours(h).minutes(m);
    Basket.setCollectionTime(formattedDT);
  };

  setPickTime = (inst, minDT) => {
    if (inst.getVal()) {
      this.changeTime(inst.getVal(), minDT);
      this.setState({ pickTime: inst.getVal() });
    }
  };

  removePaymentCard = () => {
    const { __ } = this.props;
    this.props.dispatch(
      removePaymentCard(this.state.removeCardAlert, {
        __,
        cb: () => this.setState({ removeCardAlert: null }),
      }),
    );
  };

  handleRemoveCardAlert = (cardId) => this.setState({ removeCardAlert: cardId });

  createKioskOrder = () => {
    const kioskConfiguration = getConfig().kiosk;
    Basket.createOrder(kioskConfiguration.acceptPayment ? 'kiosk' : 'collectedPay', null, () =>
      forwardTo('/order-completed-kiosk'),
    );
  };

  handleKioskGuestReigister() {
    const { registerFormData, kioskData, profile } = this.props;
    const { email, name, receiveUpdates } = this.state;
    const timestamp = new Date().getTime();
    const { kiosk_auth_email, kiosk_password } = kioskData;
    if (isEmptyObject(profile || {})) {
      registerFormData.email = `${timestamp}_${email ? email : kiosk_auth_email}`;
      registerFormData.first_name = name;
      registerFormData.is_subscribed = receiveUpdates;
      registerFormData.accepted_terms_and_conditions = true;
      registerFormData.guest = true;
      registerFormData.password = timestamp;
      this.props.dispatch({
        type: REGISTER_REQUEST,
        referrer: '/checkout-kiosk',
        guest: true,
        cb: () => this.createKioskOrder(),
      });
    } else if (profile.first_name !== name) {
      this.props.dispatch(
        updateProfile({
          first_name: name,
          last_name: profile.last_name,
          email: profile.email,
        }),
      );
      this.createKioskOrder();
    } else {
      this.createKioskOrder();
    }

    this.setState({ enterNameModal: false });
  }

  render() {
    const {
      __,
      dispatch,
      deliveryTimeModalOpen,
      restaurants,
      checkoutInstructionsModal,
      profile,
      auth,
      vouchers,
    } = this.props;
    const { collectedModal, pickTime, receiveUpdates, flash, newEmail, name } = this.state;
    const basketInstance = this.props.basketInstance || Basket;
    const isAuth = auth.loggedIn;
    const valid = validateProfileData(profile).isValid;

    let orderType = '';
    let price = '';
    let store = '';
    let snoozedTimes = '';
    let minDT = '';
    let timePickerOptions = [];
    if (Basket.getDeliveryOption().id != 'gift-vouchers') {
      if (Basket.getRestaurant()) {
        orderType = Basket.getOrderType();
        price = Basket._getTotal();
        store = restaurants.find((restaurant) => restaurant.id === Basket.getRestaurant().id);
        if (orderType == 'Table') {
          snoozedTimes = [];
        } else {
          snoozedTimes = checkSnoozedTimes(store, Basket.getDeliveryOption().id);
        }
        minDT = moment();
        if (Basket.getDeliveryOption().id == 'charter-delivery') {
          if (store && isDefined(store.charter_delivery_order_slot_lead_time)) {
            minDT.add(store.charter_delivery_order_slot_lead_time, 'minutes');
          }
        } else {
          if (store && isDefined(store.order_slot_lead_time)) {
            minDT.add(store.order_slot_lead_time, 'minutes');
          }
        }

        timePickerOptions = formatDataForTime(
          Basket.getRestaurant(),
          minDT,
          Basket.getRestaurant().id,
          Basket.getDeliveryOption().id === 'charter-delivery' ? true : false,
          false,
          snoozedTimes,
        );
      }
    } else {
      if (!Basket.is_gift && this.props.giftVoucherData) {
        Basket.setCollectionTime(null);
        Basket.addToBasket({
          item: {
            productPrice: this.props.giftVoucherData.points_value / 100,
            productName: 'Voucher',
          },
          quantity: 1,
        });
        Basket.setGift();
        Basket.setRestaurant(restaurants.find((restaurant) => restaurant.name === 'Vouchers'));
      }
    }
    const appliedVoucher = Basket.getAppliedVocuher();
    const appliedPoints = Basket.getAppliedPoints();
    let fullscreenBasket =
      JSON.parse(localStorage.getItem('kioskData'))?.full_screen_basket_mode ?? false;
    return (
      <>
        <Loading transparent>
          <Layout
            onPayButtonSubmitHandler={() => this.setState({ enterNameModal: true })}
            showPayButton={fullscreenBasket}
            noPadding
            scrollY={false}
            color="transparent"
            selectCategoryOnScroll={this.selectCategoryOnScroll}
            hideSecondToolbar={true}
            headerTitle={__('Checkout')}
            headerWithTitle={true}
            backHandler={this.backHandler}
            disableForwardTo={true}
            staticHeaderClassName={'kiosk-header'}
            hasUserBar
            showAllergensButton={!fullscreenBasket}
          >
            <div
              className={`checkout-content ${
                fullscreenBasket ? 'checkout-content-fullscreen' : ''
              } ${getConfig().kiosk.hasLoyalty ? 'checkout-content-with-loyalty' : ''}`}
            >
              <div className="basket-items-wrapper">
                {basketInstance.getItems().map((basketItem, basketItemIndex) => {
                  return (
                    <OrderContentItem
                      key={basketItemIndex}
                      item={basketItem}
                      index={basketItemIndex}
                      basketInstance={basketInstance}
                      update={this.updateHandler}
                    />
                  );
                })}
                {Basket.getAllergen().length > 0 && fullscreenBasket && (
                  <IonButton
                    onClick={() =>
                      dispatch({ type: SET_COMMON_PROP, key: 'allergenModalOpen', value: true })
                    }
                    className="allergens-button"
                  >
                    {__('View Allergens')}
                  </IonButton>
                )}
              </div>
              <div>
                {basketInstance.process_fee_value > 0 && (
                  <IonGrid className="box-content-wrapper ">
                    <IonRow className="box-content basket-item-service-charge-row">
                      <IonCol className="paddLR grow ">{__('Items total')}</IonCol>
                      <IonCol className="righted paddLR self-center ">
                        {Basket.getItemsTotal()}
                      </IonCol>
                    </IonRow>
                    <IonRow className=" box-content">
                      <IonCol className="paddLR grow">
                        {' '}
                        {Basket.processing_fee_description > 0
                          ? `${Basket.processing_fee_description}% `
                          : null}
                        {__('Order processing fee')}
                      </IonCol>
                      <IonCol className="paddLR righted">
                        {basketInstance.getProcessingFee()}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )}
                {isAuth &&
                  getConfig().kiosk.hasLoyalty &&
                  (vouchers.length > 0 || profile.available_balance > 0) && (
                    <>
                      <div className="basket-loyalty-wrapper">
                        <Title className="centered">{__('Your loyalty')}</Title>
                        {!valid && getConfig().appType.hasEmailValidationEnabled ? (
                          <>
                            {isAuth && !valid && getConfig().appType.hasEmailValidationEnabled ? (
                              <NormalText className="centered block">
                                {__(
                                  'You can earn, but not redeem points until your account is verified',
                                )}
                              </NormalText>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {appliedVoucher.length == 0 && !appliedPoints ? (
                              <>
                                <div className="basket-loyalty-options">
                                  {vouchers.length > 0 && (
                                    <div
                                      className="box-content box-shadow"
                                      onClick={() => forwardTo('/apply-vouchers')}
                                    >
                                      <TicketSvg />
                                      <div>
                                        <StrongText className="block primary-color">
                                          {__('Apply a voucher')}
                                        </StrongText>
                                        <SmallText className="block primary-color">{`${
                                          vouchers.length
                                        } ${__('available')}`}</SmallText>
                                      </div>
                                      <IonIcon icon={chevronForward} />
                                    </div>
                                  )}

                                  {profile.available_balance > 0 && (
                                    <div
                                      className="box-content box-shadow"
                                      onClick={() => forwardTo('/apply-points')}
                                    >
                                      <TicketSvg />

                                      <div>
                                        <StrongText className="block primary-color">
                                          {__('Redeem points')}
                                        </StrongText>
                                        <SmallText className="block primary-color">
                                          {' '}
                                          {`${profile.available_balance?.toLocaleString()} ${__(
                                            'points available',
                                          )}`}
                                        </SmallText>
                                      </div>
                                      <IonIcon icon={chevronForward} />
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : (
                              <IonGrid>
                                {appliedPoints ? (
                                  <IonRow className="box-content">
                                    <IonCol className="paddLR grow ">
                                      {__('Applied points')}
                                      <IonButton
                                        onClick={() => {
                                          Basket.applyPoints(0, () => {});
                                          this.setState({ update: true });
                                        }}
                                        color="danger"
                                        fill="clear"
                                      >
                                        {__('Remove')} <IonIcon icon={trashBinOutline} />{' '}
                                      </IonButton>
                                    </IonCol>
                                    <IonCol className="righted paddLR righted ">
                                      {Basket._calculatePointsAppliedPrice(null, true)}
                                    </IonCol>
                                  </IonRow>
                                ) : null}
                                {appliedVoucher.length > 0 ? (
                                  <IonRow className="box-content">
                                    <IonCol className="paddLR grow ">
                                      {__('Applied voucher')}
                                      <IonButton
                                        onClick={() => {
                                          this.props.dispatch(removeVoucher(appliedVoucher[0].id));
                                          this.setState({ update: true });
                                        }}
                                        color="danger"
                                        fill="clear"
                                      >
                                        {__('Remove')} <IonIcon icon={trashBinOutline} />{' '}
                                      </IonButton>
                                    </IonCol>
                                    <IonCol className="righted paddLR righted ">
                                      {Basket._calculateAppliedVocuhersPrice(true, null)}
                                    </IonCol>
                                  </IonRow>
                                ) : null}
                              </IonGrid>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                <IonGrid class="box-content-wrapper">
                  <IonRow className="box-content">
                    <IonCol className="paddLR grow">
                      <Subtitle className="bold"> {__('Total')}</Subtitle>
                    </IonCol>
                    <IonCol className="paddLR righted">
                      <Subtitle className="bold">{basketInstance._getTotal(true)}</Subtitle>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <div className="send-email-field box-content">
                  <div>
                    <NormalText className="block centered">
                      {__(
                        'If you wish to Recive your receipt via email, enter your email address below',
                      )}
                    </NormalText>
                  </div>

                  <div className="add-email-block">
                    <IonItem
                      lines="none"
                      className={`input-field-wrapper ${flash && 'flash-field'}`}
                    >
                      <IonInput
                        onIonChange={(e) => {
                          this.setState({ flash: false, newEmail: e.target.value });
                        }}
                        required={false}
                        type="email"
                        pattern="email"
                        inputmode="email"
                        value={this.state.newEmail}
                        autocomplete="on"
                      ></IonInput>
                    </IonItem>
                    <IonButton
                      onClick={() => this.setState({ email: newEmail, flash: true })}
                      disabled={!isEmailValid(newEmail)}
                      expand="block"
                      className={flash ? 'add-email-button-grean' : 'add-email-button'}
                    >
                      {__('Submit')}
                    </IonButton>
                  </div>
                  <div className="register-terms-wrapper">
                    <IonCheckbox
                      color="primary"
                      checked={receiveUpdates}
                      onIonChange={(e) => this.setState({ receiveUpdates: e.detail.checked })}
                    />
                    <IonLabel className="ion-text-wrap">
                      <NormalText color="primary">
                        {__('Opt-in to receive updates, offers and promotions via email')}
                      </NormalText>
                    </IonLabel>
                  </div>
                </div>
                <div className="flex-min">
                  <IonButton
                    color="primary"
                    disabled={basketInstance.getItems().length === 0}
                    onClick={() => this.setState({ enterNameModal: true })}
                    className="large-button"
                  >
                    {`${__('Pay')} ${Basket._getTotal(true)}`}
                    <IonIcon icon={arrowForward} />
                  </IonButton>
                </div>
              </div>
            </div>
          </Layout>
          <IonAlert
            isOpen={collectedModal}
            onDidDismiss={() => this.showCollectedModal(false)}
            header={__('Pay on Collection')}
            message={__(
              'You agree to pay the amount shown before collecting your order. Earned loyalty will be updated after payment',
            )}
            buttons={[
              {
                text: __('Cancel'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => this.showCollectedModal(false),
              },
              {
                text: __('Confirm'),
                handler: () => {
                  Basket.createOrder('collectedPay');
                  this.showCollectedModal(false);
                },
              },
            ]}
          />
        </Loading>
        <Modal
          className={'delivery-time-modal'}
          isOpen={deliveryTimeModalOpen}
          onDidDismiss={() => dispatch(setCommonModal('deliveryTimeModalOpen', false))}
        >
          <Title>{__('Select Collection Time')}</Title>
          <NormalText>{__('Please select different time')}</NormalText>
          <Spacer />
          <SelectOption
            display="inline"
            onSet={(e, inst) => this.setPickTime(inst, minDT)}
            data={timePickerOptions}
            label="Location"
            value={pickTime}
            inputStyle="box"
            placeholder={__('Select Collection Time')}
            setText={__('OK')}
            cancelText={__('Cancel')}
            onInit={() => {
              if (timePickerOptions.length > 0) {
                const firstAvailableTime = timePickerOptions.find((i) => !i.disabled);
                if (!pickTime && firstAvailableTime && pickTime !== firstAvailableTime.value) {
                  this.changeTime(firstAvailableTime.value, minDT);
                }
              }
            }}
          />
          <Spacer />
          <IonButton
            expand="block"
            color="primary"
            className="uppercase okx-font-secondary"
            onClick={() => dispatch(setCommonModal('deliveryTimeModalOpen', false))}
          >
            {__('Continue')}
          </IonButton>
        </Modal>

        <Modal
          className="pay-modal small-modal"
          isOpen={checkoutInstructionsModal === true}
          onDidDismiss={() =>
            this.props.dispatch({
              type: SET_COMMON_PROP,
              key: 'checkoutInstructionsModal',
              value: false,
            })
          }
        >
          <div>
            <PaymentIcon className="payment-icon" />
            <Spacer />
            <Title className="large-title bold uppercase">{__('Present your payment card')}</Title>
            <Spacer />
            <Title>{__('Follow instructions on the terminal')}</Title>
          </div>
          <Spacer />
          <IonButton
            color="primary"
            fill="clear"
            className="large-button"
            onClick={() => dispatch({ type: CANCEL_PAYMENT_INTENT })}
          >
            {__('Abort Payment')}
          </IonButton>
        </Modal>

        <Modal
          className="name-modal small-modal"
          isOpen={this.state.enterNameModal}
          onDidDismiss={() => this.setState({ enterNameModal: false })}
        >
          <Title className="large-title primary-color">{__('Enter your name')}</Title>
          <Spacer />

          <Subtitle className="block centered">
            {__(`Enter your name below and we will call you when your order is ready`)}
          </Subtitle>
          <Spacer />

          <div className="add-name-input">
            <div>
              <IonItem lines="none" className="input-field-wrapper input-field-wrapper-large">
                <IonInput
                  onIonChange={(e) => {
                    this.setState({ name: e.target.value });
                  }}
                  color="primary"
                  required={true}
                  value={name}
                  autocomplete="on"
                />
              </IonItem>
            </div>
          </div>

          <Spacer size={1} />
          <IonButton
            onClick={() => this.handleKioskGuestReigister()}
            color="primary"
            className="large-button"
            disabled={isStringEmptyOrSpaces(name)}
          >
            {__('Continue')}
            <IonIcon icon={arrowForward} />
          </IonButton>
        </Modal>

        <IonAlert
          isOpen={isDefined(this.state.removeCardAlert)}
          onDidDismiss={() => this.handleRemoveCardAlert(null)}
          header={__('Confirm')}
          message={__('Do you you want to remove this card?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.removePaymentCard(),
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth, registerFormData, kioskData, profile, vouchers } = store.profile;
  const { checkoutInstructionsModal } = store.common;
  return {
    cards: store.orders.cards || [],
    clientSecret: store.orders.clientSecret,
    profile: store.profile.profile || {},
    deliveryTimeModalOpen: store.common.deliveryTimeModalOpen,
    restaurants: store.restaurants.restaurants || [],
    registerFormData,
    kioskData,
    checkoutInstructionsModal,
    profile,
    auth,
    vouchers: vouchers || [],
  };
};

export default connect(mapStateToProps)(withTranslation(Checkout));
