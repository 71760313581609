import { isWebConfig } from './lib/utils';
import Dashboard from './screens/dashboard';
import Loyalty from './screens/loyalty';
import Account from './screens/account';
import Feedback from './screens/feedback';
import ReferAFriend from './screens/refer-a-friend';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Locations from './screens/locations';
import Social from './screens/social';
import ResetPassword from './screens/resetPassword';
import Terms from './screens/terms';
import Privacy from './screens/privacy';
import Faq from './screens/faq';
import ItemDetails from './screens/itemDetails';
import DeliveryOptions from './screens/delivery-options';
import Delivery from './screens/delivery';
import DeliveryAddressCheck from './screens/delivery-address-check';
import DeliveryAddressAdd from './screens/delivery-address-add';
import DeliveryTime from './screens/delivery-time';
import ClickAndCollect from './screens/clickAndCollect';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import OrderSummary from './screens/orderSummary';
import ApplyPoints from './screens/applyPoints';
import Checkout from './screens/checkout';
import OrderCompleted from './screens/orderCompleted';
import ContactDetails from './screens/contactDetails';
import ApplyVouchers from './screens/applyVouchers';

// import AllergensInfo from './screens/allergensInfo'
import OrderToTable from './screens/orderToTable';
import { getConfig } from './appConfig';
import { getSingleDeliveryOption } from './lib/utils';
import { ServiceCharge } from './screens/service-charge';
import GiftVouchers from './screens/giftVouchers';
import ApplyLoyalty from './screens/applyLoyalty';
import MyMembership from './screens/myMembership';
import MembershipCompleted from './screens/membershipCompleted';
import CheckoutMembership from './screens/checkoutMembership';
import CardAddMembersip from './screens/cardAddMembership';
import ChooseSubscriptionMenu from './screens/chooseSubscriptionMenu';
import ChooseSubscription from './screens/chooseSubscription';
import TermsAndConditions from './screens/terms-subscription-package';
import CheckoutKiosk from './screens/kiosk/checkoutKiosk';
import KioskDashboard from './screens/kiosk/dashboardKiosk';
import deliveryOptionsKiosk from './screens/kiosk/deliveryOptionsKiosk';
import orderCompletedKiosk from './screens/kiosk/orderCompletedKiosk';
import OrderPage from './screens/kiosk/orderKiosk';
import api from './lib/api';
import Login from './screens/login';

const Order = require('./screens/order' + (isWebConfig() ? 'Web' : '')).default;

const home = require('./assets/images/home.svg');
const loyalty = require('./assets/images/loyalty.svg');
const myAccount = require('./assets/images/account.svg');
const feedback = require('./assets/images/feedback.svg');
const referAFriend = require('./assets/images/refer.svg');
const history = require('./assets/images/history.svg');
const locations = require('./assets/images/locations.svg');
const social = require('./assets/images/social-media.svg');
const login = require('./assets/images/login.svg');
const logout = require('./assets/images/logout.svg');
const settings = require('./assets/images/settings.svg');
const startNewOrder = require('./assets/images/order.svg');
const menu = require('./assets/images/menu.svg');
const terms = require('./assets/images/terms.svg');
const privacy = require('./assets/images/privacy.svg');
const qm = require('./assets/images/qm.svg');
const gift = require('./assets/images/gift.svg');

const { hasOrdering } = getConfig().appType;
let navConfig;

export const initRouter = async () => {
  const config = await api.getFrontEndAppConfig();
  const frontEndAppConfig = config.front_end_app_config;
  const kiosk = frontEndAppConfig.kiosk ? frontEndAppConfig.kiosk.active : false;
  const singleDeliveryOption = await getSingleDeliveryOption();
  const { hasGiftVouchers, hasMembership } = config.front_end_app_config.flags;
  navConfig = {
    routes: [
      isWebConfig()
        ? []
        : {
            label: 'Home',
            path: '/dashboard',
            component: Dashboard,
            icon: home,
            exact: true,
            default: !kiosk,
          },
      {
        label: 'Kiosk Home',
        path: '/kiosk-dashboard',
        component: KioskDashboard,
        icon: home,
        exact: true,
        default: kiosk,
        notInDrawer: true,
      },
      {
        label: 'Start New Order',
        path: '/delivery-options-kiosk',
        component: deliveryOptionsKiosk,
        icon: startNewOrder,
        exact: true,
        group: 'button',
        protected: false,
        notInDrawer: true,
      },
      ...(hasOrdering
        ? [
            {
              path: '/order-completed-kiosk',
              component: orderCompletedKiosk,
            },
          ]
        : []),
      {
        label: 'Menu',
        path: '/order',
        component: kiosk ? OrderPage : Order,
        icon: menu,
        exact: true,
        default: isWebConfig() && !kiosk,
        group: 'button',
      },
      // {
      //   label: 'Apply Loyalty',
      //   path: '/apply-loyalty',
      //   component: ApplyLoyalty,
      //   icon: menu,
      //   protected: true,
      //   notInDrawer: true,
      // },
      ...(hasMembership
        ? [
            {
              label: 'Membership',
              path: '/get-membership',
              component: ChooseSubscriptionMenu,
              icon: menu,
              protected: true,
              group: 2,
            },
            {
              label: 'Membership',
              path: '/membership',
              component: ChooseSubscription,
              icon: home,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),
      {
        label: 'TermsAndConditions',
        path: '/membership-terms',
        component: TermsAndConditions,
        notInDrawer: true,
        protected: true,
      },
      // {
      //   label: 'Checkout Membership',
      //   path: '/checkout-membership',
      //   component: CheckoutMembership,
      //   notInDrawer: true,
      //   protected: true,
      // },
      // {
      //   label: 'MyMembership',
      //   path: '/my-membership',
      //   component: MyMembership,
      //   notInDrawer: true,
      //   protected: true,
      // },
      // {
      //   label: 'Membership Completed',
      //   path: '/membership-completed',
      //   component: MembershipCompleted,
      //   notInDrawer: true,
      //   protected: true,
      // },
      // {
      //   label: 'Add card Membership',
      //   path: '/add-card-membership',
      //   component: CardAddMembersip,
      //   notInDrawer: true,
      //   protected: true,
      // },
      ...(hasOrdering
        ? [
            {
              label: singleDeliveryOption ? singleDeliveryOption.label : '',
              path: '/click-and-collect',
              component: ClickAndCollect,
              icon: settings,
              protected: false,
              notInDrawer: !singleDeliveryOption,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Start New Order',
              path: '/delivery-options',
              component: DeliveryOptions,
              icon: startNewOrder,
              protected: false,
              notInDrawer: !!singleDeliveryOption,
            },
          ]
        : []),
      {
        label: 'Loyalty',
        path: '/loyalty',
        component: Loyalty,
        icon: loyalty,
        protected: true,
        state: { tab: 'points' },
        group: 1,
      },
      // {
      //   label: 'Refer A Friend',
      //   path: '/refer-a-friend',
      //   component: ReferAFriend,
      //   icon: referAFriend,
      //   protected: true,
      //   group: 1,
      // },
      ...(hasOrdering
        ? [
            {
              label: 'Checkout',
              path: '/checkout',
              component: Checkout,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Checkout',
              path: '/checkout-kiosk',
              component: CheckoutKiosk,
              icon: settings,
              notInDrawer: true,
              protected: false,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Apply Points',
              path: '/apply-points',
              component: ApplyPoints,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Service Charge',
              path: '/service-charge',
              component: ServiceCharge,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),
      // {
      //   label: 'My Account',
      //   path: '/account',
      //   component: Account,
      //   icon: myAccount,
      //   protected: true,
      //   group: 2,
      // },
      ...(hasGiftVouchers
        ? [
            {
              label: 'eGift Vouchers',
              path: '/gift-vouchers',
              component: GiftVouchers,
              icon: gift,
              protected: false,
              group: 1,
            },
          ]
        : []),
      // {
      //   label: 'History',
      //   path: '/history',
      //   component: History,
      //   icon: history,
      //   protected: true,
      //   group: 2,
      // },
      // {
      //   label: 'Leave Feedback',
      //   path: '/feedback',
      //   component: Feedback,
      //   icon: feedback,
      //   protected: true,
      //   group: 2,
      // },
      // {
      //   label: 'Locations',
      //   path: '/locations',
      //   component: Locations,
      //   icon: locations,
      //   protected: false,
      //   group: 3,
      // },
      // {
      //   label: 'Social Media',
      //   path: '/social',
      //   component: Social,
      //   icon: social,
      //   protected: false,
      //   group: 3,
      // },
      {
        label: 'Contact Details',
        path: '/contact-details',
        component: ContactDetails,
        protected: true,
        notInDrawer: true,
      },
      {
        label: 'Apply Vouchers',
        path: '/apply-vouchers',
        component: ApplyVouchers,
        notInDrawer: true,
        protected: true,
      },
      {
        label: 'FAQ',
        path: '/faq',
        component: Faq,
        icon: qm,
        protected: false,
        notInDrawer: false,
        group: 3,
      },
    ],
    authRoutes: [
      { label: 'Logout', path: '/logout', icon: login, fn: 'logout' },
      { label: 'Login', path: '/login', component: Login, icon: logout, fn: 'login' },
    ],
    additionalRoutes: [
      { label: 'T&Cs', path: '/terms', component: Terms, icon: terms },
      { label: 'Privacy Policy', path: '/privacy', component: Privacy, icon: privacy },
    ],
    notInMenuRoutes: [
      { path: '/reset-password', component: ResetPassword },
      ...(hasOrdering ? [{ path: '/order', component: Order }] : []),
      ...(hasOrdering ? [{ path: '/item-details', component: ItemDetails }] : []),
      ...(hasOrdering ? [{ path: '/cards', component: Cards }] : []),
      ...(hasOrdering ? [{ path: '/card-add', component: CardAdd }] : []),
      ...(hasOrdering ? [{ path: '/history-details', component: HistoryDetails }] : []),
      ...(hasOrdering ? [{ path: '/order-summary', component: OrderSummary }] : []),
      ...(hasOrdering ? [{ path: '/order-completed', component: OrderCompleted }] : []),
      ...(hasOrdering ? [{ path: '/order-to-table', component: OrderToTable }] : []),
      ...(hasOrdering ? [{ path: '/delivery', component: Delivery }] : []),
      ...(hasOrdering
        ? [{ path: '/delivery-address-check', component: DeliveryAddressCheck }]
        : []),
      ...(hasOrdering ? [{ path: '/delivery-address-add', component: DeliveryAddressAdd }] : []),
      ...(hasOrdering ? [{ path: '/delivery-time', component: DeliveryTime }] : []),
    ],
  };

  return navConfig;
};

export default navConfig;
