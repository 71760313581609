import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import React, { Component } from 'react';
import { withTranslation } from '../../lib/translate.js';
import { makeKey } from '../../lib/utils.js';
import { NormalText, SmallText, StrongText, Title } from '../common/index.js';
import Incrementer from '../incrementer/index.js';
import Modal from '../../components/modal';
import './index.css';
import { ItemDetailsKiosk } from '../../screens/kiosk/itemDetailsKiosk/index.js';
import { getConfig } from '../../appConfig.js';
import Basket from '../../lib/basket.js';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { pencilOutline, trashBinOutline } from 'ionicons/icons';

class OrderContentItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      nameVisible: true,
      update: false,
    };
  }

  drawSubItems = (basketItem = {}, basketInstance) => {
    const { quantity, selectedChoices } = basketItem;
    const { profile } = this.props;
    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <IonRow className="sub-item" key={makeKey(choiceGroupIndex, choiceIndex, sku)}>
              <IonCol>
                <SmallText>{basketInstance.getProductName(choice, profile)}</SmallText>
              </IonCol>
              <SmallText className="righted text-start">
                {basketInstance.calculateSubItemPrice(choice, quantity) !== 0
                  ? basketInstance._calculateSubItemPrice(choice, quantity)
                  : null}
              </SmallText>
            </IonRow>
          );
        });
      });
    }
    return null;
  };

  onContentScrollHandler() {
    const nameVisible = this.isScrolledIntoView(document.querySelector('.item-details-modal-name'));
    if (this.state.nameVisible != nameVisible) {
      if (!nameVisible) {
        this.setState({ nameVisible: false });
      } else {
        this.setState({ nameVisible: true });
      }
    }
  }
  componentDidUpdate(prevProps) {
    const { update } = this.props;
    if (update) update();
  }
  showModal = (basketItem, basketItemIndex) => {
    this.setState({ modalOpen: !this.state.modalOpen, basketItem, basketItemIndex });
  };
  render() {
    const { __, className, item, index, basketInstance, profile } = this.props;
    const { modalOpen } = this.state;
    const itemImage = item.item.itemRichData?.squareImageUrl;
    let image =
      item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : '';
    if (image && image.indexOf('http://') !== -1) {
      image = image.replace(/http:\/\//g, 'https://');
    }
    return (
      <>
        <div className="box-content">
          <div
            className="order-item-image"
            style={
              image && image !== ''
                ? { backgroundImage: `url(${image})` }
                : {
                    backgroundSize: 'contain',
                    backgroundImage: `url(${this.props.clientProfile.logo_image})`,
                  }
            }
          />
          <div className="item-content-wrapper flex-justify-between flex-row-wrapper">
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <NormalText className="block ">{item.item.productName}</NormalText>
                <NormalText>
                  {basketInstance.calculateItemPriceByIndex(index, true) > 0
                    ? basketInstance._calculateItemPriceByIndex(index, true)
                    : null}
                </NormalText>
              </div>

              {this.drawSubItems(item, basketInstance)}
              {item.instructions && item.instructions !== '' ? (
                <IonRow>
                  <IonCol>
                    <Title>{__('Special Notes')}:</Title>
                    <Title className="sub-items">{item.instructions}</Title>
                  </IonCol>
                </IonRow>
              ) : null}
            </div>
            <div className="item-content-actions flex-justify-between flex-col-wrapper flex-align-end">
              <Incrementer
                color="primary"
                allowNegative={false}
                quantity={item.quantity}
                onUpdate={(newQuantity) => {
                  basketInstance.changeItemQuantity(index, newQuantity);
                  this.setState({ update: true });
                }}
              >
                <StrongText className="bold primary-color">{item.quantity}</StrongText>
              </Incrementer>
              <IonButton onClick={() => this.showModal(item, index)} fill="outline" color="primary">
                {__('Edit')}
                <IonIcon
                  style={{ marginLeft: '10px', height: '30px', width: '30px' }}
                  icon={pencilOutline}
                ></IonIcon>
              </IonButton>
              <IonButton
                onClick={() => {
                  basketInstance.changeItemQuantity(index, 0);
                  this.setState({ update: true });
                }}
                fill="outline"
                color="danger"
              >
                {__('Remove')}
                <IonIcon
                  style={{ marginLeft: '10px', height: '30px', width: '30px' }}
                  icon={trashBinOutline}
                ></IonIcon>
              </IonButton>
            </div>
          </div>
        </div>
        {modalOpen && (
          <Modal
            className={`item-details-modal  item-details-modal-kiosk ${modalOpen ? 'visible' : ''}`}
            isOpen={modalOpen}
            onDidDismiss={() => {
              this.setState({ modalOpen: false });
            }}
          >
            <ItemDetailsKiosk
              __={__}
              item={this.state.basketItem}
              profile={profile}
              modalOpen={modalOpen}
              closeModal={() => {
                this.setState({ modalOpen: false });
              }}
              basketItem={item}
              edit={true}
              index={this.state.basketItemIndex}
            />
          </Modal>
        )}
      </>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    clientProfile: store.common.clientProfile,
  };
};
export default connect(mapStateToProps)(withRouter(withTranslation(OrderContentItem)));
